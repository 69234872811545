import useQuery from './useQuery';

const statusQueryParam = 'sts';
const errorStatusQueryParam = 'ER';
const canceledStatusQueryParam = 'CA';
const bookingNumberQueryParam = 'bookingId';

export type UsePaymentResponse = {
  bookingId: string | null;
  hasPaymentError: boolean;
  paymentWasCancelled: boolean;
};

export function usePaymentStatus(): UsePaymentResponse {
  const query = useQuery();
  let bookingId: string | null = null;
  let error = false;
  let cancelled = false;

  if (query.has(bookingNumberQueryParam)) {
    bookingId = query.get(bookingNumberQueryParam);
  }
  if (query.has(statusQueryParam)) {
    error = query.get(statusQueryParam) === errorStatusQueryParam;
  }
  if (query.has(statusQueryParam)) {
    cancelled = query.get(statusQueryParam) === canceledStatusQueryParam;
  }

  return {
    bookingId,
    hasPaymentError: error,
    paymentWasCancelled: cancelled
  };
}
