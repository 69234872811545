/* This file was generated by running "node scripts/generateTranslations.js". */
/* Created: 2024-07-22 09:22:06 */

const Keys = {
  submit: 'submit',
  smallGroupInfo: 'smallGroupInfo',
  smallGroupLabel: 'smallGroupLabel',
  smallGroup: 'smallGroup',
  date: 'date',
  pricePerPerson: 'pricePerPerson',
  origin: 'origin',
  availability: 'availability',
  night: 'night',
  night_plural: 'night_plural',
  from: 'from',
  headline: 'headline',
  sectionText: 'sectionText',
  promotionCodeHeadline: 'promotionCodeHeadline',
  promotionCode: 'promotionCode',
  promotionCodeText: 'promotionCodeText',
  earlyBirdHeadline: 'earlyBirdHeadline',
  earlyBirdText: 'earlyBirdText',
  promotionCodeSuccess: 'promotionCodeSuccess',
  promotionError: 'promotionError',
  promotionErrorAdditional: 'promotionErrorAdditional',
  promotionErrorOldIsBetter: 'promotionErrorOldIsBetter',
  promotionErrorNewIsOld: 'promotionErrorNewIsOld',
  maintenanceMode: 'maintenanceMode',
  noPackagesFound: 'noPackagesFound',
  addHere: 'addHere'
}; export default Keys;