import React, { ReactNode, useEffect } from 'react';
import { Button, ModalBody } from 'reactstrap';
import { useTranslation } from '@ibe/components';
import fallback from '../../../../Translations/generated/Checkout.de.json';
import Keys from '../../../../Translations/generated/Checkout.de.json.keys';
import debounce from 'lodash-es/debounce';
import { TrendToursCustomModal } from '../AccommodationRoomAdaptOverlay';

const SummaryModal = ({
  isModalOpen,
  hideModal,
  children
}: {
  isModalOpen: boolean;
  hideModal: () => void;
  children: ReactNode;
}) => {
  const { t } = useTranslation('Checkout', fallback);

  const onResize = () => {
    if (!window.matchMedia('(max-width: 768px)').matches) {
      hideModal();
    }
  };

  useEffect(() => {
    if (isModalOpen) {
      const debouncedHandleResize = debounce(onResize, 150);
      window.removeEventListener('resize', debouncedHandleResize);
      window.addEventListener('resize', debouncedHandleResize);
      return (): void => {
        window.removeEventListener('resize', debouncedHandleResize);
      };
    }
  }, [isModalOpen]);

  return (
    <TrendToursCustomModal
      modalClassName="sticky-summary-overlay"
      centered
      isOpen={isModalOpen}
      size="xl"
      backdropClassName="sticky-summary-overlay-backdrop"
    >
      <ModalBody>
        <div className="sticky-summary-overlay-content-wrapper">{children}</div>
        <div className="iso__modal-footer--sticky ">
          <div className="summary-sticky-bottom-bar d-flex justify-content-center">
            <Button onClick={hideModal} color="secondary">
              {t(Keys.close)}
            </Button>
          </div>
        </div>
      </ModalBody>
    </TrendToursCustomModal>
  );
};

export default SummaryModal;
