import React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import BlankPageLayout from './BlankPageLayout';

interface Props {
  children: React.ReactNode;
}

const PageLayout = observer((props: Props) => {
  const { children } = props;

  return (
    <BlankPageLayout>
      <div>{children}</div>
    </BlankPageLayout>
  );
});
export default PageLayout;
