import { StepType } from '@ibe/services';
import Traveler from './Pages/Checkout/Components/Traveler';
import Summary from './Pages/Checkout/Components/Summary';
import Confirmation from './Pages/Checkout/Components/Confirmation/Confirmation';
import PackagesPage from './Pages/Packages/PackagesPage';
import { CheckoutStep } from './Models/CheckoutStep';
import Extras from './Pages/Checkout/Components/Extras';
import TravelDate from './Pages/Checkout/Components/TravelDate';

export const workflowCheckout: CheckoutStep[] = [
  {
    type: StepType.OAG_PACKAGE_HOTEL,
    slug: 'packages',
    title: '',
    label: 'Packages',
    component: PackagesPage,
    steps: null
  },
  {
    type: StepType.OAG_CHECKOUT_SUMMARY,
    slug: 'summary',
    title: '',
    label: 'Summary',
    component: Summary,
    steps: null
  },
  {
    type: StepType.OAG_CHECKOUT_TRAVELERS,
    slug: 'traveler',
    title: '',
    label: 'Traveler',
    component: Traveler,
    steps: null
  },
  {
    type: StepType.OAG_CHECKOUT_CONFIRMATION,
    slug: 'confirmation',
    title: '',
    label: 'Confirmation',
    component: Confirmation,
    steps: null
  }
];

export const productIBEWorkflowCheckout: CheckoutStep[] = [
  {
    type: StepType.OAG_PACKAGE_HOTEL,
    slug: 'packages',
    title: '',
    label: 'Packages',
    component: PackagesPage,
    steps: null
  },
  {
    type: StepType.OAG_CHECKOUT_SUMMARY,
    slug: 'summary',
    title: '',
    label: 'Summary',
    component: Summary,
    steps: null
  },
  {
    type: StepType.OAG_CHECKOUT_EXTRAS,
    slug: 'extras',
    title: '',
    label: 'Extras',
    component: Extras,
    steps: null
  },
  {
    type: StepType.OAG_CHECKOUT_TRAVELERS,
    slug: 'traveler',
    title: '',
    label: 'Traveler',
    component: Traveler,
    steps: null
  },
  {
    type: StepType.OAG_CHECKOUT_CONFIRMATION,
    slug: 'confirmation',
    title: '',
    label: 'Confirmation',
    component: Confirmation,
    steps: null
  }
];

export const productIBEWhitelabelWorkflowCheckout: CheckoutStep[] = [
  {
    type: StepType.OAG_PACKAGE_HOTEL,
    slug: 'packages',
    title: '',
    label: 'Packages',
    component: TravelDate,
    steps: null
  },
  {
    type: StepType.OAG_CHECKOUT_SUMMARY,
    slug: 'summary',
    title: '',
    label: 'Summary',
    component: Summary,
    steps: null
  },
  {
    type: StepType.OAG_CHECKOUT_EXTRAS,
    slug: 'extras',
    title: '',
    label: 'Extras',
    component: Extras,
    steps: null
  },
  {
    type: StepType.OAG_CHECKOUT_TRAVELERS,
    slug: 'traveler',
    title: '',
    label: 'Traveler',
    component: Traveler,
    steps: null
  },
  {
    type: StepType.OAG_CHECKOUT_CONFIRMATION,
    slug: 'confirmation',
    title: '',
    label: 'Confirmation',
    component: Confirmation,
    steps: null
  }
];
