import React from 'react';
import { useTranslation } from '@ibe/components';
import fallback from '../../../../../Translations/generated/Checkout.de.json';
import Keys from '../../../../../Translations/generated/Checkout.de.json.keys';
import { useExtendedBooking } from '../ContextAndProvider';

const Separator = () => {
  const { t } = useTranslation('Checkout', fallback);
  const { selectedItems } = useExtendedBooking();
  const { extensionDuration } = selectedItems?.duration || {};
  return (
    <div className="extended__wrapper__header__separator">
      <h2>{t(Keys.extensionDetail)}</h2>
      <p>
        {t(Keys.yourExtension)} {`${extensionDuration ? extensionDuration - 1 : 0}`}
        {t(Keys.numberOfNight, { count: extensionDuration ? extensionDuration - 1 : 0 })}.
      </p>
    </div>
  );
};

export default Separator;
