import { PackageParams } from '@ibe/components';
import { encodeObjectasUrlParam, LoggerFactory, removeUndefinedProps } from '@ibe/services';
import { ApiGeoUnitFromJSON } from '@ibe/api';

export interface RawPackageParams {
  start?: string;
  end?: string;
  origin?: string;
  origins?: string;
  originCode?: string;
  destination?: string;
  destinationCode?: string;
  detail?: string;
  item?: string;
  date?: string;
  occupancy?: string;
  adults?: string;
  children?: string;
  infants?: string;
  childrenAges?: string;
  packageServiceGroup?: string;
  productId?: string;
  goToDetails?: string;
  destinationItem?: string;
  mainServiceCodes?: string;
  duration?: string;
}

class PackageParamsTransformer {
  static decode(urlParams: RawPackageParams): Partial<PackageParams> {
    const logger = LoggerFactory.get('PackageParamsTransformer');
    const params: Partial<PackageParams> = {};
    if (!urlParams) {
      return params;
    }
    try {
      params.startDate = urlParams.start || undefined;
      params.endDate = urlParams.end || undefined;
      params.packageDetailId = urlParams.detail || undefined;
      params.travelDateId = urlParams.date || undefined;
      params.itemId = urlParams.item || undefined;
      params.productId = urlParams.productId || undefined;
      if (urlParams.goToDetails) {
        params.goToDetails = JSON.parse(urlParams.goToDetails);
      }
      if (urlParams.destinationItem) {
        params.destinationItem = JSON.parse(urlParams.destinationItem);
      }
      if (urlParams.mainServiceCodes) {
        params.mainServiceCodes = JSON.parse(urlParams.mainServiceCodes);
      }
      if (urlParams.duration) {
        params.duration = JSON.parse(urlParams.duration);
      }
      if (urlParams.occupancy) {
        params.occupancy = JSON.parse(urlParams.occupancy);
      } else if (urlParams.adults || urlParams.children || urlParams.infants) {
        params.occupancy = [
          {
            adults: urlParams.adults ? Number(urlParams.adults) : 2,
            children: urlParams.children ? Number(urlParams.children) : 0,
            infants: urlParams.infants ? Number(urlParams.infants) : 0,
            childrenAges: urlParams.childrenAges ? JSON.parse(urlParams.childrenAges) : [],
            infantsAges: []
          }
        ];
      }
      if (urlParams.origin) {
        params.origin = JSON.parse(urlParams.origin);
      } else if (urlParams.originCode) {
        params.origin = ApiGeoUnitFromJSON({
          id: '',
          code: urlParams.originCode,
          name: '',
          typeCode: '',
          children: {}
        });
      }
      if (urlParams.origins) {
        params.origins = JSON.parse(urlParams.origins);
      }
      if (urlParams.destination) {
        params.destination = JSON.parse(urlParams.destination);
      } else if (urlParams.destinationCode) {
        params.destination = ApiGeoUnitFromJSON({
          id: '',
          code: urlParams.destinationCode,
          name: '',
          typeCode: '',
          children: {}
        });
      }
      params.packageServiceGroup = urlParams.packageServiceGroup || undefined;
    } catch (e) {
      logger.error(e);
    }
    return removeUndefinedProps(params);
  }

  static encode(params: Partial<PackageParams>): string {
    if (params.destinationItem) {
      params.destinationItem.name = encodeURIComponent(params.destinationItem.name);
    }
    const obj: RawPackageParams = {
      start: params.startDate,
      end: params.endDate,
      origin: JSON.stringify(params.origin),
      origins: JSON.stringify(params.origins),
      destination: JSON.stringify(params.destination),
      productId: params.productId,
      occupancy: JSON.stringify(params.occupancy),
      date: params.travelDateId,
      detail: params.packageDetailId,
      item: params.itemId,
      packageServiceGroup: params.packageServiceGroup,
      goToDetails: JSON.stringify(params.goToDetails),
      destinationItem: JSON.stringify(params.destinationItem),
      mainServiceCodes: JSON.stringify(params.mainServiceCodes),
      duration: JSON.stringify(params.duration)
    };

    return encodeObjectasUrlParam(removeUndefinedProps(obj));
  }
}

export default PackageParamsTransformer;
