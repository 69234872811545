import { SessionPersistence, SessionStoragePersistence } from '@ibe/services';
import { useConfig, useExternalSearchParams } from '@ibe/components';

export class DetailUrl {
  url: string;
}

const useUrlPersistence = ():
  | SessionStoragePersistence<DetailUrl>
  | SessionPersistence<DetailUrl> => {
  const context = useExternalSearchParams();
  const config = useConfig();

  let sessionPersistence;
  if (!!context?.widgetSessionKeyPrefix) {
    sessionPersistence = new SessionStoragePersistence<DetailUrl>(DetailUrl);
    sessionPersistence.useKey(context.widgetSessionKeyPrefix + config.sessionKeySearchParams);
  } else {
    sessionPersistence = new SessionPersistence<DetailUrl>(DetailUrl);
    sessionPersistence.useKey(config.sessionKeySearchParams);
  }
  return sessionPersistence;
};

export default useUrlPersistence;
