const randomNumber = (): number => Math.floor(Math.random() * 5);

const getTravelerMockData = (): Record<string, unknown> => {
  return {
    title: ['Prof. Dr.', 'Dipl. Inform'][randomNumber()],
    salutation: ['DI', 'FR', 'DI', 'HR', 'DI'][randomNumber()],
    firstname: ['Firstname1', 'Firstname2', 'Firstname3', 'Firstname4', 'Firstname5'][
      randomNumber()
    ],
    lastname: ['Lastname1', 'Lastname2', 'Lastname3', 'Lastname4', 'Lastname5'][randomNumber()],
    birthDate: ['1960-06-26', '1970-07-27', '1980-08-28', '1990-09-29', '2000-10-30'][
      randomNumber()
    ],
    nationality: ['DE', 'GG', 'NC', 'IC', 'IT'][randomNumber()],
    street: ['Street1', 'Street2', 'Street3', 'Street4', 'Street5'][randomNumber()],
    houseNumber: ['10', '20', '30', '30', '50'][randomNumber()],
    city: ['City1', 'City2', 'City3', 'City4', 'City5'][randomNumber()],
    zipCode: ['12345', '23456', '34567', '45678', '56789'][randomNumber()],
    country: 'DE',
    phone: ['93874054', '89605456', '23409857', '689098345', '0749385765'][randomNumber()],
    email: [
      'test1@testing.net',
      'test2@testing.net',
      'test3@testing.net',
      'test4@testing.net',
      'test5@testing.net'
    ][randomNumber()]
  };
};

export default getTravelerMockData;
