import React, { MouseEvent, useCallback, useMemo, useState } from 'react';
import {
  ApiAddItemRequest,
  ApiAddItemRequestFromJSONTyped,
  ApiExtraService,
  ApiItemType,
  ApiPriceModifierType,
  ApiTravelerFromJSON
} from '@ibe/api';
import { Button } from 'reactstrap';
import {
  ComponentWrapper,
  getCurrentSelectionState,
  isRailAndFly,
  MetaInfoCmsContent,
  priceMultiplier
} from './additionals';
import ExtraModal from './ExtraModal';
import { sanitize } from '@ibe/services';
import { useTheme } from '@emotion/react';
import { Price, ShowMore, useBookingService, useTranslation } from '@ibe/components';
import fallback from '../../../../Translations/generated/optional-offers.de.json';
import Keys from '../../../../Translations/generated/optional-offers.de.json.keys';
import { observer } from 'mobx-react';

export interface ExtraProps {
  extra: ComponentWrapper;
  extras: ComponentWrapper[];
  mode?: 'VERTICAL' | 'HORIZONTAL';
  metaInfoCmsContent?: MetaInfoCmsContent;
  isLoading?: boolean;
}

const Extra = observer(function Extra({
  mode,
  extra,
  extras,
  metaInfoCmsContent,
  isLoading
}: ExtraProps): JSX.Element {
  const { t } = useTranslation('optional-offers', fallback, Keys);
  const theme = useTheme();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const { itemLevel, unitLevel } = extra;
  const bs = useBookingService();

  const price = useMemo(() => {
    const basePrice = !!unitLevel ? unitLevel.price : itemLevel.price;
    if (isRailAndFly(extra.component)) {
      if (extra.relatedComponentItemLevel) {
        return {
          ...extra.itemLevel.price,
          finalPrice:
            extra.itemLevel.price.finalPrice + extra.relatedComponentItemLevel.price.finalPrice
        };
      } else {
        return extra.itemLevel.price;
      }
    }
    const participantModifierPrice = basePrice?.modifiers?.find(
      modifier => modifier.type === ApiPriceModifierType.PARTICIPANT
    )?.absolute;
    return {
      ...basePrice,
      finalPrice: !!participantModifierPrice ? participantModifierPrice : basePrice.finalPrice
    };
  }, [itemLevel, unitLevel, extras, extra]);

  const currentSelection = useMemo(() => {
    return getCurrentSelectionState(bs.packageCart?.itemsBookingOptions || {}, extra);
  }, [bs.packageCart?.itemsBookingOptions, extra]);

  const imageTile = useMemo(
    () =>
      metaInfoCmsContent?.imageTile?.small ||
      metaInfoCmsContent?.imageTile?.large ||
      metaInfoCmsContent?.imageTile?.extraSmall,
    [metaInfoCmsContent]
  );

  const handleModalSubmit = useCallback(
    (selectedTravelerIds: string[]): void => {
      const options: { [key: string]: ApiAddItemRequest } = {};
      if (extra.unitLevel?.id && extra.unitLevel?.unitRates) {
        if (selectedTravelerIds.length > 0) {
          const travelerOption: ApiAddItemRequest = ApiAddItemRequestFromJSONTyped(
            {
              travelers: selectedTravelerIds.map(id => ApiTravelerFromJSON({ id: id }))
            },
            true
          );
          options[extra.unitLevel.unitRates[0].id] = travelerOption;
          bs.selectItemsInPackageCart(
            extra.component.id,
            [extra.unitLevel.unitRates[0].id],
            options
          ).then(async () => {
            await bs.updatePackageCartBooking();
          });
        } else {
          bs.removeItemFromPackageCart(extra.component.id, extra.unitLevel?.unitRates[0].id).then(
            async () => {
              await bs.updatePackageCartBooking();
            }
          );
        }
      } else if (extra.itemLevel) {
        if (selectedTravelerIds.length > 0) {
          const itemTravelerOption: ApiAddItemRequest = ApiAddItemRequestFromJSONTyped(
            {
              travelers: selectedTravelerIds.map(id => ApiTravelerFromJSON({ id: id }))
            },
            true
          );
          const itemOptions: { [key: string]: ApiAddItemRequest } = {};
          itemOptions[extra.itemLevel.id] = itemTravelerOption;
          bs.selectItemsInPackageCart(extra.component.id, [extra.itemLevel.id], itemOptions).then(
            async () => {
              if (extra.relatedComponent && extra.relatedComponentItemLevel) {
                const relatedItemOptions: { [key: string]: ApiAddItemRequest } = {};
                relatedItemOptions[extra.relatedComponentItemLevel.id] = itemTravelerOption;
                await bs.selectItemsInPackageCart(
                  extra.relatedComponent.id,
                  [extra.relatedComponentItemLevel.id],
                  relatedItemOptions
                );
              }
              await bs.updatePackageCartBooking();
            }
          );
        } else {
          bs.removeItemFromPackageCart(extra.component.id, extra.itemLevel.id).then(async () => {
            if (extra.relatedComponentItemLevel && extra.relatedComponent) {
              await bs.removeItemFromPackageCart(
                extra.relatedComponent.id,
                extra.relatedComponentItemLevel.id
              );
            }
            await bs.updatePackageCartBooking();
          });
        }
      }

      setModalOpen(false);
    },
    [extra, bs.booking?.travelers]
  );

  const hasAnyRestrictedExtraSelected = useCallback((): boolean => {
    if (
      extra.component.itemType === ApiItemType.EXTRA &&
      extra.component.selectedItems.length > 0
    ) {
      const currentExtra = extra.itemLevel as ApiExtraService;
      return extra.component.selectedItems.some(item => {
        const candidate: ApiExtraService = item as ApiExtraService;
        return (
          candidate.code !== currentExtra.code &&
          candidate.relatedServices &&
          candidate.relatedServices.some(related => related.code === currentExtra.code)
        );
      });
    }
    return false;
  }, [extra]);

  const handleDirectSubmit = useCallback(
    (e: MouseEvent<HTMLButtonElement>): void => {
      (e.target as HTMLButtonElement).blur();
      if (currentSelection.length > 0 || hasAnyRestrictedExtraSelected()) {
        setModalOpen(true);
      } else {
        const options: { [key: string]: ApiAddItemRequest } = {};
        let itemId: string | undefined = undefined;
        if (extra.unitLevel?.id && extra.unitLevel?.unitRates) {
          itemId = extra.unitLevel?.unitRates[0].id;
        } else if (extra.itemLevel) {
          itemId = extra.itemLevel.id;
        }

        if (itemId) {
          const travelerOption: ApiAddItemRequest = ApiAddItemRequestFromJSONTyped(
            {
              travelers: bs.booking?.travelers
            },
            true
          );
          options[itemId] = travelerOption;
          bs.selectItemsInPackageCart(extra.component.id, [itemId], options).then(async () => {
            if (extra.relatedComponent && extra.relatedComponentItemLevel) {
              const relatedOptions: { [key: string]: ApiAddItemRequest } = {};
              relatedOptions[extra.relatedComponentItemLevel.id] = travelerOption;
              await bs.selectItemsInPackageCart(
                extra.relatedComponent.id,
                [extra.relatedComponentItemLevel.id],
                relatedOptions
              );
            }

            await bs.updatePackageCartBooking();
          });
        }
      }
    },
    [extra]
  );

  return (
    <div
      className={`optional-offer__extra__container ${
        mode === 'HORIZONTAL' || metaInfoCmsContent?.design === 'extras'
          ? ' optional-offer__extra__container--horizontal'
          : ''
      }`}
    >
      <div className="optional-offer__extra">
        <div className="optional-offer__extra__image-container">
          <img
            src={!!imageTile ? imageTile.url : theme.placeholderImages.package}
            alt={imageTile?.altText || metaInfoCmsContent?.imageTile?.imageAlt || ''}
          />
        </div>
        <div className="optional-offer__extra__content">
          <div className="optional-offer__extra__content__upper">
            {!!metaInfoCmsContent?.displayName ? (
              <h3 dangerouslySetInnerHTML={{ __html: sanitize(metaInfoCmsContent.displayName) }} />
            ) : (
              <h3>
                {extra?.itemLevel?.name || extra?.component?.name || extra?.unitLevel?.name || ''}
              </h3>
            )}
            {!!metaInfoCmsContent?.description ? (
              <>
                <p>
                  <ShowMore
                    lines={!!metaInfoCmsContent?.more ? 3 : 4}
                    more={t(Keys.more)}
                    less={t(Keys.less)}
                    buttonClass="optional-offer__extra__show-more-button"
                    hideEllipsis
                    externalMore={(): void => setModalOpen(true)}
                    alwaysShowMore={metaInfoCmsContent?.more}
                  >
                    {metaInfoCmsContent.description}
                  </ShowMore>
                </p>
              </>
            ) : (
              <></>
            )}
          </div>
          <div className="optional-offer__extra__content__lower">
            <div className="optional-offer__extra__content__price mb-3">
              <Price price={price} removeZeroDecimals />
              <div className="per-person">{t(Keys.perPerson)}</div>
            </div>
            <div className="optional-offer__extra__content__button">
              {currentSelection.length > 0 &&
              priceMultiplier(extra, bs.packageCart?.itemsBookingOptions || {}) > 0 ? (
                <div>{`${priceMultiplier(extra, bs.packageCart?.itemsBookingOptions || {})}x ${t(
                  Keys.selected
                )}`}</div>
              ) : (
                <></>
              )}
              <Button
                color={currentSelection.length > 0 ? 'secondary' : 'primary'}
                onClick={handleDirectSubmit}
              >
                {currentSelection.length > 0 ? t(Keys.change) : t(Keys.add)}
              </Button>
            </div>
          </div>
        </div>
        <ExtraModal
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          onSubmit={handleModalSubmit}
          price={price}
          extra={extra}
          itemsBookingOptions={bs.packageCart?.itemsBookingOptions || {}}
          metaInfoCmsContent={metaInfoCmsContent}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
});

export default Extra;
