import React, { useEffect, useMemo, useState } from 'react';
import CommonWrapper, { RemountWrapper } from '../CommonWrapper';
import { ApiComponent, ApiHotel, ApiRelatedServiceRelationType } from '@ibe/api';
import { useBookingService, useTranslation } from '@ibe/components';
import { useExtendedBooking } from '../ContextAndProvider';
import fallback from '../../../../../Translations/generated/Checkout.de.json';
import Keys from '../../../../../Translations/generated/Checkout.de.json.keys';
import HotelCarouselComponent from './HotelCarousel/HotelCarouselComponent';
import { getSelectableRoomNumbersCombinations } from './RoomSelection/useRoomSelection';

const HotelMainComponent = ({ idx, remount }: { idx: number; remount?: () => void }) => {
  const { selectedItems } = useExtendedBooking();
  const { mainComponent } = selectedItems?.duration || {};
  return <Hotel idx={idx} hotelComponent={mainComponent} remount={remount} />;
};

const HotelOptionalComponent = ({ idx, remount }: { idx: number; remount?: () => void }) => {
  const { selectedItems } = useExtendedBooking();
  const { selectedExtensionComponent } = selectedItems?.duration || {};
  return (
    <Hotel idx={idx} hotelComponent={selectedExtensionComponent} remount={remount} isOptional />
  );
};

const Hotel = ({
  idx,
  hotelComponent,
  isOptional,
  remount
}: {
  idx: number;
  hotelComponent?: ApiComponent;
  isOptional?: boolean;
  remount?: () => void;
}) => {
  const { selectedItems, setSelectedItems } = useExtendedBooking();
  const bs = useBookingService();
  const numberOfTravelers = bs.booking?.travelers.length;
  const [selectedHotel, setSelectedHotel] = useState<ApiHotel | undefined>(undefined);

  useEffect(() => {
    const foundHotelFromSelectables = hotelComponent?.selectableItems.find(
      selectableItem => selectableItem.id === hotelComponent?.selectedItems?.[0]?.id
    ) as ApiHotel;
    const preSelectedHotel =
      (isOptional ? selectedItems?.hotelOptional : selectedItems?.hotelMain) ||
      foundHotelFromSelectables;
    setSelectedHotel(preSelectedHotel);
  }, [hotelComponent, selectedItems?.hotelOptional, selectedItems?.hotelMain]);

  const successNotes = useMemo(() => {
    return isOptional
      ? [selectedItems?.hotelOptional?.name || '']
      : [selectedItems?.hotelMain?.name || ''];
  }, [selectedItems?.hotelMain, selectedItems?.hotelOptional]);

  const { t } = useTranslation('Checkout', fallback);

  const hotels: ApiHotel[] = useMemo(() => {
    if (!hotelComponent || !numberOfTravelers) return [];
    return getOrderedHotels(hotelComponent?.selectableItems as ApiHotel[])
      .flatMap(hotel => {
        if (isOptional) {
          return {
            ...hotel,
            availability: selectedItems?.hotelMain?.relatedServices?.find(
              relatedService =>
                relatedService.code === hotel.code &&
                relatedService.relationType === ApiRelatedServiceRelationType.EXCLUDE
            )
              ? 0
              : hotel.availability
          };
        }
        return hotel;
      })
      .flatMap(hotel => {
        if (hotel.availability === 0) return hotel;
        const combinationBasedOnAvailability = getSelectableRoomNumbersCombinations(
          numberOfTravelers,
          hotelComponent,
          hotel
        );
        if (!combinationBasedOnAvailability.length) return { ...hotel, availability: 0 };
        return hotel;
      });
  }, [hotelComponent, selectedItems, numberOfTravelers]);

  const onNext = () => {
    if (selectedHotel) {
      if (isOptional) {
        setSelectedItems({
          duration: selectedItems.duration,
          travelers: selectedItems.travelers,
          hotelMain: selectedItems.hotelMain,
          roomsMain: selectedItems.roomsMain,
          cateringMain: selectedItems.cateringMain,
          hotelOptional: selectedHotel
        });
      } else {
        setSelectedItems({
          duration: selectedItems.duration,
          travelers: selectedItems.travelers,
          hotelMain: { ...selectedHotel }
        });
      }
    }
  };

  const radioSelectionChanged = (hotel: ApiHotel) => {
    setSelectedHotel(hotel);
  };

  return (
    <CommonWrapper
      idx={idx}
      description={isOptional ? t(Keys.extBookingSelectYourHotel) : t(Keys.selectYourHotel)}
      title={isOptional ? t(Keys.extBookingYourHotelOptional) : t(Keys.extBookingYourHotel)}
      successNotes={successNotes}
      onNext={onNext}
      disableButton={!selectedHotel}
      onClose={remount}
    >
      <div className={'carousel-wrapper'}>
        {!!hotels && (
          <HotelCarouselComponent
            hotels={hotels}
            selectedHotel={selectedHotel}
            initialHotel={hotels[0]}
            onChange={radioSelectionChanged}
          />
        )}
      </div>
    </CommonWrapper>
  );
};

export const HotelMain = ({ idx }: { idx: number }) => (
  <RemountWrapper Component={HotelMainComponent} props={{ idx }} />
);

export const HotelOptional = ({ idx }: { idx: number }) => (
  <RemountWrapper Component={HotelOptionalComponent} props={{ idx }} />
);

export const getOrderedHotels = (selectableItems: ApiHotel[]) => {
  return ((selectableItems || []) as ApiHotel[]).sort((h1, h2) => {
    if (h1.availability === 0 && h2.availability !== 0) return 1;
    if (h1.availability !== 0 && h2.availability === 0) return -1;

    return h1.price.finalPrice - h2.price.finalPrice;
  });
};
