import React, { useState } from 'react';
import { faCheck, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'reactstrap';
import { sanitize } from '@ibe/services';
import { MEDIAQUERY_DEFAULTS, useConfig, useMediaQuery } from '@ibe/components';
import useThgConfig from '../../../../Hooks/useThgConfig';

const InsuranceItem = ({
  id,
  text,
  info,
  noInsurance
}: {
  id: string;
  text: string;
  info: string;
  noInsurance: boolean;
}): JSX.Element => {
  const config = useConfig();
  const thgConfig = useThgConfig();
  const baseUrl = thgConfig.baseUrl || config.translationsUrl;

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  const isTablet = useMediaQuery({ query: MEDIAQUERY_DEFAULTS.md, type: 'max' });

  return (
    <div id={id + '_cont'} className="insurance__item">
      {!noInsurance && (
        <FontAwesomeIcon icon={faCheck} className="d-inline item_check" color="#00A760" />
      )}
      {noInsurance && <img src={`${baseUrl}/img/no_insurance.svg`} alt="no insurance logo" />}
      <span className="item_text">{text}</span>
      {info && (
        <>
          <FontAwesomeIcon
            id={id}
            icon={faInfoCircle}
            className="d-inline item_info"
            color="#004F9E"
          />

          <Tooltip
            placement={isTablet ? 'bottom-end' : 'bottom'}
            isOpen={tooltipOpen}
            target={id}
            toggle={toggle}
            popperClassName="tooltip-popper"
            innerClassName="tooltip-inner"
            container={id + '_cont'}
            onClick={toggle}
          >
            <div className="tooltip__text">{text}</div>
            <div
              className="tooltip__info"
              dangerouslySetInnerHTML={{
                __html: sanitize(info)
              }}
            />
          </Tooltip>
        </>
      )}
    </div>
  );
};

export default InsuranceItem;
