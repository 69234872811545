import React, { useCallback } from 'react';
import { CustomInput } from 'reactstrap';
import { PromotionCodeData } from './PromotionCode';

const SingleSelection = ({
  isChecked,
  setIsChecked,
  infoText,
  checkboxLabel,
  imgSrc,
  promotionCode
}: {
  isChecked: boolean;
  setIsChecked: (isChecked: boolean) => void;
  imgSrc?: string;
  infoText?: string;
  checkboxLabel: string;
  promotionCode: PromotionCodeData;
}): JSX.Element => {
  const handleChange = useCallback((): void => {
    setIsChecked(!isChecked);
  }, [isChecked, setIsChecked, promotionCode]);

  return (
    <div className="small-group-selection">
      <div className="small-group-selection__info">
        {!!imgSrc ? <img src={imgSrc} alt="Kleingruppenreise" /> : <></>}
        <span>{infoText}</span>
      </div>
      <CustomInput
        id="small-group-selection"
        type="checkbox"
        checked={isChecked}
        onChange={handleChange}
        label={checkboxLabel}
      />
    </div>
  );
};

export default SingleSelection;
