import React from 'react';
import { useConfig } from '@ibe/components';
import useThgConfig from '../Hooks/useThgConfig';

const CustomAnimationSpinner = (): JSX.Element => {
  const config = useConfig();
  const thgConfig = useThgConfig();
  const baseUrlStr = thgConfig.baseUrl || config.translationsUrl;
  return (
    <div className={'animated-spinner-container'}>
      <img
        className="animated-spinner"
        src={`${baseUrlStr}/img/ladebildschirm_900x506px_flug.gif`}
        alt="a loading spinner"
      />
    </div>
  );
};

export default CustomAnimationSpinner;
