import {
  ApiAddItemRequest,
  ApiComponent,
  ApiComponentType,
  ApiHotel,
  ApiItemType,
  ApiRoomRate,
  ApiTravelerType
} from '@ibe/api';
import React, { useMemo, useState } from 'react';
import {
  extendedBookingStorage,
  SelectedItemsType,
  useExtendedBooking
} from '../ContextAndProvider';
import CommonWrapper, { RemountWrapper } from '../CommonWrapper';
import fallback from '../../../../../Translations/generated/Checkout.de.json';
import Keys from '../../../../../Translations/generated/Checkout.de.json.keys';
import { Price, useBookingService, useTranslation } from '@ibe/components';
import { Col, CustomInput, Row } from 'reactstrap';
import { useCollapsibleWizard } from '../../CollapsibleWizard/ContextAndProvider';
import { TFunction } from 'i18next';

const CateringMainComp = ({ idx, remount }: { idx: number; remount?: () => void }) => {
  const { selectedItems } = useExtendedBooking();
  const { mainComponent } = selectedItems?.duration || {};
  const hotel = selectedItems.hotelMain;
  return <CateringWrapper idx={idx} component={mainComponent} hotel={hotel} remount={remount} />;
};

const CateringOptionalComp = ({ idx, remount }: { idx: number; remount?: () => void }) => {
  const { selectedItems } = useExtendedBooking();
  const { selectedExtensionComponent } = selectedItems?.duration || {};
  const hotel = selectedItems.hotelOptional;
  return (
    <CateringWrapper
      idx={idx}
      component={selectedExtensionComponent}
      hotel={hotel}
      remount={remount}
      isOptional
    />
  );
};

const CateringWrapper = (props: {
  idx: number;
  hotel?: ApiHotel;
  component?: ApiComponent;
  isOptional?: boolean;
  remount?: () => void;
}) => {
  const { t } = useTranslation('Checkout', fallback);
  const { idx, hotel, component, isOptional, remount } = props;
  if (!hotel || !component) {
    return (
      <CommonWrapper
        idx={idx}
        title={t(Keys.yourCatering)}
        description={t(Keys.extBookingSelectYourMealType)}
        successNotes={[]}
      >
        <></>
      </CommonWrapper>
    );
  }

  return (
    <Catering
      component={component}
      hotel={hotel}
      idx={idx}
      isOptional={isOptional}
      remount={remount}
    />
  );
};

const Catering = ({
  component,
  hotel,
  idx,
  isOptional,
  remount
}: {
  component: ApiComponent;
  hotel: ApiHotel;
  idx: number;
  isOptional?: boolean;
  remount?: () => void;
}) => {
  const { t } = useTranslation('Checkout', fallback);
  const { selectedItems, setSelectedItems } = useExtendedBooking();
  const { items, nextStep } = useCollapsibleWizard();

  const {
    differentMealTypeCodes,
    setSelectedCatering,
    successNotes,
    disableButton
  } = useCateringSelectionState(hotel, component, t, isOptional);
  const bs = useBookingService();

  const onNext = () => {
    const selectedMealType = differentMealTypeCodes.find(diff => diff.isSelected);
    if (selectedMealType) {
      const { mealTypeCode, mealTypeDescription, roomRate } = selectedMealType;
      const cateringInfo = { mealTypeCode, mealTypeDescription, rateId: roomRate.id };

      const chooseElements: SelectedItemsType = isOptional
        ? {
            duration: selectedItems.duration,
            travelers: selectedItems.travelers,
            hotelMain: selectedItems.hotelMain,
            roomsMain: selectedItems.roomsMain,
            cateringMain: selectedItems.cateringMain,
            hotelOptional: selectedItems.hotelOptional,
            roomsOptional: selectedItems.roomsOptional,
            cateringOptional: cateringInfo
          }
        : {
            duration: selectedItems.duration,
            travelers: selectedItems.travelers,
            hotelMain: selectedItems.hotelMain,
            roomsMain: selectedItems.roomsMain,
            cateringMain: cateringInfo
          };
      setSelectedItems(chooseElements);
      nextStep(idx, true);

      const updatedData = { ...chooseElements, steps: items };
      if (!isOptional) {
        updatedData.hotelComponents = (bs?.packageCart?.packageModel.packageDetails || [])
          .flatMap(detail => detail.components)
          .filter(comp => comp.itemType === ApiItemType.HOTEL);
      }
      extendedBookingStorage.set(updatedData);

      const packageCart = bs.packageCart;

      // first delete optional hotel if necessary
      if (selectedItems.duration && packageCart) {
        let removeOptionalHotel = false;
        const packageDetails = packageCart.packageModel.packageDetails;
        let selectedOptionalHotelComponent: ApiComponent | undefined = undefined;

        if (packageDetails) {
          const optionalHotels = packageDetails[0].components.filter(
            c =>
              c.componentType === ApiComponentType.OPTIONAL &&
              c.itemType === ApiItemType.HOTEL &&
              c.selectedItems.length > 0
          );
          selectedOptionalHotelComponent =
            optionalHotels.length > 0 ? optionalHotels[0] : undefined;
        }

        removeOptionalHotel =
          selectedItems.duration.selectedExtensionComponent === undefined ||
          selectedItems.duration.selectedExtensionComponent.id !==
            selectedOptionalHotelComponent?.id;

        if (removeOptionalHotel && selectedOptionalHotelComponent) {
          const roomRateIds: string[] = [];

          selectedOptionalHotelComponent.selectedItems.forEach(item => {
            const hotelItem = item as ApiHotel;
            hotelItem.rooms.forEach(room => {
              room.roomrates.forEach(rate => roomRateIds.push(rate.id));
            });
          });

          if (roomRateIds.length > 0) {
            bs.removeItemsFromPackageCart(selectedOptionalHotelComponent.id, roomRateIds);
          }
        }
      }

      // next add newly selected item
      const selectedItemsRooms = !isOptional
        ? selectedItems.roomsMain
        : selectedItems.roomsOptional;

      if (selectedItemsRooms) {
        const selectedItemsIds: string[] = [];
        const bookingOptions: {
          [key: string]: ApiAddItemRequest;
        } = {};

        selectedItemsRooms.forEach(mr => {
          const rate = mr.room.roomrates.find(r => r.mealTypeCode === cateringInfo.mealTypeCode);
          if (rate) {
            if (!selectedItemsIds.find(i => i === rate.id)) {
              selectedItemsIds.push(rate.id);
            }
            const existingOption = bookingOptions[rate.id];

            if (existingOption) {
              bookingOptions[rate.id] = {
                count: existingOption.count + 1,
                travelers: [...existingOption.travelers, ...mr.travelers]
              } as ApiAddItemRequest;
            } else {
              bookingOptions[rate.id] = {
                count: 1,
                travelers: mr.travelers
              } as ApiAddItemRequest;
            }
          }
        });

        if (selectedItemsIds.length > 0) {
          bs.selectItemsInPackageCart(component.id, selectedItemsIds, bookingOptions).then(
            async () => {
              await bs.updatePackageCartBooking();
            }
          );
        }
      }
    }
  };

  return (
    <CommonWrapper
      idx={idx}
      title={isOptional ? t(Keys.yourCateringOptional) : t(Keys.yourCatering)}
      description={t(Keys.extBookingSelectYourMealType)}
      successNotes={successNotes}
      disableButton={disableButton}
      onNext={onNext}
      onClose={remount}
    >
      <div className="mt-0 mt-lg-3">
        {differentMealTypeCodes.map(differentMealTypeCode => {
          const {
            mealTypeDescription,
            isSelected,
            mealTypeCode,
            priceDifference,
            isInclusive,
            roomRate
          } = differentMealTypeCode;
          return (
            <div key={mealTypeCode}>
              <Row>
                <Col>
                  <CustomInput
                    id={`extended-booking-item-${mealTypeCode}`}
                    name={`extended-booking-item-${mealTypeCode}`}
                    className="extended__booking__custom__input"
                    type="radio"
                    label={
                      <div
                        className={`extended__booking__item ${
                          !!isSelected ? 'extended__booking__item--selected' : ''
                        }`}
                      >
                        <span className="custom-checkbox"></span>
                        <div className="extended__booking__whitespace">{mealTypeDescription}</div>
                      </div>
                    }
                    checked={isSelected}
                    onChange={() => setSelectedCatering(roomRate.id)}
                  />
                </Col>
                <Col className="text-right">
                  {isInclusive && (
                    <span className="extended__booking__catering__inclusive">
                      {t(Keys.inclusive)}
                    </span>
                  )}
                  {!isInclusive && (
                    <>
                      <Price
                        className="extended__booking__item__price"
                        prefix={priceDifference >= 0 ? '+ ' : ''}
                        price={{
                          ...roomRate?.totalPrice,
                          finalPrice: priceDifference,
                          currencyCode: roomRate?.totalPrice?.currencyCode
                        }}
                      />
                      <span className="extended__booking__catering__per-person">
                        {t(Keys.extBookingPerPerson)}
                      </span>
                    </>
                  )}
                </Col>
              </Row>
            </div>
          );
        })}
      </div>
    </CommonWrapper>
  );
};

type MealTypeCodListItem = {
  roomRate: ApiRoomRate;
  mealTypeCode: string;
  mealTypeDescription: string;
  isSelected: boolean;
  priceDifference: number;
  isInclusive: boolean;
};

export const getPersonPriceFromRate = (
  rate: ApiRoomRate,
  travelerType?: ApiTravelerType
): number => {
  return (
    rate.pricingInfo.find(info => info.travelerType === (travelerType || ApiTravelerType.ADULT))
      ?.price.finalPrice || 0
  );
};

export const getPersonPriceFromCheapestSortedRates = (
  rates: ApiRoomRate[],
  travelerType?: ApiTravelerType
): number => {
  const sortedRates = rates.sort((a, b) => a.totalPrice.finalPrice - b.totalPrice.finalPrice);
  const cheapestRate = sortedRates[0];
  return (
    cheapestRate.pricingInfo.find(
      info => info.travelerType === (travelerType || ApiTravelerType.ADULT)
    )?.price.finalPrice || 0
  );
};

export const useCateringSelectionState = (
  hotel: ApiHotel,
  component: ApiComponent,
  t: TFunction,
  isOptional?: boolean
) => {
  const {
    selectedItems: { cateringMain, cateringOptional }
  } = useExtendedBooking();

  const foundHotel = (component.selectableItems as ApiHotel[]).find(
    selectableItem => selectableItem.code === hotel.code
  );

  const possibleRates = foundHotel?.rooms[0].roomrates.sort(
    (a, b) => a.totalPrice.finalPrice - b.totalPrice.finalPrice
  );

  const cheapestRate = possibleRates && possibleRates[0];

  const [selectedCatering, setSelectedCatering] = useState<string | undefined>(
    (isOptional ? cateringOptional?.rateId : cateringMain?.rateId) || cheapestRate?.id
  );

  const differentMealTypeCodes: MealTypeCodListItem[] = useMemo(() => {
    const uniqueMealTypes: MealTypeCodListItem[] = [];
    possibleRates?.forEach(roomRate => {
      uniqueMealTypes.push({
        roomRate,
        mealTypeCode: roomRate.mealTypeCode,
        mealTypeDescription:
          roomRate.mealTypeDescription === null ? t(Keys.roomOnly) : roomRate.mealTypeDescription,
        isSelected: roomRate.id === selectedCatering,
        priceDifference: cheapestRate
          ? getPersonPriceFromRate(roomRate) - getPersonPriceFromRate(cheapestRate)
          : 0,

        isInclusive: roomRate.mealTypeCode === cheapestRate?.mealTypeCode
      });
    });
    return [...uniqueMealTypes];
  }, [selectedCatering, foundHotel, component, cateringMain, cateringOptional]);

  const successNotes: string[] = useMemo(() => {
    const note = isOptional
      ? cateringOptional?.mealTypeDescription
      : cateringMain?.mealTypeDescription;
    return [note || ''];
  }, [isOptional, cateringOptional, cateringMain]);

  return {
    differentMealTypeCodes,
    disableButton: !selectedCatering,
    setSelectedCatering,
    successNotes
  };
};

export const CateringMain = ({ idx }: { idx: number }) => (
  <RemountWrapper Component={CateringMainComp} props={{ idx }} />
);
export const CateringOptional = ({ idx }: { idx: number }) => (
  <RemountWrapper Component={CateringOptionalComp} props={{ idx }} />
);
