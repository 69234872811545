import React, { useMemo } from 'react';

import { ApiBooking, ApiTraveler } from '@ibe/api';
import CheckoutStore, { ProductIBEMode } from '../../../../Pages/Checkout/CheckoutStore';
import { steps as collapsibleSteps } from './steps';
import CollapsibleWizardWithProvider from '../CollapsibleWizard/CollapsibleWizard';
import FixedBottomBar from './FixedBottomBar';
import { ExtendedBookingProvider, extendedBookingStorage } from './ContextAndProvider';

interface Props {
  booking: ApiBooking;
  next: () => void;
  disabled: boolean;
  loading: boolean;
  productIBEMode?: ProductIBEMode;
  store: CheckoutStore;
  showPromotionCode?: boolean;
  handleBookingError?: (errorMessage: string) => void;
  travelers?: ApiTraveler[];
  render: (a: JSX.Element) => JSX.Element;
  isWhitelabel?: boolean;
}

const ExtendedBooking = ({ store, render, ...props }: Props) => {
  const steps = useMemo(() => {
    const storageSteps = extendedBookingStorage.get()?.steps;
    if (storageSteps && storageSteps?.length) {
      return storageSteps.flatMap((storageStep, index) => {
        return { ...storageStep, component: collapsibleSteps[index].component };
      });
    }
    return collapsibleSteps.flatMap((step, index) => ({
      ...step,
      component: collapsibleSteps[index].component
    }));
  }, []);

  const collapsibleWizard = (
    <CollapsibleWizardWithProvider items={steps}>
      <FixedBottomBar {...props} />
    </CollapsibleWizardWithProvider>
  );
  return (
    <ExtendedBookingProvider store={store}>{render(collapsibleWizard)}</ExtendedBookingProvider>
  );
};
export default ExtendedBooking;
