import { ThgConfigModel } from '../Config/ThgConfigModel';
import { fetchJSON } from '@ibe/services';

const loadExternalWidgetConfig = async (): Promise<Partial<ThgConfigModel> | undefined> => {
  const scriptElement = window?.document?.getElementById('thg');

  if (scriptElement) {
    const src = scriptElement.getAttribute('src');

    if (src) {
      const configuredThgConfigModel: Partial<ThgConfigModel> = {};
      let url: URL | string;
      let baseUrl: string;

      if (src.startsWith('http')) {
        url = new URL(src);
        baseUrl = url.origin;
      } else {
        url = src;
        baseUrl = '';
      }

      const baseString = typeof url === 'string' ? url : url.href;
      const baseUrlWithContext = baseString.substring(0, baseString.indexOf('/.resources'));
      configuredThgConfigModel.baseUrl = baseUrlWithContext;

      const externalThgConfig = (await fetchJSON(
        baseUrlWithContext + '/.rest/config/thgConfig'
      )) as Partial<ThgConfigModel>;

      configuredThgConfigModel.configUrl = baseUrl + externalThgConfig.configUrl;
      configuredThgConfigModel.version = externalThgConfig.version;
      configuredThgConfigModel.apiUrl = baseUrlWithContext + '/api';
      configuredThgConfigModel.productApiUrl = baseUrlWithContext + '/.rest';
      configuredThgConfigModel.globistaDataSecurityLink =
        externalThgConfig.globistaDataSecurityLink;

      return configuredThgConfigModel;
    }
  }

  return undefined;
};

export default loadExternalWidgetConfig;
