import { createContext } from 'react';

import AppService from './Services/AppService';
import WorkflowService from './Services/WorkflowService';
// import BookingService from './Services/BookingService';

const appService = new AppService();
export const appContext = createContext(appService);

const workflowService = new WorkflowService();
export const workflowContext = createContext(workflowService);

// const bookingService        = new BookingService();
// export const bookingContext = createContext( bookingService );
