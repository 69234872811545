import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ComponentType, ReactElement, useMemo, useState } from 'react';
import { Button } from 'reactstrap';
import { useCollapsibleWizard } from '../CollapsibleWizard/ContextAndProvider';
import { createUniqueId, sanitize } from '@ibe/services';
import { Element as ScrollTo } from 'react-scroll';
import { ScrollerService, useTranslation } from '@ibe/components';
import fallback from '../../../../Translations/generated/Checkout.de.json';
import Keys from '../../../../Translations/generated/Checkout.de.json.keys';

const scrollElementName = 'extended-booking-wrapper-';
interface CommonWrapperProps {
  title: string;
  description?: string;
  idx: number;
  children: JSX.Element;
  successNotes: string[];
  onNext?: () => void;
  onReset?: () => void;
  disableButton?: boolean;
  jumpToNextMissingSuccessfulStep?: boolean;
  onClose?: () => void;
}

// TODO:  CHECK FOR MEMORIZATION TO PREVENT CHILD COMPONENT TO RERENDER
const CommonWrapper = ({
  title,
  description,
  successNotes = [],
  idx,
  children,
  onNext,
  onReset,
  disableButton,
  onClose,
  jumpToNextMissingSuccessfulStep
}: CommonWrapperProps) => {
  const { nextStep, getData, goTo, closeAndMoveToNextMissingSuccesStep } = useCollapsibleWizard();
  const { t } = useTranslation('Checkout', fallback);
  const { isActive, isSuccess: isSuccessful } = getData(idx) || {};

  const moveNext = (index: number) => {
    nextStep(index, true);
    ScrollerService.scrollTo(`${scrollElementName}${index}`, { offset: -200 });
  };

  const defaultReset = (index: number) => {
    goTo(index);
    ScrollerService.scrollTo(`${scrollElementName}${index}`, { offset: -200 });
  };

  const handleClose = (id: number) => {
    onClose && onClose();
    closeAndMoveToNextMissingSuccesStep(id);
    ScrollerService.scrollTo(`${scrollElementName}${id}`, { offset: -200 });
  };

  const handleClick = (id: number) => {
    // call business logic of child component
    onNext && onNext();
    // move to next element
    if (jumpToNextMissingSuccessfulStep && isSuccessful) {
      closeAndMoveToNextMissingSuccesStep(id);
      ScrollerService.scrollTo(`${scrollElementName}${id}`, { offset: -200 });
      return;
    }
    moveNext(id);
  };

  const handleReset = (id: number) => {
    // call business logic of child component
    onReset && onReset();
    // open component by given id
    defaultReset(id);
  };

  return (
    <ScrollTo name={scrollElementName + idx}>
      <div className={`extended__wrapper ${isSuccessful ? 'extended__wrapper--success' : ''}`}>
        <div
          className={`extended__wrapper__left ${isActive ? 'extended__wrapper__left--active' : ''}
           `}
        >
          <div className="extended__wrapper__left__title">
            <span>{idx + 1}.</span>
            {title}
          </div>
          <div className="extended__wrapper__left__contain">
            {!isActive && !isSuccessful && description ? (
              <div
                className="extended__wrapper__left__description"
                dangerouslySetInnerHTML={{ __html: sanitize(description) }}
              />
            ) : null}
            {isActive ? (
              <>
                {description ? (
                  <div
                    className="extended__wrapper__left__description"
                    dangerouslySetInnerHTML={{ __html: sanitize(description) }}
                  />
                ) : null}
                <div>{children}</div>
                <div className="text-right  mt-4 mt-lg-3">
                  {isSuccessful && (
                    <Button
                      className="extended__wrapper--button mr-3"
                      onClick={() => handleClose(idx)}
                      color="secondary"
                    >
                      {t(Keys.close)}
                    </Button>
                  )}
                  <Button
                    className="extended__wrapper--button"
                    onClick={() => handleClick(idx)}
                    color="primary"
                    disabled={disableButton}
                  >
                    {t(Keys.apply)}
                  </Button>
                </div>
              </>
            ) : null}
          </div>
          {!isActive && isSuccessful ? (
            <>
              {successNotes.map((note, index) => (
                <div className="extended__wrapper__left__note" key={idx + '_note_' + index}>
                  <span>
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                  <div dangerouslySetInnerHTML={{ __html: sanitize(note) }} />
                </div>
              ))}
            </>
          ) : null}
        </div>
        {!isActive && isSuccessful ? (
          <div className="extended__wrapper__right">
            <Button
              className="extended__wrapper--button"
              onClick={() => handleReset(idx)}
              color="secondary"
            >
              {t(Keys.change)}
            </Button>
          </div>
        ) : null}
      </div>
    </ScrollTo>
  );
};

type Props = { idx: number; remount?: () => void };
interface RemountWrapperProps {
  Component: ComponentType<{ idx: number; remount?: () => void }>;
  props: Props;
}
export function RemountWrapper({ Component, props }: RemountWrapperProps): ReactElement {
  const [resetter, setResetter] = useState<number>(1);
  const key = useMemo<string>(() => createUniqueId(), [resetter]);
  const onRemount = (): void => setResetter(prev => prev + 1);

  return <Component {...props} key={key} remount={onRemount} />;
}

export default CommonWrapper;
