import React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';

interface Props {
  children: React.ReactNode;
  title?: string;
  withNav?: boolean;
}

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  background: transparent;
  padding: 0;
`;

const Center = styled.div`
  flex: 1 1 auto;
`;

const BlankPageLayout = observer((props: Props) => {
  return (
    <Layout>
      <Center>{props.children}</Center>
    </Layout>
  );
});
export default BlankPageLayout;
