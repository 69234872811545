import React, { useState } from 'react';
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption
} from 'reactstrap';
import { useTheme } from '@emotion/react';

export interface SliderItem {
  altText: string;
  caption: string;
  key?: number;
  src?: string;
  srcSet?: string;
}

interface Props {
  sliderItems: SliderItem[];
}

const SingleHotelCarouselComponent = (props: Props): JSX.Element => {
  const { sliderItems } = props;
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const theme = useTheme();
  const placeholderImg = theme.placeholderImages.hotel;
  const [src, setSrc] = useState('');

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === sliderItems.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? sliderItems.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex: number) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const handleError = () => {
    setSrc(placeholderImg);
  };

  const slides = sliderItems.map(item => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.srcSet}
      >
        <img
          src={!!item.srcSet ? undefined : placeholderImg}
          srcSet={!!item.srcSet ? item.srcSet : ''}
          alt={item.altText}
          onError={handleError}
        />
        {!!item.caption && <CarouselCaption captionText={item.caption} />}
      </CarouselItem>
    );
  });

  return (
    !!sliderItems && (
      <Carousel activeIndex={activeIndex} next={next} previous={previous} interval={false}>
        <CarouselIndicators
          items={sliderItems}
          activeIndex={activeIndex}
          onClickHandler={goToIndex}
        />
        {slides}

        {slides.length > 1 && (
          <>
            <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
            <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
          </>
        )}
      </Carousel>
    )
  );
};

export default SingleHotelCarouselComponent;
