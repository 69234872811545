import { observer } from 'mobx-react';
import React, { useContext, useState } from 'react';
import { CheckoutStep, CheckoutStepComponentProps } from '../../../Models/CheckoutStep';
import ProgressbarWrapper from './ProgressBarWrapper';
import StickySummary from './StickySummary';
import { Button, Col, Row } from 'reactstrap';
import { Overlay, OverlayContainer, useBookingService, useTranslation } from '@ibe/components';
import { SessionStorage } from '@ibe/services';
import { ApiImageContainer } from '@ibe/api';
import { SessionStorageType } from '../../../Components/Search';
import { productSearchSessionKey, productSearchSessionTempKey } from '../../../Config/config';
import { useHistory } from 'react-router-dom';
import { workflowContext } from '../../../contexts';
import ProductIBE from '../../Products/ProductIBE';
import HorizontalSpread from '../../../Layouts/Helper/HorizontalSpread';
import Keys from '../../../Translations/generated/Checkout.de.json.keys';
import fallback from '../../../Translations/generated/Checkout.de.json';
import { FlightAndAccommodationSummaryHeader } from './FlightAndAccommodationSummary';
import { HotelInformationType } from '../CheckoutStore';
import { whitelabelSeasonStorage } from '../../Util/utils';
import { useMount } from 'react-use';

const sessionStorage = new SessionStorage<SessionStorageType>(productSearchSessionKey);
const sessionStorageTemp = new SessionStorage<SessionStorageType>(productSearchSessionTempKey);

const TravelDate = observer(function TravelDates(props: CheckoutStepComponentProps) {
  const {
    store,
    rerouteUrl,
    navBarOffset,
    productIBEMode,
    packageCodes,
    thgConfig,
    promotionCode,
    agencyNumber,
    isWhitelabel
  } = props;
  const bs = useBookingService();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const workflow = useContext(workflowContext);
  const [error, setError] = useState<string | undefined>(undefined);
  const { t } = useTranslation('Checkout', fallback);

  const [earliestDate, setEarliestDate] = useState<string | undefined>(undefined);
  const [latestDate, setLatestDate] = useState<string | undefined>(undefined);

  const switchSessionData = (sessionToTemp: boolean) => {
    const copyFrom = sessionToTemp ? sessionStorage : sessionStorageTemp;
    const copyTo = sessionToTemp ? sessionStorageTemp : sessionStorage;
    const session = copyFrom.get();
    if (!!session) {
      copyTo.set(session);
      copyFrom.clear();
    }
  };

  const handleProgressbarClick = (step: CheckoutStep): void => {
    if (step.slug === 'packages') {
      switchSessionData(false);
    }
  };

  const next = async (): Promise<void> => {
    history.push(workflow.nextStepSlug || '');
  };

  const prev = (): void => {
    switchSessionData(false);

    if (!!rerouteUrl) {
      location.href = rerouteUrl;
    }
  };

  useMount(() => {
    const season = whitelabelSeasonStorage.get();

    if (season) {
      setEarliestDate(season.dateFrom + '');
      setLatestDate(season.dateTo + '');

      const image = season.image as ApiImageContainer | undefined;
      const icon = season.icon as ApiImageContainer | undefined;

      store.setHotelInformation({
        name: season.productName,
        country: (season?.seasonCountry as { countryName: string } | undefined)?.countryName as
          | string
          | undefined,
        additionalServices: (season?.additionalServices as unknown) as HotelInformationType['additionalServices'],
        image: image?.small || image?.extraSmall || image?.large,
        icon: icon?.small || icon?.extraSmall || icon?.large
      });
    }
  });

  const TopNav = () => (
    <HorizontalSpread className="checkout-nav">
      <Button color="secondary" className="mb-4" onClick={(): void => prev()}>
        {t(Keys.prev)}
      </Button>
    </HorizontalSpread>
  );

  const checkoutUrl = undefined;

  return (
    <OverlayContainer className="checkout-workflow">
      {!!rerouteUrl && <TopNav />}
      <ProgressbarWrapper
        onClick={handleProgressbarClick}
        store={store}
        rerouteUrl={rerouteUrl}
        isWhitelabel
      />
      <Row>
        <Col md={8} xs={12} className="traveler-sticky-main-content">
          {packageCodes && packageCodes.length > 0 && earliestDate && latestDate && (
            <>
              {store.hotelInformation && (
                <FlightAndAccommodationSummaryHeader store={store} isWhiteLabel={isWhitelabel} />
              )}
              <div className="whitelabel-wrapper">
                <ProductIBE
                  earliestPossibleDate={earliestDate}
                  latestPossibleDate={latestDate}
                  packageCodes={packageCodes}
                  checkoutUrl={checkoutUrl}
                  maintenanceMode={thgConfig?.maintenanceMode}
                  initialPromotionCode={promotionCode}
                  handlePackageSelection={next}
                  agencyNumber={agencyNumber}
                  isWhitelabel={isWhitelabel}
                />
              </div>
            </>
          )}
        </Col>
        <Col sm={12} md={4} className="traveler-sticky-summary-wrapper">
          {bs.booking && (
            <StickySummary
              loading={bs.isBusy || loading}
              booking={bs.booking}
              next={next}
              disabled={!!error}
              navBarOffset={navBarOffset}
              productIBEMode={productIBEMode}
              showPromotionCode
              hideMobileBar
              hideSummaryContent
            />
          )}
        </Col>
      </Row>
      {(bs.isBusy || loading) && <Overlay />}
    </OverlayContainer>
  );
});

export default TravelDate;
