import React, { useState } from 'react';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import fallback from '../../../Translations/generated/Checkout.de.json';
import Keys from '../../../Translations/generated/Checkout.de.json.keys';
import { MEDIAQUERY_DEFAULTS, useMediaQuery, useTranslation } from '@ibe/components';
import { Tooltip } from 'reactstrap';
import { sanitize } from '@ibe/services';
import { useMount } from 'react-use';

const PaymentPopUp = ({ text }: { text?: string }) => {
  const { t } = useTranslation('Checkout', fallback);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  const isTablet = useMediaQuery({ query: MEDIAQUERY_DEFAULTS.md, type: 'max' });

  const [isMounted, setIsMounted] = useState<boolean>(false);

  useMount(() => {
    setIsMounted(true);
  });

  return (
    <span id="info__box__container" className="payment__info__box">
      <FontAwesomeIcon id={'info__box'} icon={faInfoCircle} />
      {isMounted ? (
        <Tooltip
          placement={isTablet ? 'bottom-end' : 'bottom'}
          isOpen={tooltipOpen}
          target={'info__box'}
          toggle={toggle}
          popperClassName="tooltip-popper"
          innerClassName="tooltip-inner"
          container={'info__box__container'}
          onClick={toggle}
        >
          <div
            className="tooltip__info"
            dangerouslySetInnerHTML={{
              __html: sanitize(text || t(Keys.invoicePaymentInfo))
            }}
          />
        </Tooltip>
      ) : null}
    </span>
  );
};

export default PaymentPopUp;
