import React, { useState } from 'react';
import { Modal, ModalBody, ModalProps } from 'reactstrap';
import { observer } from 'mobx-react';
import { ApiBooking } from '@ibe/api';
import {
  AccommodationAdaptFooter,
  AccommodationAdaptStore,
  AccommodationRoomAdapt,
  useApi,
  useBookingService,
  useTranslation
} from '@ibe/components';

import fallback from '../../../Translations/generated/Checkout.de.json';
import Keys from '../../../Translations/generated/Checkout.de.json.keys';
import CheckoutStore from '../CheckoutStore';

export interface AccommodationAdaptOverlayProps {
  isModalOpen: boolean;
  openModal: (a: boolean) => void;
  booking: ApiBooking;
  store: CheckoutStore;
  prev: () => void;
}

const AccommodationAdaptOverlay = observer(function AccommodationAdaptOverlay(
  props: AccommodationAdaptOverlayProps
): JSX.Element {
  const [showError, setShowError] = useState(false);
  const { isModalOpen, openModal, booking, store, prev } = props;
  const { t } = useTranslation('Checkout', fallback);
  const bs = useBookingService();

  const onSuccess = (_: string) => {
    openModal(false);
  };

  if (!booking) return <></>;

  const api = useApi();
  const Store = new AccommodationRoomAdaptOverlayStore(api, bs);

  const hideErrorAndModal = () => {
    setShowError(false);
    openModal(false);
    prev();
  };

  /**
   * THE IDEA WERE TO MAKE AccommodationRoomAdapt REUSABLE FOR THE CASE
   * WE HAVE MULTIPLE HOTEL, PACIFIC DONT SUPPORT MULTIPLE NOW, I KEEP THE IDEA,
   * SO WE CAN CHANGE LESS IF WE HAVE TO WORK ON THIS COMPONENT AGAIN
   */

  return (
    <TrendToursCustomModal
      modalClassName={'accommodation-adapt-overlay accommodation-adapt-overlay__ibe_flights'}
      backdropClassName="accommodation-adapt-overlay-backdrop"
      centered
      isOpen={isModalOpen}
      size="xl"
      container={document.getElementById('iso') || undefined}
    >
      <ModalBody>
        <>
          {booking && !showError ? (
            <AccommodationRoomAdapt
              onClose={() => openModal(false)}
              onSuccessPerHotel={onSuccess}
              Store={Store}
              onInitializationError={() => setShowError(true)}
              translationNamespace={'accommodation-adapt-flight'}
              footerPerHotel={({ onClose, applyModificationPerHotel, error }) => {
                return (
                  <>
                    {error ? (
                      <div className="d-flex justify-content-end">
                        <p className="accommodation-adapt-overlay-error-p">
                          {t(Keys.getPaxRoomAssignmentsErrorSearch)}
                        </p>
                      </div>
                    ) : null}
                    <div className="iso__accommodation-adapt-footer-spacing--custom d-block d-md-none" />
                    <div className="iso__modal-footer--sticky">
                      <AccommodationAdaptFooter
                        close={onClose}
                        applyModification={applyModificationPerHotel}
                        isApplyBtnNotActive={!!error}
                        translationNamespace={'accommodation-adapt-flight'}
                      />
                    </div>
                  </>
                );
              }}
            />
          ) : null}
          {showError && (
            <>
              <div className="accommodation-adapt-overlay-error">
                <p className="accommodation-adapt-overlay-error-p">
                  {t(Keys.getPaxRoomAssignmentsErrorSearch)}
                </p>
              </div>
              <div className="iso__accommodation-adapt-footer-spacing--custom d-block d-md-none" />
              <div className="iso__modal-footer--sticky">
                <AccommodationAdaptFooter
                  close={hideErrorAndModal}
                  applyModification={() => ''}
                  isApplyBtnNotActive={true}
                  onlyConfirm
                />
              </div>
            </>
          )}
        </>
      </ModalBody>
    </TrendToursCustomModal>
  );
});

export default AccommodationAdaptOverlay;

/**
 * WHY THIS  ?
 * WELL, The way the backend responds may be change in the future,
 * to prevent many manipulation and changes
 */

class AccommodationRoomAdaptOverlayStore extends AccommodationAdaptStore {
  getPaxRoomAssignmentsAllHotel = async (): Promise<void> => {
    // for THG we have just on Hotel
    const firstKey = Object.keys(this.allHotels)[0];
    await this._getPaxRoomAssignmentsPerHotel(firstKey);
  };

  getPaxRoomAssignmentsPerHotel = async (hotelId: string): Promise<void> => {
    await this._getPaxRoomAssignmentsPerHotel(hotelId);
  };

  updateBookingAllHotels = async (): Promise<void> => {
    // BACKEND DOES'NT SUPPORT IT NOW
    await this._updateBookingAllHotels();
  };

  updateBookingPerHotel = async (hotelId: string): Promise<void> => {
    await this._updateBookingPerHotel(hotelId);
  };

  initNumberOfRoomLoaderMaxMin = async (): Promise<void> => {
    this._initNumberOfRoomLoaderMaxMin();
  };

  searchInitialData = async (): Promise<void> => {
    await this._searchInitialData();
  };
}

const CUSTOMCLASS = 'iso__custom__modal-open';
export const TrendToursCustomModal = (props: ModalProps) => {
  const addCustomClassToBody = () => {
    const bodyElement = document.getElementsByTagName('body')?.[0];
    if (bodyElement) bodyElement.classList.add(CUSTOMCLASS);
  };

  const removeCustomClassToBody = () => {
    const bodyElement = document.getElementsByTagName('body')?.[0];
    if (bodyElement) bodyElement.classList.remove(CUSTOMCLASS);
  };

  return (
    <Modal
      container={document.getElementById('iso') || undefined}
      onOpened={addCustomClassToBody}
      onClosed={removeCustomClassToBody}
      {...props}
    >
      {props.children}
    </Modal>
  );
};
