import { SessionStorage } from '@ibe/services';

export const unEscape = (value: string) => {
  value = value.replace(/&lt;/g, '<');
  value = value.replace(/&gt;/g, '>');
  value = value.replace(/&quot;/g, '"');
  value = value.replace(/&#39;/g, "'");
  value = value.replace(/&amp;/g, '&');
  return value;
};

export const stepSessionStorage = new SessionStorage<string>('thg-step-session-storage');
