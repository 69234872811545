import { ApiComponent, ApiHotel, ApiHotelRoom } from '@ibe/api';

import { useMemo, useState, useEffect } from 'react';
import { useExtendedBooking, ExtendedApiTraveler } from '../../ContextAndProvider';
import { createUniqueId } from '@ibe/services';
import { getSortedHotelRooms } from './RoomSelection';

export interface DropDownItem {
  label: string;
  value: string;
}

export const useRoomSelection = (
  component: ApiComponent,
  hotel?: ApiHotel,
  isOptional?: boolean,
  assignTravelersByDefault?: boolean
) => {
  const { setSelectedItems, selectedItems: globalSelectedItems } = useExtendedBooking();
  const { travelers, roomsMain, roomsOptional } = globalSelectedItems;
  const numberOfTravelers = travelers?.length || 2;

  const selectableRoomRange = useMemo(() => {
    return getSelectableRoomNumbersCombinations(numberOfTravelers, component, hotel);
  }, [component, numberOfTravelers, hotel]);

  const [minNumberRoom, setMinNumberRoom] = useState<number | undefined>(undefined);
  const [maxNumberRoom, setMaxNumberRoom] = useState<number | undefined>(undefined);

  const [numberOfRooms, setNumberOfRooms] = useState<number | undefined>(undefined);

  const preSelectedRooms = isOptional ? roomsOptional : roomsMain;
  const [selectedRooms, setSelectedRooms] = useState<
    { assignedRoomNumber: number; room: ApiHotelRoom; travelers?: ExtendedApiTraveler[] }[]
  >([]);

  const availableRooms = useMemo(() => {
    const allRooms = getAllAvailableRooms(component.selectableItems as ApiHotel[], hotel);
    const displayableRooms: ApiHotelRoom[] = [];
    const roomOptions = selectableRoomRange.find(a => a.roomNumber === numberOfRooms)?.roomOptions;
    for (let index = 0; index < allRooms.length; index++) {
      if (roomOptions?.[allRooms[index].minOccupancy]) displayableRooms.push(allRooms[index]);
    }
    return displayableRooms;
  }, [hotel, component, numberOfTravelers, numberOfRooms, selectableRoomRange]);
  useEffect(() => {
    const selectedItems = component.selectedItems[0] as ApiHotel;
    const minRoomNumber = selectableRoomRange?.[0]?.roomNumber;

    const preSelectedNumberOfRooms =
      (isOptional ? roomsOptional?.length : roomsMain?.length) ||
      selectedItems?.rooms?.length ||
      selectableRoomRange?.[0]?.roomNumber;

    if (!assignTravelersByDefault) {
      setSelectedRooms(
        (preSelectedRooms || [])?.flatMap(({ room, ...res }, index) => ({
          assignedRoomNumber: index,
          room,
          travelers: res.travelers
        }))
      );
    }

    setMinNumberRoom(selectableRoomRange?.[0]?.roomNumber);
    setMaxNumberRoom(
      selectableRoomRange?.[selectableRoomRange.length - 1]?.roomNumber || numberOfTravelers
    );
    setNumberOfRooms(
      preSelectedNumberOfRooms > minRoomNumber ? preSelectedNumberOfRooms : minRoomNumber
    );
  }, [
    travelers,
    preSelectedRooms,
    hotel,
    selectableRoomRange,
    roomsOptional,
    roomsMain,
    isOptional
  ]);

  useEffect(() => {
    // THGIBE-1393 preset travelers to a double room by default if there are 2 travelers only
    if (assignTravelersByDefault) {
      if (
        (!preSelectedRooms ||
          (preSelectedRooms?.length && numberOfRooms !== preSelectedRooms.length)) &&
        travelers?.length === 2 &&
        availableRooms?.length === 1 &&
        availableRooms[0]?.minOccupancy === 2
      ) {
        setSelectedRooms([
          {
            assignedRoomNumber: 0,
            room: availableRooms[0],
            travelers: travelers?.flatMap((tr, idx) => {
              return { ...tr, position: idx };
            })
          }
        ]);
      } else {
        if (preSelectedRooms?.length && numberOfRooms === preSelectedRooms.length) {
          setSelectedRooms(
            preSelectedRooms.flatMap(({ room, ...res }, index) => ({
              assignedRoomNumber: index,
              room,
              travelers: res.travelers
            }))
          );
        } else {
          setSelectedRooms([]);
        }
      }
    }
  }, [availableRooms, numberOfRooms]);

  const updateRoomRateSelection = (a: ApiHotelRoom, roomNumber: number) => {
    const updatedSelectedRooms = selectedRooms.filter(
      selectedRoom => selectedRoom.assignedRoomNumber !== roomNumber
    );
    updatedSelectedRooms.push({ assignedRoomNumber: roomNumber, room: a, travelers: [] });
    setSelectedRooms(updatedSelectedRooms);
  };

  const cheapestHotelRoom = useMemo(() => {
    const sortedRooms = getSortedHotelRooms(
      getAllAvailableRooms(component.selectableItems as ApiHotel[], hotel)
    );
    return sortedRooms[0];
  }, [hotel, component, numberOfTravelers, numberOfRooms]);

  const updateNumberOfRooms = (newRoomCount: number, add: boolean) => {
    setSelectedRooms([]);
    if (selectableRoomRange.find(room => room.roomNumber === newRoomCount && room.isFeasible)) {
      return setNumberOfRooms(newRoomCount);
    }
    if (add) {
      const closestBiggerRoomNumber = selectableRoomRange.filter(
        number => number.roomNumber > newRoomCount
      );
      if (closestBiggerRoomNumber[0]) {
        return setNumberOfRooms(closestBiggerRoomNumber[0].roomNumber);
      }
      return;
    }
    const closestSmallerRoomNumber = selectableRoomRange.filter(
      number => number.roomNumber > newRoomCount
    );
    if (closestSmallerRoomNumber[closestSmallerRoomNumber.length - 1]) {
      return setNumberOfRooms(
        closestSmallerRoomNumber[closestSmallerRoomNumber.length - 1].roomNumber
      );
    }
  };

  const disableButton: boolean = useMemo(() => {
    const allAlreadySelectedTravelers = selectedRooms.reduce((acc, room) => {
      if (room.travelers && room.travelers.length > 0) {
        const travelerIds = room.travelers.map(traveler => traveler.id);
        return acc.concat(travelerIds);
      }
      return acc;
    }, [] as string[]);
    const occupancy = selectedRooms.reduce((total, currentValue) => {
      total = total + currentValue.room.minOccupancy;
      return total;
    }, 0);

    return (
      occupancy !== allAlreadySelectedTravelers?.length ||
      occupancy < 0 ||
      allAlreadySelectedTravelers.length < 0 ||
      numberOfRooms !== selectedRooms.length ||
      allAlreadySelectedTravelers?.length !== travelers?.length
    );
  }, [selectedRooms, numberOfRooms, travelers]);

  const successNotes: string[] = useMemo(() => {
    return (isOptional ? roomsOptional || [] : roomsMain || []).reduce((notes, selectedRoom) => {
      notes.push(selectedRoom?.room?.description);
      return notes;
    }, [] as string[]);
  }, [isOptional, roomsMain, roomsOptional]);

  const onNext = () => {
    const selection = selectedRooms
      .sort((a, b) => a.assignedRoomNumber - b.assignedRoomNumber)
      .reduce((acc, selectedRoom) => {
        acc.push({
          room: selectedRoom.room,
          travelers: selectedRoom.travelers as ExtendedApiTraveler[]
        });
        return acc;
      }, [] as Exclude<typeof roomsMain, undefined>);

    if (isOptional) {
      setSelectedItems({
        duration: globalSelectedItems.duration,
        travelers: globalSelectedItems.travelers,
        hotelMain: globalSelectedItems.hotelMain,
        roomsMain: globalSelectedItems.roomsMain,
        cateringMain: globalSelectedItems.cateringMain,
        hotelOptional: globalSelectedItems.hotelOptional,
        roomsOptional: selection
      });
    } else {
      setSelectedItems({
        duration: globalSelectedItems.duration,
        travelers: globalSelectedItems.travelers,
        hotelMain: globalSelectedItems.hotelMain,
        roomsMain: selection
      });
    }
  };

  const updateTravelersToRoom = (
    travelerID: string,
    position: number,
    roomNumber: number,
    add?: boolean
  ) => {
    const updatedSelectedRooms = [...selectedRooms];
    const findTraveler = (travelers || []).find(trav => trav.id === travelerID);
    const findIndex = updatedSelectedRooms.findIndex(
      updatedSelectedRoom => updatedSelectedRoom.assignedRoomNumber === roomNumber
    );
    if (findIndex < 0) return;

    if (!updatedSelectedRooms[findIndex].travelers) updatedSelectedRooms[findIndex].travelers = [];

    if (add) {
      if (findTraveler) {
        updatedSelectedRooms[findIndex].travelers = updatedSelectedRooms[findIndex].travelers
          ?.filter(trav => trav.position !== position)
          ?.concat({ ...findTraveler, position } as ExtendedApiTraveler);
      }
    } else {
      updatedSelectedRooms[findIndex].travelers = updatedSelectedRooms[findIndex].travelers?.filter(
        trav => trav.id !== travelerID && trav.position !== position
      );
    }
    setSelectedRooms(updatedSelectedRooms);
  };

  const dropDownOptions: DropDownItem[] = useMemo(() => {
    const allAlreadySelectedTravelers = selectedRooms.reduce((acc, room) => {
      if (room.travelers && room.travelers.length > 0) {
        const travelerIds = room.travelers.map(traveler => traveler.id);
        return acc.concat(travelerIds);
      }
      return acc;
    }, [] as string[]);

    return (travelers || [])
      .filter(traveler => !allAlreadySelectedTravelers.includes(traveler.id))
      .flatMap(traveler => {
        return {
          label: traveler.firstName + ' ' + traveler.lastName,
          value: traveler.id
        };
      });
  }, [selectedRooms, travelers]);

  const roomKeys = useMemo(() => {
    if (!numberOfRooms) return [];
    return Array.from({ length: numberOfRooms }, () => createUniqueId());
  }, [numberOfRooms, hotel]);

  return {
    availableRooms,
    numberOfRooms,
    cheapestHotelRoom,
    updateNumberOfRooms,
    setNumberOfRooms,
    updateRoomRateSelection,
    updateTravelersToRoom,
    selectedRooms,
    minNumberRoom,
    maxNumberRoom,
    successNotes,
    disableButton,
    onNext,
    dropDownOptions,
    roomKeys
  };
};

interface RoomOptions {
  [key: number]: boolean;
}

export const getAllAvailableRooms = (selectableItems: ApiHotel[], hotel?: ApiHotel) => {
  const foundHotel = (selectableItems as ApiHotel[]).find(
    selectableItem => selectableItem.code === hotel?.code
  );
  return foundHotel?.rooms || [];
};

export const getSelectableRoomNumbersCombinations = (
  numberOfTravelers: number,
  component: ApiComponent,
  hotel?: ApiHotel
) => {
  const availableRooms = getAllAvailableRooms(component.selectableItems as ApiHotel[], hotel);

  return Array.from(Array(numberOfTravelers + 1).keys())
    .flatMap(roomNbr => {
      const pos = checkFeasibility(numberOfTravelers, roomNbr, availableRooms);
      const roomOptions: RoomOptions = {};
      pos.combinations.map(comb => {
        comb.map(com => {
          roomOptions[com.minOccupancy] = true;
        });
      });
      return {
        isFeasible: pos.feasible,
        roomNumber: roomNbr,
        roomOptions
      };
    })
    .filter(a => a.isFeasible);
};

interface ExplorationState {
  guestCount: number;
  desiredRooms: number;
  combination: ApiHotelRoom[];
  index: number;
}

function checkFeasibility(
  defaultGuestCount: number,
  defaultDesiredRooms: number,
  availableHotelRooms: ApiHotelRoom[]
): { feasible: boolean; combinations: ApiHotelRoom[][] } {
  const stack: ExplorationState[] = [
    { guestCount: defaultGuestCount, desiredRooms: defaultDesiredRooms, combination: [], index: 0 }
  ];

  const validCombinations: ApiHotelRoom[][] = [];
  while (stack.length > 0) {
    const state = stack.pop();
    if (!state) break;
    const { guestCount, desiredRooms, combination, index } = state;
    if (guestCount === 0 && desiredRooms === 0) {
      validCombinations.push(combination);
      continue;
    }

    if (index >= availableHotelRooms.length) continue;

    const room = availableHotelRooms[index];

    const availability =
      room.availability > defaultGuestCount * 2 ? defaultGuestCount * 2 : room.availability;

    stack.push({ guestCount, desiredRooms, combination: [...combination], index: index + 1 });

    for (let i = 1; i <= availability; ++i) {
      if (guestCount >= room.minOccupancy * i && desiredRooms >= i) {
        const newCombination = [
          ...combination,
          ...Array(i).fill({
            minOccupancy: room.minOccupancy,
            availability: room.availability
          })
        ];
        stack.push({
          guestCount: guestCount - room.minOccupancy * i,
          desiredRooms: desiredRooms - i,
          combination: newCombination,
          index: index + 1
        });
      }
    }
  }

  return {
    feasible: validCombinations.length > 0,
    combinations: validCombinations
  };
}
