import { useCallback, useEffect, useRef } from 'react';
import debounce from 'lodash-es/debounce';

export const footerSelector = '.footer';
export const navbarMobileSelector = '.top-nav-mobile';
export const navbarSelector = '.top-nav';
export const navbarBottomSelector = '.bottom-nav';
const useFixedElementScroll = () => {
  const stickyBoxRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const navbarMobile = document.querySelector(navbarMobileSelector) as HTMLDivElement;
  const navbar = document.querySelector(navbarSelector) as HTMLDivElement;
  const navbarBottom = document.querySelector(navbarBottomSelector) as HTMLDivElement;
  const footer = document.querySelector(footerSelector) as HTMLDivElement;

  let retryTimer: ReturnType<typeof setTimeout> | null = null;
  const retryScrollTimer: ReturnType<typeof setTimeout> | null = null;

  const onResize = useCallback((): void => {
    if (retryTimer) clearTimeout(retryTimer);
    if (
      containerRef.current &&
      stickyBoxRef.current &&
      containerRef?.current?.getBoundingClientRect().width > 0
    ) {
      stickyBoxRef.current.style.width = `${containerRef?.current?.clientWidth}px`;
    } else {
      retryTimer = setTimeout(() => {
        onResize();
      }, 150);
    }
    return;
  }, [containerRef, stickyBoxRef]);

  const getPosition = (element: HTMLDivElement | null) => {
    let top = 0;

    while (element) {
      top += element.offsetTop || 0;
      element = element.offsetParent as HTMLDivElement;
    }

    return top;
  };

  const handleScroll = useCallback(() => {
    const mainContent = document.querySelector('.checkout_main_container') as HTMLDivElement;
    const stickyBox = stickyBoxRef.current;
    const navbarHeight =
      (navbarMobile?.offsetHeight || 0) +
      (navbar?.offsetHeight || 0) +
      (navbarBottom?.offsetHeight || 0);
    if (
      stickyBox &&
      stickyBox?.offsetHeight > 0 &&
      footer &&
      footer.getBoundingClientRect().top > 0 &&
      mainContent &&
      navbar &&
      navbarHeight > 0
    ) {
      const stickyBoxHeight = stickyBox.offsetHeight;
      const mainContentHeight = mainContent.offsetHeight;
      const viewportHeight = window.innerHeight;
      const footerTop = footer.getBoundingClientRect().top;
      const stickyBoxTop = stickyBox.getBoundingClientRect().top;
      const scrollPastStickyBoxFixed =
        window.scrollY > stickyBoxHeight + getPosition(mainContent) - viewportHeight;
      const scrollPastStickyBoxRelative = window.scrollY > getPosition(mainContent);

      const footerEntersView = footerTop < viewportHeight;

      if (stickyBoxHeight > mainContentHeight) {
        stickyBox.style.position = 'relative';
        stickyBox.style.top = 'initial';
        stickyBox.style.bottom = 'initial';
      } else if (stickyBoxHeight < viewportHeight) {
        if (footerTop - stickyBoxHeight < navbarHeight + 10) {
          stickyBox.style.position = 'absolute';
          stickyBox.style.bottom = '0';
          stickyBox.style.top = 'initial';
        } else if (stickyBoxTop > navbarHeight + 10) {
          stickyBox.style.position = 'sticky';
          stickyBox.style.top = `${navbarHeight + 10}px`;
          stickyBox.style.bottom = 'initial';
        }
      } else {
        if (stickyBox && footer) {
          if (!scrollPastStickyBoxRelative) {
            stickyBox.style.position = 'relative';
            stickyBox.style.bottom = 'initial';
            stickyBox.style.top = 'initial';
          } else if (scrollPastStickyBoxFixed && !footerEntersView) {
            stickyBox.style.position = 'fixed';
            stickyBox.style.bottom = '0';
            stickyBox.style.top = 'initial';
          } else if (footerEntersView) {
            stickyBox.style.position = 'absolute';
            stickyBox.style.bottom = '0';
            stickyBox.style.top = 'initial';
          }
        }
      }
    }
  }, [containerRef, stickyBoxRef, footer, navbar]);

  useEffect((): (() => void) => {
    const debouncedHandleResize = debounce(onResize, 150);

    const debouncedHandleScroll = debounce(handleScroll, 15);

    if (containerRef.current && stickyBoxRef.current) {
      window.removeEventListener('resize', debouncedHandleResize);
      window.addEventListener('resize', debouncedHandleResize);
      debouncedHandleResize();
      handleScroll();
      document.removeEventListener('scroll', debouncedHandleScroll);
      document.addEventListener('scroll', debouncedHandleScroll);
    }

    return (): void => {
      window.removeEventListener('resize', debouncedHandleResize);
      document.removeEventListener('scroll', debouncedHandleScroll);
      if (retryTimer) clearTimeout(retryTimer);
      if (retryScrollTimer) clearTimeout(retryScrollTimer);
    };
  }, [containerRef, stickyBoxRef, onResize, handleScroll, footer, navbar]);

  return [stickyBoxRef, containerRef];
};

export default useFixedElementScroll;
