import {
  ApiAddItemRequest,
  ApiComponent,
  ApiComponentType,
  ApiExtraService,
  ApiExtraUnit,
  ApiImageContainer,
  ApiInsurance,
  ApiInsuranceUnit,
  ApiItem,
  ApiItemType
} from '@ibe/api';
import dayjs from 'dayjs';

export interface ExtendedApiItem extends ApiItem {
  code: string;
}

export type ComponentWrapper<T extends ApiComponent = ApiComponent> = {
  component: T;
  relatedComponent?: T;
  operation: 'SELECT' | 'DESELECT' | 'NONE';
  componentIndex: number;
  itemLevel: ApiItem;
  relatedComponentItemLevel?: ApiItem;
  itemIndex: number;
  unitLevel?: ApiExtraUnit;
  unitIndex?: number;
};

export type OnChangeOperation = 'ADD' | 'REMOVE' | 'REMAINS_ADDED' | 'REMAINS_REMOVED' | '';
export type OnChangeTravelers = Record<number, OnChangeOperation>;

export type MetaInfoCmsContent = Partial<{
  descriptionPopup: string;
  description: string;
  design: 'extras' | 'attractions';
  displayName: string;
  imagePopup: ApiImageContainer;
  imageTile: ApiImageContainer;
  more: boolean;
  prioritySelection: number;
  hidePopupImage: boolean;
}>;

export const getSelectedMainComponent = (components: Array<ApiComponent>): ApiComponent => {
  const mainComponents = components.filter(
    component => component.componentType === ApiComponentType.MAIN
  );
  return mainComponents.find(component => component.selectedItems.length > 0) || mainComponents[0];
};

export const getOptionalOffers = (components: Array<ApiComponent>): Array<ComponentWrapper> => {
  return components
    .map(
      (component, idx: number) =>
        ({ component: component, operation: 'NONE', componentIndex: idx } as ComponentWrapper)
    )
    .filter(component => component.component.componentType === ApiComponentType.OPTIONAL);
};

export const isExtendedItem = (item: ApiItem): item is ExtendedApiItem => {
  return item.hasOwnProperty('code');
};

export const isNumber = (value?: number): value is number => {
  return value !== undefined && value !== null;
};

const isInsurance = (item: ApiInsurance | ApiExtraService | ApiItem): item is ApiInsurance => {
  return item.hasOwnProperty('units');
};

const isExtra = (item: ApiInsurance | ApiExtraService | ApiItem): item is ApiExtraService => {
  return item.hasOwnProperty('extraUnits');
};

export const getUnits = (
  item: ApiInsurance | ApiExtraService | ApiItem
): ApiInsuranceUnit[] | ApiExtraUnit[] => {
  return isInsurance(item) ? item.units : isExtra(item) ? item.extraUnits : [];
};

export const changeSelectedFlight = (
  matchCode: string,
  components: Array<ApiComponent>,
  operation: ComponentWrapper['operation'],
  componentId: string
): Array<ApiComponent> => {
  const newComponents = [...components];
  let matchingFlightIndex = -1;
  let matchingSelectableItemIndex = -1;
  let matchingFlightComponent = newComponents.find((component: ApiComponent, idx: number) => {
    const itemIndex = component.selectableItems.findIndex(
      item => item.matchCode == matchCode && component.id !== componentId
    );
    if (itemIndex >= 0) {
      matchingFlightIndex = idx;
      matchingSelectableItemIndex = itemIndex;
    }
    return itemIndex >= 0;
  });
  if (!!matchingFlightComponent) {
    if (operation === 'DESELECT') {
      const matchlessSelectable = matchingFlightComponent.selectableItems.find(
        item => !item.matchCode
      );
      matchingFlightComponent = {
        ...matchingFlightComponent,
        selectedItems: !!matchlessSelectable ? [matchlessSelectable] : []
      };
    } else if (operation === 'SELECT' && matchingSelectableItemIndex >= 0) {
      matchingFlightComponent = {
        ...matchingFlightComponent,
        selectedItems: [matchingFlightComponent.selectableItems[matchingSelectableItemIndex]]
      };
    }
    newComponents.splice(matchingFlightIndex, 1, matchingFlightComponent);
  }
  return newComponents;
};

export const priceMultiplier = (
  extra: ComponentWrapper,
  itemsBookingOptions: { [key: string]: ApiAddItemRequest }
): number => {
  if (
    extra.unitLevel &&
    extra.unitLevel.unitRates &&
    extra.unitLevel.unitRates[0] &&
    itemsBookingOptions &&
    itemsBookingOptions[extra.unitLevel.unitRates[0].id] &&
    itemsBookingOptions[extra.unitLevel.unitRates[0].id].travelers
  ) {
    return itemsBookingOptions[extra.unitLevel.unitRates[0].id].travelers.length;
  }
  if (extra.itemLevel && itemsBookingOptions[extra.itemLevel.id]) {
    return itemsBookingOptions[extra.itemLevel.id].travelers.length;
  }
  return 0;
};

export const getCurrentSelectionState = (
  itemsBookingOptions: { [key: string]: ApiAddItemRequest },
  extra: ComponentWrapper
) => {
  if (
    extra.unitLevel &&
    extra.unitLevel.unitRates &&
    extra.unitLevel.unitRates[0] &&
    itemsBookingOptions[extra.unitLevel.unitRates[0].id] &&
    itemsBookingOptions[extra.unitLevel.unitRates[0].id].travelers
  ) {
    return itemsBookingOptions[extra.unitLevel.unitRates[0].id].travelers.map(t => t.id);
  }
  if (extra.itemLevel && itemsBookingOptions[extra.itemLevel.id]) {
    return itemsBookingOptions[extra.itemLevel.id].travelers.map(t => t.id);
  }
  return [];
};

export const getExtras = (components: Array<ApiComponent>): ComponentWrapper[] =>
  getOptionalOffers(components)
    .filter(
      component =>
        component.component.itemType === ApiItemType.EXTRA ||
        // component.component.itemType === ApiItemType.INSURANCE ||  --> not needed for now
        (component.component.itemType === ApiItemType.FLIGHT &&
          component.component.componentType === ApiComponentType.OPTIONAL)
    )
    .reduce((total: ComponentWrapper[], current: ComponentWrapper) => {
      if (
        current.component.itemType === ApiItemType.EXTRA ||
        current.component.itemType === ApiItemType.INSURANCE
      ) {
        return [
          ...total,
          ...current.component.selectableItems.reduce(
            (innerTotal: ComponentWrapper[], innerCurrent: ApiItem, innerIdx: number) => {
              return [
                ...innerTotal,
                ...getUnits(innerCurrent).map(
                  (unit: ApiExtraUnit | ApiInsuranceUnit, unitIndex: number) => {
                    return {
                      ...current,
                      itemLevel: innerCurrent,
                      itemIndex: innerIdx,
                      unitLevel: unit,
                      unitIndex
                    } as ComponentWrapper;
                  }
                )
              ];
            },
            [] as ComponentWrapper[]
          )
        ];
      } else {
        // if total contains opt flight component -> da hinzufügen sonst neu
        const railAndFlyOutbound = total.find(
          wrapper =>
            wrapper.component.componentType === ApiComponentType.OPTIONAL &&
            wrapper.component.itemType === ApiItemType.FLIGHT
        );
        if (railAndFlyOutbound) {
          railAndFlyOutbound.relatedComponent = current.component;
          railAndFlyOutbound.relatedComponentItemLevel = current.component.selectableItems[0];
          return [...total];
        } else {
          return [
            ...total,
            { ...current, itemLevel: current.component.selectableItems[0], itemIndex: 0 }
          ];
        }
      }
    }, [] as ComponentWrapper[]);

export const isRailAndFly = (extraComponent: ApiComponent): boolean => {
  return (
    extraComponent.itemType === ApiItemType.FLIGHT &&
    extraComponent.componentType === ApiComponentType.OPTIONAL
  );
};

export const isRailAndFlyReturn = (
  extraComponent: ApiComponent,
  travelStartDate: string
): boolean => {
  return (
    isRailAndFly(extraComponent) &&
    dayjs(extraComponent.startDate).diff(dayjs(travelStartDate), 'day') > 0
  );
};
