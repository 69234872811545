import React, { Dispatch, useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { Error, useBookingService, useConfig, useTranslation } from '@ibe/components';
import fallback from '../../../../Translations/generated/Checkout.de.json';
import Keys from '../../../../Translations/generated/Checkout.de.json.keys';
import InsuranceDisplay from './InsuranceDisplay';
import type { Feature, InsuranceModel } from './InsuranceDisplay';
import useThgConfig from '../../../../Hooks/useThgConfig';
import {
  ApiComponentType,
  ApiInsurance,
  ApiInsuranceUnit,
  ApiItemType,
  ApiMetaInfo
} from '@ibe/api';
import { sanitize } from '@ibe/services';

export type ApiInsuranceUnitExtended = ApiInsuranceUnit & {
  metaInfo?: ApiMetaInfo & {
    contentByMetaType?: {
      insurance?: {
        content?: {
          displayName?: string;
          isNoInsurance?: boolean;
          isRecommendation?: boolean;
          services?: ApiService[];
        };
      };
    };
  };
};
type ApiService = {
  serviceDescription?: string;
  serviceHoverText?: string;
};

const InsuranceContainer = ({
  bookedClicked,
  clickAction
}: {
  bookedClicked: boolean;
  clickAction: Dispatch<React.SetStateAction<boolean>>;
}): JSX.Element => {
  const { t } = useTranslation('Checkout', fallback);
  const bs = useBookingService();
  const [selectedInsuranceModel, setSelectedInsuranceModel] = useState<InsuranceModel>();

  const config = useConfig();
  const thgConfig = useThgConfig();
  const baseUrl = thgConfig.baseUrl || config.translationsUrl;

  const packageCart = bs.packageCart;

  const [noInsurance, setNoInsurance] = useState<InsuranceModel | undefined>(undefined);
  const [withDeductibleInsurance, setWithDeductibleInsurance] = useState<
    InsuranceModel | undefined
  >(undefined);
  const [withoutDeductibleInsurance, setWithoutDeductibleInsurance] = useState<
    InsuranceModel | undefined
  >(undefined);

  const [withDeductibleFeatures, setWithDeductibleFeatures] = useState<Feature[] | undefined>(
    undefined
  );
  const [withoutDeductibleFeatures, setWithoutDeductibleFeatures] = useState<Feature[] | undefined>(
    undefined
  );
  const [noInsuranceFeatures, setNoInsuranceFeatures] = useState<Feature[] | undefined>(undefined);

  const generateFeatures = (insuranceUnit: ApiInsuranceUnit): Feature[] => {
    const features: Feature[] = [];
    const extendedUnit: ApiInsuranceUnitExtended = insuranceUnit as ApiInsuranceUnitExtended;
    const extendedContent = extendedUnit.metaInfo?.contentByMetaType?.insurance?.content;
    if (extendedContent && extendedContent.services) {
      if (!extendedContent.isNoInsurance) {
        extendedContent.services.forEach((service, index) =>
          features.push({
            id: 'wd_feat_' + insuranceUnit.cmsCode + '_' + (index + 1),
            text: service.serviceDescription ? service.serviceDescription : '',
            info: service.serviceHoverText ? service.serviceHoverText : '',
            noInsurance: !!extendedContent.isNoInsurance
          })
        );
      } else {
        features.push({
          id: 'wd_feat_' + insuranceUnit.cmsCode,
          text:
            extendedContent.services && extendedContent.services[0].serviceDescription
              ? extendedContent.services[0].serviceDescription
              : t(Keys.insuranceNoneWanted),
          info: '',
          noInsurance: true
        });
      }
    }
    return features;
  };

  useEffect(() => {
    if (packageCart && packageCart.packageModel.packageDetails) {
      const insuranceComp = packageCart.packageModel.packageDetails[0].components.find(
        c => c.itemType === ApiItemType.INSURANCE && c.componentType === ApiComponentType.OPTIONAL
      );

      if (insuranceComp) {
        const apiItems = insuranceComp.selectableItems as ApiInsurance[];

        let insWDeduct: InsuranceModel = {} as InsuranceModel;
        let insWODeduct: InsuranceModel = {} as InsuranceModel;
        let insNoIns: InsuranceModel = {} as InsuranceModel;

        apiItems.forEach(i => {
          i.units.forEach(u => {
            if (u.metaInfo?.contentByMetaType?.insurance) {
              const extendedApi = u as ApiInsuranceUnitExtended;
              const content = extendedApi.metaInfo?.contentByMetaType?.insurance?.content;

              if (content) {
                const insModel: InsuranceModel = {
                  title: '',
                  value: u.cmsCode ? u.cmsCode : 'CODE_NOT_SET',
                  price: u.price.finalPrice,
                  packageCartId: packageCart.id,
                  componentId: insuranceComp.id,
                  itemId: u.id
                };

                // -> no insurance
                if (content.isNoInsurance) {
                  insModel.title = u.metaInfo?.contentByMetaType?.insurance.content?.displayName
                    ? u.metaInfo?.contentByMetaType?.insurance.content?.displayName.toString()
                    : u.name
                    ? u.name
                    : t(Keys.insuranceNone);
                  insModel.isNoInsurance = true;
                  insNoIns = insModel;
                  setNoInsurance(insModel);
                  setNoInsuranceFeatures(generateFeatures(u));
                }
                // -> without deductible
                else if (content.isRecommendation) {
                  insModel.title = u?.metaInfo?.contentByMetaType?.insurance.content?.displayName
                    ? u?.metaInfo?.contentByMetaType?.insurance.content?.displayName.toString()
                    : u.name
                    ? u.name
                    : t(Keys.insuranceWithoutDeductible);
                  insModel.isRecommendation = true;
                  insWODeduct = insModel;
                  setWithoutDeductibleInsurance(insModel);
                  setWithoutDeductibleFeatures(generateFeatures(u));
                }
                // with deductible
                else {
                  insModel.title = u?.metaInfo?.contentByMetaType?.insurance.content?.displayName
                    ? u?.metaInfo?.contentByMetaType?.insurance.content?.displayName.toString()
                    : u.name
                    ? u.name
                    : t(Keys.insuranceWithDeductible);
                  insWDeduct = insModel;
                  setWithDeductibleInsurance(insModel);
                  setWithDeductibleFeatures(generateFeatures(u));
                }
              }
            }
          });
        });

        const allInsuranceUnits = [insNoIns, insWDeduct, insWODeduct];

        const selInsurance =
          insuranceComp.selectedItems.length === 1
            ? (insuranceComp.selectedItems[0] as ApiInsurance)
            : null;

        if (selInsurance) {
          const selUnitCmsCode =
            selInsurance.units.length === 1 ? selInsurance.units[0].cmsCode : undefined;

          if (selUnitCmsCode) {
            setSelectedInsuranceModel(
              allInsuranceUnits.find(model => model.value === selUnitCmsCode)
            );
          }
        }
      }
    }
  }, [packageCart?.packageModel.packageDetails]);

  if (
    noInsurance === undefined ||
    withDeductibleInsurance === undefined ||
    withoutDeductibleInsurance === undefined
  ) {
    return <div>{t(Keys.insuranceDataLoading)}</div>;
  }

  const changeInsuranceSelection = (selInsuranceModel: InsuranceModel) => {
    bs.selectItemsInPackageCart(selInsuranceModel.componentId, [selInsuranceModel.itemId], {}).then(
      async () => {
        clickAction(true);
        await bs.updatePackageCartBooking();
        setSelectedInsuranceModel(selInsuranceModel);
      }
    );
  };

  return (
    <div>
      <Row>
        <Col xs={12} sm={9}>
          <div className="insurance__header">{t(Keys.insuranceHeader)}</div>
        </Col>
        <Col xs={12} sm={3}>
          <div className="insurance__logo">
            <img
              className="w-100"
              src={`${baseUrl}/img/HanseMerkur_Versicherungsgruppe_logo.svg`}
              alt="HanseMerkur Versicherungsgruppe Logo"
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={9}>
          <div
            dangerouslySetInnerHTML={{
              __html: sanitize(
                t(Keys.insuranceIntroText, { interpolation: { escapeValue: false } })
              )
            }}
          />
        </Col>
      </Row>

      <Row className="insurance__features">
        <Col className="mb-5 mb-sm-0 order-2 order-sm-1">
          <InsuranceDisplay
            model={withDeductibleInsurance}
            onChange={changeInsuranceSelection}
            isSelected={selectedInsuranceModel?.value === withDeductibleInsurance.value}
            features={withDeductibleFeatures}
          />
        </Col>
        <Col className="insurance__features__highlighted mb-5 mb-sm-0 order-1 order-sm-2">
          <InsuranceDisplay
            model={withoutDeductibleInsurance}
            isHighlight
            onChange={changeInsuranceSelection}
            isSelected={selectedInsuranceModel?.value === withoutDeductibleInsurance.value}
            features={withoutDeductibleFeatures}
          />
        </Col>
        <Col className="no-insurance order-3">
          <InsuranceDisplay
            model={noInsurance}
            onChange={changeInsuranceSelection}
            isSelected={selectedInsuranceModel?.value === noInsurance.value}
            features={noInsuranceFeatures}
          />
        </Col>
      </Row>

      <Row className="insurance__legal-info">
        <Col xs={12}>
          <p className="font-weight-bold mb-0 d-sm-inline-block mr-sm-6">
            {t(Keys.insuranceLegalInfo)}
          </p>
          <a target="_blank" href={baseUrl + t(Keys.insuranceAgbLink)}>
            {t(Keys.insuranceAgb)}
          </a>
        </Col>
      </Row>
      {bookedClicked && !selectedInsuranceModel && (
        <Row className="insurance__none-selected-validation">
          <Col>
            <Error message={t(Keys.insuranceNoneSelectedMessage)} />
          </Col>
        </Row>
      )}
    </div>
  );
};

export default InsuranceContainer;
