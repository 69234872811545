import { FormConfig } from '@ibe/components';
import { ApiTravelerType } from '@ibe/api';
import useTravellerFormConfigProductIBE from './useTravellerFormConfigProductIBE';
import { FormRow } from '@ibe/components/dist/ISO/Form/types/types';

const useTravellerFormConfigProductIBEFormWithOptionalConfig = (
  isFirstItem: boolean,
  initialValues?: { [key: string]: string }
): FormConfig => {
  const config = useTravellerFormConfigProductIBE(isFirstItem, initialValues);

  const rowIndex = 0;
  const elements = config.rows[rowIndex].elements;
  const fieldsKeyToMakeOptional = ['street', 'houseNumber', 'city', 'zipCode', 'country'];
  const newElements = [] as FormRow['elements'];
  elements.map(elt => {
    newElements.push(
      fieldsKeyToMakeOptional.includes(elt.key) ? { ...elt, validations: undefined } : elt
    );
  });

  const newConfig = { ...config };
  newConfig.rows[rowIndex].elements = newElements;
  return newConfig;
};

export default useTravellerFormConfigProductIBEFormWithOptionalConfig;
