import styled from '@emotion/styled';

const ContentContainer = styled.div`
  margin-bottom: 1.5rem;
  box-shadow: 0px 0px 8px #d7d7d7;
  padding: 16px;
  background-color: #fff;

  @media (min-width: 768px) {
    padding: 32px;
  }
`;

export default ContentContainer;
