import React, { useEffect, useState, JSX } from 'react';
import { ApiHotel, ApiMetaInfo, ApiPriceFromJSON } from '@ibe/api';
import { Price, StarRating, useTranslation } from '@ibe/components';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardText,
  CardTitle,
  Col,
  CustomInput,
  Modal,
  ModalBody
} from 'reactstrap';
import fallback from '../../../Translations/generated/Checkout.de.json';
import Keys from '../../../Translations/generated/Checkout.de.json.keys';
import { sanitize } from '@ibe/services';
import SingleHotelCarouselComponent, {
  SliderItem
} from './ExtendedBooking/Components/HotelCarousel/SingleHotelCarouselComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import {
  getPersonPriceFromCheapestSortedRates,
  getPersonPriceFromRate
} from './ExtendedBooking/Components/Catering';

type ApiHotelExtended = ApiHotel & {
  metaInfo?: ApiMetaInfo & {
    contentByMetaType?: {
      accommodation?: {
        content?: {
          tileDescription?: string;
          hotelName?: string;
          more?: string;
        };
      };
    };
  };
};

const srcSetWidths: number[] = [
  64,
  128,
  256,
  384,
  448,
  512,
  640,
  704,
  768,
  832,
  896,
  960,
  1024,
  1088,
  1152,
  1280,
  1344,
  1408,
  1472,
  1600
];

const HotelDisplay = ({
  hotel,
  selectedHotel,
  initialHotel,
  onChange,
  modalStateChanged
}: {
  hotel: ApiHotelExtended;
  selectedHotel?: ApiHotel;
  initialHotel: ApiHotel;
  onChange: (selectedHotel: ApiHotel) => void;
  modalStateChanged: (isOpened: boolean) => void;
}): JSX.Element => {
  const [modal, setModal] = useState(false);
  const [mainImage, setMainImage] = useState<SliderItem | null>(null);
  const [sliderItems, setSliderItems] = useState<SliderItem[]>([]);
  const { t } = useTranslation('Checkout', fallback);
  const {
    id: hotelId,
    name,
    media,
    rating,
    price,
    shortDescription,
    description,
    rooms,
    metaInfo: {
      contentByMetaType: {
        accommodation: { content: { tileDescription = '', hotelName = '', more = '' } = {} } = {}
      } = {}
    } = {}
  } = hotel || {};

  const orderedRooms = rooms.sort((room1, room2) => {
    return (
      getPersonPriceFromCheapestSortedRates(room1.roomrates) -
      getPersonPriceFromCheapestSortedRates(room2.roomrates)
    );
  });
  const roomPrice = getPersonPriceFromRate(orderedRooms?.[0].roomrates?.[0]);

  const orderedInitialHotelRooms = initialHotel.rooms.sort((room1, room2) => {
    return (
      getPersonPriceFromCheapestSortedRates(room1.roomrates) -
      getPersonPriceFromCheapestSortedRates(room2.roomrates)
    );
  });
  const roomPriceInitialHotelRoom = getPersonPriceFromRate(
    orderedInitialHotelRooms?.[0].roomrates?.[0]
  );

  useEffect(() => {
    const slides = [];
    let mainImg: SliderItem;

    if (!!media && !!media.sliderMainImage?.focalPointImages?.goldenRatio) {
      slides.push({
        altText: media.sliderMainImage.imageAlt || '',
        caption: media.sliderMainImage.imageCaption || '',
        key: media.sliderMainImage.index,
        srcSet: buildSrcSet(media.sliderMainImage.focalPointImages?.goldenRatio)
      });
    }

    if (!!media && media.images && media.images.length > 0) {
      media.images.map(item => {
        slides.push({
          altText: item.imageAlt || '',
          caption: item.imageCaption || '',
          key: item.index,
          srcSet: buildSrcSet(item.focalPointImages?.goldenRatio)
        });
        return;
      });
    }

    if (!!media && !!media.mainImage?.focalPointImages?.goldenRatio) {
      mainImg = {
        altText: media.mainImage.imageAlt || '',
        caption: media.mainImage.imageCaption || '',
        key: media.mainImage.index,
        srcSet: buildSrcSet(media.mainImage.focalPointImages?.goldenRatio)
      };
      setMainImage(mainImg);
    }

    setSliderItems(slides);
  }, []);

  useEffect(() => {
    modalStateChanged(modal);
  }, [modal]);

  const [hotelNameValue, setHotelNameValue] = useState('');

  useEffect(() => {
    if (hotelName === '' || hotelName === null || hotelName === undefined) {
      setHotelNameValue(name);
    } else {
      setHotelNameValue(hotelName);
    }
  }, [hotelName, name]);

  const toggleModal = () => setModal(value => !value);

  const buildSrcSet = (urlString: string | undefined): string =>
    srcSetWidths.map(width => `${prepareUrlString(urlString, width)} ${width}w,`).join(' ');

  const prepareUrlString = (
    urlString: string | undefined,
    width: number,
    height?: number
  ): string | undefined =>
    urlString
      ?.replace(/_WIDTH_/, !!width ? `${width}` : '')
      .replace(/_HEIGHT_/, !!height ? `${height}` : '')
      .replaceAll(' ', '%20');

  return (
    <>
      <Card className={`iso__hotel-card ${hotel.availability ? '' : 'hotel-unavailable'}`}>
        {mainImage && (
          <img srcSet={!!mainImage.srcSet ? mainImage.srcSet : ''} alt={mainImage.altText} />
        )}
        <CardBody>
          <CardTitle>
            <span className="hotel-name">{hotelNameValue}</span>{' '}
            {rating && <StarRating showEmptyStars numberOfStars={rating} />}
          </CardTitle>
          <CardText>
            {!!tileDescription && (
              <span
                className="truncated-text"
                dangerouslySetInnerHTML={{
                  __html: sanitize(tileDescription)
                }}
              ></span>
            )}
            {!!more && (
              <Button className="more-info-btn" color="link" onClick={toggleModal}>
                {t(Keys.extBookingMoreInfo)}
              </Button>
            )}
          </CardText>
        </CardBody>
        <CardFooter>
          <>
            <Col sm={6} className="p-0">
              <CustomInput
                type="radio"
                className={`extended__booking__custom__input ${
                  !!hotel.availability ? '' : 'd-none'
                }`}
                id={'hotel_select_' + hotelId}
                checked={selectedHotel?.id === hotel.id}
                onChange={() => onChange(hotel)}
                label={
                  <div
                    className={`extended__booking__item ${
                      selectedHotel?.id === hotel.id ? 'extended__booking__item--selected' : ''
                    }`}
                  >
                    <span className="custom-checkbox"></span>
                    <div className="extended__booking__whitespace">
                      {selectedHotel?.id === hotelId
                        ? t(Keys.extBookingRadioSelected)
                        : t(Keys.extBookingRadioSelect)}
                      {t(' ')}
                    </div>
                  </div>
                }
              />
            </Col>

            <Col sm={6} className="text-right p-0">
              {!!hotel.availability && initialHotel.id === hotelId && (
                <span className="additional-costs">{t(Keys.inclusive)}</span>
              )}

              {initialHotel.id !== hotelId && (
                <Price
                  className={`additional-costs ${!!hotel.availability ? '' : 'invisible'}`}
                  price={{
                    ...ApiPriceFromJSON({
                      finalPrice: roomPrice - roomPriceInitialHotelRoom,
                      currencyCode: price.currencyCode,
                      modifiers: []
                    })
                  }}
                  removeZeroDecimals
                  displayInline
                  align={'left'}
                  prefix={roomPrice > roomPriceInitialHotelRoom ? '+' : undefined}
                />
              )}

              <div className={`${!!hotel.availability ? 'per-person' : 'per-person unavailable'}`}>
                {!!hotel.availability && initialHotel.id !== hotelId && t(Keys.extBookingPerPerson)}
                {!!hotel.availability && initialHotel.id === hotelId && <span>&nbsp;</span>}
                {!hotel.availability && t(Keys.extBookingUnavailable)}
              </div>
            </Col>
          </>
        </CardFooter>
      </Card>

      <Modal
        isOpen={modal}
        toggle={toggleModal}
        container={document.getElementById('iso') || undefined}
        centered
        backdrop
        size="lg"
        className="iso__hotel-modal modal-dialog--goldenRatio"
      >
        <ModalBody className="p-0">
          <div className="optional-offer__modal__close" onClick={toggleModal}>
            <FontAwesomeIcon icon={faTimes} />
          </div>

          <SingleHotelCarouselComponent sliderItems={sliderItems} />

          <div className="optional-offer__modal__content hotel-card-content">
            <div className="header">
              <div>
                {rating && (
                  <p className="d-md-none">
                    <StarRating showEmptyStars numberOfStars={rating} />
                  </p>
                )}
                <p className="hotel-name">
                  {hotelNameValue}{' '}
                  <span className="ml-3 d-none d-md-inline">
                    {rating && <StarRating showEmptyStars numberOfStars={rating} />}
                  </span>
                </p>
              </div>
              {initialHotel.id === hotelId && (
                <span className="additional-costs">{t(Keys.inclusive)}</span>
              )}
              {initialHotel.id !== hotelId && (
                <span className="additional-costs">
                  <Price
                    price={{
                      ...ApiPriceFromJSON({
                        finalPrice: roomPrice - roomPriceInitialHotelRoom,
                        currencyCode: price.currencyCode,
                        modifiers: []
                      })
                    }}
                    removeZeroDecimals
                    displayInline
                    prefix={roomPrice > roomPriceInitialHotelRoom ? '+' : undefined}
                  />
                  <span className="per-person">{t(Keys.extBookingPerPerson)}</span>
                </span>
              )}
            </div>
            <p dangerouslySetInnerHTML={{ __html: sanitize(shortDescription) }}></p>
          </div>

          <div className="optional-offer__modal__divider" />

          <div className="optional-offer__modal__content hotel-card-content">
            <p dangerouslySetInnerHTML={{ __html: sanitize(description) }}></p>
          </div>

          <div className="optional-offer__modal__selection pt-0">
            <div className="optional-offer__modal__buttons__container p-0">
              <div className="optional-offer__modal__buttons">
                <Button className="mr-0" onClick={toggleModal}>
                  {t(Keys.close)}
                </Button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default HotelDisplay;
