import * as ReactHabitat from 'react-habitat';
import WhitelabelWidget from '../widgets/WhitelabelWidget';

class Habitat extends ReactHabitat.Bootstrapper {
  constructor() {
    super();

    const builder = new ReactHabitat.ContainerBuilder();
    builder.register(WhitelabelWidget).as('ThgWhitelabel');
    this.setContainer(builder.build());
  }
}

export default new Habitat();
