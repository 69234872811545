import styled from '@emotion/styled';
import React, { ReactNode } from 'react';

interface Props {
  children?: ReactNode;
  className?: string;
}

const HorizontalSpread = (props: Props): JSX.Element => {
  const { children, className } = props;
  const Spread = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  `;

  return <Spread className={className || ''}>{children}</Spread>;
};
export default HorizontalSpread;
