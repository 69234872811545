import React from 'react';
import { observer } from 'mobx-react';
import { Element as ScrollTo } from 'react-scroll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

const ErrorWrapper = observer(
  ({
    scrollElementError,
    hasTermError,
    error
  }: {
    scrollElementError: string;
    hasTermError?: string;
    error?: string;
  }) => {
    return (
      <>
        <ScrollTo name={scrollElementError}>
          <div className="iso__booking-note-text-error-container">
            <FontAwesomeIcon icon={faExclamationTriangle} className="d-inline" />
            <p className="iso__booking-note-text-error ">{hasTermError || error}</p>
          </div>
        </ScrollTo>
      </>
    );
  }
);

export default ErrorWrapper;
