import { CollapsibleWizardItemType } from '../CollapsibleWizard/ContextAndProvider';
import { HotelMain, HotelOptional } from './Components/Hotel';
import { Duration } from './Components/Duration';
import Separator from './Components/Separator';
import { RoomSelectionMain, RoomSelectionOptional } from './Components/RoomSelection/RoomSelection';
import { Travelers } from './Components/Travelers';
import { CateringMain, CateringOptional } from './Components/Catering';

export const steps: CollapsibleWizardItemType[] = [
  {
    component: Duration,
    isActive: true
  },
  {
    component: Travelers
  },
  {
    component: HotelMain
  },
  {
    component: RoomSelectionMain
  },
  {
    component: CateringMain
  },
  {
    component: Separator,
    type: 'SEPARATOR',
    hide: true
  },
  {
    component: HotelOptional,
    hide: true
  },
  {
    component: RoomSelectionOptional,
    hide: true
  },
  {
    component: CateringOptional,
    hide: true
  }
];
