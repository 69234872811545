import { observable, action, runInAction, makeObservable } from 'mobx';

import { pause } from '../Util/pause';

class AppService {
  isLoading = true;

  constructor() {
    makeObservable(this, {
      isLoading: observable,
      setIsLoading: action
    });
  }

  setIsLoading(b: boolean): void {
    this.isLoading = b;
  }

  async init(): Promise<void> {
    await pause(500);
    runInAction(() => {
      this.isLoading = false;
    });
  }
}

export default AppService;
