import {
  ApiBookedItem,
  ApiComponentType,
  ApiHotel,
  ApiHotelRoom,
  ApiItemType,
  ApiPrice,
  ApiPriceModifierType,
  ApiRoomRate,
  ApiTraveler,
  ApiComponent,
  ApiBooking,
  ApiPriceModifier
} from '@ibe/api';
import { useEffect, useMemo } from 'react';
import { useBookingService, useTranslation } from '@ibe/components';
import fallback from '../../../../../Translations/generated/Checkout.de.json';
import Keys from '../../../../../Translations/generated/Checkout.de.json.keys';
import fallbackSummary from '../../../../../Translations/generated/summary.de.json';
import KeysSummary from '../../../../../Translations/generated/summary.de.json.keys';
import { SelectedItemsType } from '../../ExtendedBooking/ContextAndProvider';
import { PriceFactory } from '@ibe/services';
import { getOrderedHotels } from '../../ExtendedBooking/Components/Hotel';
import {
  getCheapestRoomRate,
  getSortedHotelRooms,
  getPersonPriceFromRate
} from '../../ExtendedBooking/Components/RoomSelection/RoomSelection';
import { getAllAvailableRooms } from '../../ExtendedBooking/Components/RoomSelection/useRoomSelection';
import { filterBookedItems } from '../Content';
import { TFunction } from 'i18next';
import { getSortedComponentsWithHotelInfo } from '../../ExtendedBooking/Components/Duration';
import { getPersonPriceFromCheapestSortedRates } from '../../ExtendedBooking/Components/Catering';

export type BookedItemType = {
  item: ApiBookedItem & { useValuePriceDisplay?: boolean };
  amount: number;
};

export interface GroupType {
  label: string;
  basePrice: ApiPrice | undefined;
  hidePrice?: boolean;
  isBasisPrice?: boolean;
  isDiscount?: boolean;
  lists: {
    text: string;
    priceDifference: number | null;
  }[];
}

export interface Props {
  travelerIdx: number;
  bookedItems: BookedItemType[];
  onCollapseToggle?: (travelerIdx: number) => void;
  isOpen?: boolean;
  traveler?: ApiTraveler;
  travelers?: ApiTraveler[];
  booking: ApiBooking;

  extendedBookingItems?: SelectedItemsType;
  onTotalPriceChange: (a: number) => void;
  isConfirmationPage?: boolean;
}

export const useCustomPerTravelerSummaryItem = ({
  travelerIdx,
  traveler,
  bookedItems: formattedBookedItems,
  extendedBookingItems,
  onTotalPriceChange,
  booking,
  travelers = [],
  isConfirmationPage
}: Props) => {
  const { t } = useTranslation('summary', fallback, Keys);
  const { t: tSummary } = useTranslation('summary', fallbackSummary);
  const bookedItems = booking?.bookedItems;
  const bs = useBookingService();

  const name = useMemo(() => {
    const findTraveler = travelers.find(a => a.id === traveler?.id);
    const travelerInfo = findTraveler || traveler;
    return !!travelerInfo?.firstName || !!travelerInfo?.lastName
      ? `${travelerInfo.firstName || ''}${
          !!travelerInfo.firstName && !!travelerInfo.lastName ? ' ' : ''
        }${travelerInfo.lastName || ''}`
      : `${travelerIdx + 1}. ${t(Keys.participant)}`;
  }, [traveler, travelerIdx, t, booking, travelers]);

  const filteredBookedItems = useMemo(() => {
    return filterBookedItems(bookedItems);
  }, [bookedItems]);

  const allDiscountPerson = useMemo(() => {
    return (booking?.travelers || []).map(tr => {
      return PriceFactory.create(
        (bs.bookedItems || [])
          .filter(it => it.type !== ApiItemType.PACKAGE)
          .map(bt =>
            bt.priceByPersonId[tr.id]?.modifiers.filter(
              mod => mod?.type === ApiPriceModifierType.DISCOUNT
            )
          )
          .filter((md, idx) => !!md && md[idx])
          .reduce((prev, curr, idx) => {
            return prev + curr[idx]?.absolute;
          }, 0),
        booking?.price.currencyCode || ''
      );
    });
  }, [booking?.travelers, booking?.bookedItems]);

  const order = [ApiComponentType.MAIN, ApiComponentType.OPTIONAL, ApiComponentType.REQUIRED];

  const allItems = booking?.items || [];

  const hotelComponents = useMemo(() => {
    const packageDetails = bs?.packageCart?.packageModel.packageDetails || [];
    let componentHotels = packageDetails
      .flatMap(detail => detail.components)
      .filter(comp => comp.itemType === ApiItemType.HOTEL);
    if (componentHotels.length < 1) {
      componentHotels = extendedBookingItems?.hotelComponents || [];
    }
    return componentHotels;
  }, [bs?.packageCart?.packageModel, extendedBookingItems?.hotelComponents]);

  const basisPrice = useMemo(() => {
    const mainComponent = hotelComponents.find(
      comp => comp.componentType === ApiComponentType.MAIN
    );

    const orderedHotels = getOrderedHotels(mainComponent?.selectableItems as ApiHotel[]);
    const cheapestHotel = orderedHotels[0];

    const orderedRooms = cheapestHotel.rooms.sort((room1, room2) => {
      return (
        getPersonPriceFromCheapestSortedRates(room1.roomrates) -
        getPersonPriceFromCheapestSortedRates(room2.roomrates)
      );
    });
    return getPersonPriceFromRate(orderedRooms?.[0].roomrates?.[0]);
  }, [hotelComponents]);

  const seasonModifier = useMemo(() => {
    if (!traveler) {
      return {
        absolute: 0,
        type: ApiPriceModifierType.FEE
      } as ApiPriceModifier;
    }

    const allFees =
      bookedItems
        .find(
          bookedItem =>
            bookedItem.itemType === ApiItemType.PACKAGE && bookedItem.priceByPersonId[traveler?.id]
        )
        ?.priceByPersonId?.[traveler?.id]?.modifiers.filter(
          mod => mod.type === ApiPriceModifierType.FEE
        ) || [];

    return allFees && allFees.length > 0
      ? ({
          absolute: allFees.map(fee => fee.absolute).reduce((fee1, fee2) => fee1 + fee2),
          currencyCode: allFees[0].currencyCode,
          description: allFees[0].description,
          type: ApiPriceModifierType.FEE,
          name: allFees[0].name
        } as ApiPriceModifier)
      : ({
          absolute: 0,
          type: ApiPriceModifierType.FEE
        } as ApiPriceModifier);
  }, [bookedItems]);

  const summaryElements: GroupType[] = useMemo(() => {
    const elements: GroupType[] = [];
    if (!traveler) return elements;

    const extensionItems = booking?.bookedItems.find(
      bItem =>
        bItem.itemType === ApiItemType.HOTEL && bItem.componentType === ApiComponentType.OPTIONAL
    );

    const foundRoomRateExt = allItems.find(it => extensionItems?.idParent === it.id) as ApiRoomRate;
    const foundRoomExt = allItems.find(it => foundRoomRateExt?.idParent === it.id) as ApiHotelRoom;
    const extensionDuration = foundRoomExt?.durationInfo?.duration;

    if (!isConfirmationPage && extendedBookingItems && booking) {
      const { duration } = extendedBookingItems;
      const mainComponent = hotelComponents.find(
        comp => comp.componentType === ApiComponentType.MAIN
      );

      const mainInfo = getHotelDetailInformationAndPriceFromExtendedBooking(
        hotelComponents,
        booking,
        extendedBookingItems,
        ApiComponentType.MAIN,
        traveler,
        t,
        tSummary,
        mainComponent
      );

      elements.push({
        label: tSummary(ApiComponentType.MAIN),
        basePrice: PriceFactory.create(
          basisPrice - seasonModifier.absolute,
          booking.price.currencyCode
        ),
        lists: mainInfo.lists,
        isBasisPrice: true
      });

      if (extendedBookingItems?.duration?.selectedExtensionComponent) {
        const optionalInfo = getHotelDetailInformationAndPriceFromExtendedBooking(
          hotelComponents,
          booking,
          extendedBookingItems,
          ApiComponentType.OPTIONAL,
          traveler,
          t,
          tSummary,
          duration?.selectedExtensionComponent
        );

        const componentWithHotelInfo = getSortedComponentsWithHotelInfo(hotelComponents);
        const extensionBasePrice =
          componentWithHotelInfo.find(
            comp =>
              comp.duration === extendedBookingItems?.duration?.extensionDuration &&
              comp.componentSelectedHotel?.isOptional
          )?.price?.finalPrice || 0;
        elements.push({
          label: tSummary(ApiComponentType.OPTIONAL, {
            count: (duration?.extensionDuration || 0) - 1,
            days: (duration?.extensionDuration || 0) - 1
          }),
          basePrice: PriceFactory.create(extensionBasePrice, booking?.price?.currencyCode),
          lists: optionalInfo.lists
        });
      }
    }
    const filteredTypesForExtendedBooking = [ApiItemType.HOTEL];

    groupAndSortItems(
      formattedBookedItems,
      order,
      extendedBookingItems && !isConfirmationPage ? filteredTypesForExtendedBooking : [],
      seasonModifier
    ).map(group => {
      let lists: GroupType['lists'] = [];
      group.items.flatMap(item => {
        if (item.item.itemType === ApiItemType.HOTEL) {
          lists = lists.concat(
            getHotelDetailInformation(hotelComponents, booking, item.item, t, tSummary)
          );
        } else if (isRequiredFlight(item.item)) {
          // FLIGHT
          if (item.item.priceByPersonId[traveler?.id]) {
            lists.push({
              text: tSummary(KeysSummary.airportSurcharge),
              priceDifference: item.item.priceByPersonId[traveler?.id]?.finalPrice || 0
            });
          }
        } else {
          // OTHERS
          if (
            item.item.priceByPersonId[traveler?.id] &&
            item.item.priceByPersonId[traveler?.id].currencyCode
          ) {
            lists.push({
              text: item.item?.name,
              priceDifference: item.item.priceByPersonId[traveler?.id]?.finalPrice || 0
            });
          }
        }
      });
      // SEASON SURCHARGES
      if (group.componentType === ApiComponentType.REQUIRED) {
        lists.push({
          text: tSummary(KeysSummary.seasonalSurcharges),
          priceDifference: seasonModifier.absolute
        });
      }
      if (lists.length > 0 && booking) {
        let groupPrice = group.items?.[0]?.item?.priceByPersonId?.[traveler?.id]?.finalPrice || 0;
        if (group.componentType === ApiComponentType.MAIN) {
          groupPrice = basisPrice - seasonModifier.absolute;
        }

        if (
          (!extendedBookingItems && group.componentType === ApiComponentType.OPTIONAL) ||
          (isConfirmationPage && group.componentType === ApiComponentType.OPTIONAL)
        ) {
          groupPrice =
            groupPrice - lists.reduce((total, item) => total + (item.priceDifference ?? 0), 0);
        }

        elements.push({
          label:
            group.componentType === ApiComponentType.OPTIONAL
              ? tSummary(group.componentType, { count: extensionDuration, days: extensionDuration })
              : tSummary(group.componentType),
          basePrice: PriceFactory.create(
            group.componentType === ApiComponentType.REQUIRED ? 0 : groupPrice,
            booking?.price?.currencyCode
          ),
          lists,
          hidePrice: group.componentType === ApiComponentType.REQUIRED,
          isBasisPrice: group.componentType === ApiComponentType.MAIN
        });
      }
    });

    // DISCOUNT
    if (allDiscountPerson[travelerIdx].finalPrice) {
      elements.push({
        label: tSummary(KeysSummary.voucher),
        basePrice: allDiscountPerson[travelerIdx],
        lists: [],
        isDiscount: true
      });
    }
    return groupAndSumPrices(elements);
  }, [booking, extendedBookingItems, hotelComponents]);

  const totalPricePerPerson = useMemo(() => {
    if (extendedBookingItems && traveler && booking) {
      const price = summaryElements
        .map(elt => {
          const listPrice = elt.lists
            .map(list => {
              return list.priceDifference || 0;
            })
            .reduce((prev, curr) => prev + curr, 0);
          return listPrice + (elt.basePrice?.finalPrice || 0);
        })
        .reduce((prev, curr) => prev + curr, 0);
      return PriceFactory.create(price, booking?.price?.currencyCode);
    }
    if (traveler && booking) {
      return PriceFactory.create(
        filteredBookedItems
          .filter(
            bookedItem =>
              bookedItem.itemType !== ApiItemType.PACKAGE && bookedItem.priceByPersonId[traveler.id]
          )
          .map(it => {
            return it.priceByPersonId[traveler.id].finalPrice;
          })
          .reduce((prev, curr) => prev + curr, 0),
        booking?.price?.currencyCode
      );
    }
  }, [booking, filteredBookedItems, extendedBookingItems, isConfirmationPage]);

  useEffect(() => {
    onTotalPriceChange(totalPricePerPerson?.finalPrice || 0);
  }, [totalPricePerPerson]);

  return {
    name,
    totalPricePerPerson,
    summaryElements
  };
};

export const groupAndSortItems = (
  items: BookedItemType[],
  order: ApiComponentType[] = [],
  filterItems?: ApiItemType[],
  seasonModifier?: ApiPriceModifier
) => {
  const groups: { componentType: string; items: BookedItemType[] }[] = order.map(initOrder => ({
    componentType: initOrder,
    items: []
  }));
  const otherGroup: typeof groups[0] = { componentType: 'rest', items: [] };

  items.forEach(item => {
    if (item?.item?.componentType && !(filterItems || []).includes(item?.item?.itemType)) {
      if (item.item.itemType !== ApiItemType.HOTEL) {
        const groupIndex = order.indexOf(ApiComponentType.REQUIRED);
        groups[groupIndex].items.push(item);
      } else {
        const groupIndex = order.indexOf(item?.item?.componentType || ApiComponentType.OPTIONAL);
        if (groupIndex !== -1) {
          groups[groupIndex].items.push(item);
        } else {
          otherGroup.items.push(item);
        }
      }
    }
  });
  if (seasonModifier && !groups.find(gr => gr.componentType === ApiComponentType.REQUIRED)) {
    groups.push({ componentType: ApiComponentType.REQUIRED, items: [] });
  }
  if (otherGroup.items.length > 0) {
    groups.push(otherGroup);
  }
  return groups;
};

const isRequiredFlight = (item: ApiBookedItem): boolean => {
  return item.itemType === ApiItemType.FLIGHT && item.componentType === ApiComponentType.REQUIRED;
};

export const getPersonPriceFromHotel = (hotel: ApiBookedItem, travelerId: string): number => {
  return hotel.priceByPersonId[travelerId].finalPrice || 0;
};

const getHotelDetailInformation = (
  hotelComponents: ApiComponent[],
  booking: ApiBooking,
  item: ApiBookedItem,
  t: TFunction,
  tSummary: TFunction
) => {
  const lists: GroupType['lists'] = [];

  const allItems = booking?.items || [];

  const foundRoomRate = allItems.find(it => item.idParent === it.id) as ApiRoomRate;
  const foundRoom = allItems.find(it => foundRoomRate.idParent === it.id) as ApiHotelRoom;
  const foundHotel = allItems.find(it => foundRoom.idParent === it.id) as ApiHotel;
  const suffix =
    item.componentType === ApiComponentType.OPTIONAL ? tSummary(KeysSummary.extension) : '';

  const foundHotelComponent = hotelComponents.find(
    hotel =>
      hotel.componentType === item.componentType &&
      hotel?.selectableItems.find(select => {
        const selectableItem = select as ApiHotel;
        return selectableItem.durationInfo?.duration === foundRoom?.durationInfo?.duration;
      })
  );

  if (foundHotelComponent) {
    const selectableItems = (foundHotelComponent?.selectableItems || []) as ApiHotel[];
    const foundHotelFromSelectable = selectableItems.find(
      selectableItem => selectableItem.code === foundHotel?.code
    );

    // HOTEL
    const cheapestHotel = getOrderedHotels(selectableItems)?.[0];
    if (foundHotelFromSelectable) {
      const orderedRooms = foundHotelFromSelectable?.rooms.sort((room1, room2) => {
        return (
          getPersonPriceFromCheapestSortedRates(room1.roomrates) -
          getPersonPriceFromCheapestSortedRates(room2.roomrates)
        );
      });
      const roomPrice = orderedRooms?.[0].roomrates?.[0]
        ? getPersonPriceFromRate(orderedRooms?.[0].roomrates?.[0])
        : 0;

      const orderedCheapestHotelRooms = cheapestHotel.rooms.sort((room1, room2) => {
        return (
          getPersonPriceFromCheapestSortedRates(room1.roomrates) -
          getPersonPriceFromCheapestSortedRates(room2.roomrates)
        );
      });
      const roomPriceCheapestHotelRoom = getPersonPriceFromRate(
        orderedCheapestHotelRooms?.[0].roomrates?.[0]
      );

      lists.push({
        text: suffix ? `${suffix}: ${foundHotel?.name}` : `${foundHotel?.name}`,
        priceDifference: roomPrice - roomPriceCheapestHotelRoom
      });
    }

    // ROOM
    const availableRooms = getAllAvailableRooms(selectableItems, foundHotel);

    const cheapestRoom = getSortedHotelRooms(availableRooms)[0];
    const foundRoomFromAvailableRooms = getSortedHotelRooms(availableRooms).find(
      availableRoom => availableRoom.roomCode === foundRoom.roomCode
    );

    if (foundRoomFromAvailableRooms) {
      const cheapestRoomRate = getCheapestRoomRate(cheapestRoom);

      const foundRoomCheapestRoomRatePrice = getPersonPriceFromRate(
        foundRoomFromAvailableRooms.roomrates.sort((rate1, rate2) => {
          return getPersonPriceFromRate(rate1) - getPersonPriceFromRate(rate2);
        })[0]
      );
      const cheapestRoomRatePrice = getPersonPriceFromRate(cheapestRoomRate);

      lists.push({
        text: suffix ? `${suffix}: ${foundRoom?.name}` : `${foundRoom?.name}`,
        priceDifference: foundRoomCheapestRoomRatePrice - cheapestRoomRatePrice
      });
    }

    // RATE
    const possibleRates =
      foundHotelFromSelectable?.rooms[0].roomrates.sort(
        (a, b) => a.totalPrice.finalPrice - b.totalPrice.finalPrice
      ) || [];

    const cheapestRate = possibleRates && possibleRates[0];
    const foundRoomRateFromPossibleRate = possibleRates.find(
      possibleRate => possibleRate.mealTypeCode === foundRoomRate.mealTypeCode
    );

    if (foundRoomRateFromPossibleRate) {
      const rateLabel =
        foundRoomRate.mealTypeDescription === null
          ? t(Keys.roomOnly)
          : foundRoomRate.mealTypeDescription;

      lists.push({
        text: suffix ? `${suffix}: ${rateLabel}` : `${rateLabel}`,
        priceDifference:
          getPersonPriceFromRate(foundRoomRateFromPossibleRate) -
          getPersonPriceFromRate(cheapestRate)
      });
    }
  }
  return lists;
};

const getHotelDetailInformationAndPriceFromExtendedBooking = (
  hotelComponents: ApiComponent[],
  booking: ApiBooking,
  extendedBookingItems: SelectedItemsType,
  componentType: ApiComponentType,
  traveler: ApiTraveler,
  t: TFunction,
  tSummary: TFunction,
  component?: ApiComponent
) => {
  const lists: GroupType['lists'] = [];

  if (!booking) return { lists };
  const {
    roomsMain,
    hotelMain,
    cateringMain,
    hotelOptional,
    cateringOptional,
    roomsOptional
  } = extendedBookingItems;
  const extensionHotel = componentType === ApiComponentType.MAIN ? hotelMain : hotelOptional;
  const extensionRooms = componentType === ApiComponentType.MAIN ? roomsMain : roomsOptional;
  const extensionCatering =
    componentType === ApiComponentType.MAIN ? cateringMain : cateringOptional;

  const foundHotelComponent = hotelComponents.find(h => h.id === component?.id);

  const selectableItems = (foundHotelComponent?.selectableItems || []) as ApiHotel[];

  const foundHotelFromSelectable = selectableItems.find(
    selectableItem => selectableItem.code === extensionHotel?.code
  );

  const suffix = componentType === ApiComponentType.OPTIONAL ? tSummary(KeysSummary.extension) : '';

  if (extensionHotel) {
    // HOTEL
    const cheapestHotel = getOrderedHotels(selectableItems)?.[0];

    const orderedRooms = foundHotelFromSelectable?.rooms.sort((room1, room2) => {
      return (
        getPersonPriceFromCheapestSortedRates(room1.roomrates) -
        getPersonPriceFromCheapestSortedRates(room2.roomrates)
      );
    });
    const roomPrice = orderedRooms?.[0].roomrates?.[0]
      ? getPersonPriceFromRate(orderedRooms?.[0].roomrates?.[0])
      : 0;

    const orderedCheapestHotelRooms = cheapestHotel.rooms.sort((room1, room2) => {
      return (
        getPersonPriceFromCheapestSortedRates(room1.roomrates) -
        getPersonPriceFromCheapestSortedRates(room2.roomrates)
      );
    });
    const roomPriceCheapestHotelRoom = getPersonPriceFromRate(
      orderedCheapestHotelRooms?.[0].roomrates?.[0]
    );

    if (foundHotelFromSelectable) {
      lists.push({
        text: suffix ? `${suffix}: ${extensionHotel?.name || ''}` : `${extensionHotel?.name || ''}`,
        priceDifference: roomPrice - roomPriceCheapestHotelRoom
      });
    }
  } else {
    lists.push({
      text: suffix
        ? `${suffix}: ${tSummary(KeysSummary.yourHotel)}`.trim()
        : `${tSummary(KeysSummary.yourHotel)}`,
      priceDifference: null
    });
  }

  if (extensionRooms && extensionHotel) {
    const roomFromExtendedRooms = (extensionRooms || []).find(rm =>
      rm.travelers.find(trav => trav.id === traveler.id)
    );
    const foundRoom = roomFromExtendedRooms?.room;
    // ROOM
    const availableRooms = getAllAvailableRooms(selectableItems, extensionHotel);
    const cheapestRoom = getSortedHotelRooms(availableRooms)[0];
    const foundRoomFromAvailableRooms = getSortedHotelRooms(availableRooms).find(
      availableRoom => availableRoom.roomCode === foundRoom?.roomCode
    );

    if (foundRoomFromAvailableRooms) {
      const cheapestRoomRate = getCheapestRoomRate(cheapestRoom);

      const foundRoomCheapestRoomRatePrice = getPersonPriceFromRate(
        foundRoomFromAvailableRooms.roomrates.sort((rate1, rate2) => {
          return getPersonPriceFromRate(rate1) - getPersonPriceFromRate(rate2);
        })[0]
      );
      const cheapestRoomRatePrice = getPersonPriceFromRate(cheapestRoomRate);
      const roomPriceDifference = foundRoomCheapestRoomRatePrice - cheapestRoomRatePrice;
      lists.push({
        text: suffix ? `${suffix}: ${foundRoom?.name || ''}` : `${foundRoom?.name || ''}`,
        priceDifference: roomPriceDifference
      });
    }
  } else {
    lists.push({
      text: suffix
        ? `${suffix}: ${tSummary(KeysSummary.yourRoom)}`
        : `${tSummary(KeysSummary.yourRoom)}`,
      priceDifference: null
    });
  }

  if (extensionCatering && extensionHotel) {
    const mealTypeCode = extensionCatering?.mealTypeCode;
    const mealTypeDescription = extensionCatering?.mealTypeDescription;
    // RATE
    const possibleRates =
      foundHotelFromSelectable?.rooms[0].roomrates.sort(
        (a, b) => a.totalPrice.finalPrice - b.totalPrice.finalPrice
      ) || [];

    const cheapestRate = possibleRates && possibleRates[0];
    const foundRoomRateFromPossibleRate = possibleRates.find(
      possibleRate => possibleRate.mealTypeCode === mealTypeCode
    );

    if (foundRoomRateFromPossibleRate) {
      const roomRatePriceDifference =
        getPersonPriceFromRate(foundRoomRateFromPossibleRate) -
        getPersonPriceFromRate(cheapestRate);
      lists.push({
        text: suffix
          ? `${suffix}: ${
              mealTypeDescription === null ? t(Keys.roomOnly) : mealTypeDescription || ''
            }`
          : `${mealTypeDescription === null ? t(Keys.roomOnly) : mealTypeDescription || ''}`,
        priceDifference: roomRatePriceDifference
      });
    }
  } else {
    lists.push({
      text: suffix
        ? `${suffix}: ${tSummary(KeysSummary.yourMeal)}`
        : `${tSummary(KeysSummary.yourMeal)}`,
      priceDifference: null
    });
  }

  return { lists };
};

const groupAndSumPrices = (groups: GroupType[]) => {
  return groups.map(group => {
    const priceMap = group.lists.reduce((acc, item) => {
      if (item.text in acc && item.priceDifference !== null) {
        if (acc[item.text] !== undefined) {
          let value = acc[item.text];
          if (value !== null) value += item.priceDifference;
          acc[item.text] = value;
        }
      } else {
        acc[item.text] = item.priceDifference;
      }
      return acc;
    }, {} as { [key: string]: number | null });

    return {
      ...group,
      lists: Object.keys(priceMap).map(text => ({
        text,
        priceDifference: priceMap[text]
      }))
    };
  });
};
