/* This file was generated by running "node scripts/generateTranslations.js". */
/* Created: 2024-07-22 09:22:06 */

const Keys = {
  paxSelection: 'paxSelection',
  travelDate: 'travelDate',
  destination: 'destination',
  destinationPlural: 'destinationPlural',
  hotels: 'hotels',
  hotel: 'hotel',
  hotel_plural: 'hotel_plural',
  origin: 'origin',
  submit: 'submit',
  invalidInput: 'invalidInput',
  travelers: 'travelers',
  select: 'select',
  travelTime: 'travelTime',
  adjustSearch: 'adjustSearch',
  nights: 'nights',
  duration: 'duration',
  night: 'night',
  night_plural: 'night_plural',
  week: 'week',
  week_plural: 'week_plural',
  arbitrary: 'arbitrary',
  noticeBestPriceEndDateChange: 'noticeBestPriceEndDateChange',
  date: 'date',
  pricePerPerson: 'pricePerPerson',
  maintenanceMode: 'maintenanceMode',
  dateAndDuration: 'dateAndDuration'
}; export default Keys;