import React, { Dispatch, useRef } from 'react';
import { Button, Input } from 'reactstrap';
import { Error, Info, useTranslation, Warning } from '@ibe/components';
import Keys from '../../../Translations/generated/Checkout.de.json.keys';
import fallback from '../../../Translations/generated/Checkout.de.json';
import ContentContainer from '../../../Components/ContentContainer';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export type ValidationResult = 'ERROR' | 'VALID' | 'NUMBER_MISMATCH' | 'NOT_SET';

const DCard = ({
  cardNumber,
  setCardNumber,
  onSubmit,
  cardValid
}: {
  cardNumber: string | null;
  setCardNumber: Dispatch<React.SetStateAction<string | null>>;
  onSubmit: () => void;
  cardValid: ValidationResult;
}): JSX.Element => {
  const { t } = useTranslation('Checkout', fallback);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleClearClick = (): void => {
    setCardNumber('');

    if (!!inputRef.current) {
      inputRef.current.focus();
    }
  };

  return (
    <ContentContainer>
      <div className="font-weight-bold mb-4">{t(Keys.dCard)}</div>
      <div className="font-weight-bold mb-4">{t(Keys.dCardSubText)}</div>
      {cardValid === 'ERROR' && <Error message={t(Keys.dCardNotValid)} />}
      {cardValid === 'VALID' && <Info message={t(Keys.dCardValid)} />}
      {cardValid === 'NUMBER_MISMATCH' && <Warning message={t(Keys.dCardNumberMismatch)} />}
      <div className="dCard__input">
        <div className="dCard__input__inner">
          <Input
            innerRef={inputRef}
            type={'text'}
            value={cardNumber ? cardNumber : ''}
            onChange={(e): void => {
              setCardNumber(e.target.value);
            }}
          />
          {cardNumber && <FontAwesomeIcon icon={faTimesCircle} onClick={handleClearClick} />}
        </div>

        <Button onClick={onSubmit}>{t(Keys.dCardSubmit)}</Button>
      </div>
    </ContentContainer>
  );
};

export default DCard;
