import React, { ReactNode } from 'react';
import { Col, Label, Row } from 'reactstrap';
import {
  faCalendarAlt,
  faPlaneDeparture,
  faClock,
  faMapMarkerAlt,
  faUserFriends
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ApiBestPricePackageModel,
  ApiItemType,
  ApiPackageModel,
  ApiRoomContainer,
  ApiSearchProductDataResponse
} from '@ibe/api';
import {
  DateDisplay,
  PackageItemSelection,
  PackageParams,
  PackageStore,
  PackageSearchStore,
  BestPriceAvailabilityStore,
  useTranslation,
  MobileModalInputWrapper,
  DestinationItem,
  CustomProductSelect,
  DestinationItemCategory,
  useExternalSearchParams,
  useMediaQuery
} from '@ibe/components';

import PackageListItem from './PackageListItem';
import Keys from '../../Translations/generated/search-content.de.json.keys';
import fallback from '../../Translations/generated/search-content.de.json';

import KeysPack from '../../Translations/generated/PackageSearch.de.json.keys';
import fallbackPack from '../../Translations/generated/PackageSearch.de.json';

import fallbackDateRange from '../../Translations/generated/date-range-input.en.t.json';
import KeysDateRange from '../../Translations/generated/date-range-input.en.t.json.keys';

import { observer } from 'mobx-react';
const NUMBER_DAY_PER_WEEK = 7;

export const renderSearch = (
  CategorySelect: ReactNode,
  OriginSelect: ReactNode,
  DestinationSelect: ReactNode,
  TravelDatePicker: ReactNode,
  TravelMonthPicker: ReactNode,
  TravelerPicker: ReactNode,
  SearchButton: ReactNode,
  OriginCheckboxes: ReactNode,
  DateRangePickerSingleSelect: ReactNode
): JSX.Element => {
  const onDestinationOpen = () => {
    const element = document.getElementById('productInput');
    setTimeout(() => {
      if (element) element.focus();
    }, 250);
  };

  return (
    <div className="iso__service-search justify-content-center">
      <Row className="iso__service-search__row__1">
        <Col className="iso__service-search__row__1__col__1" sm={6} lg={4}>
          <div className="d-flex align-items-end position-relative">
            <FontAwesomeIcon
              icon={faPlaneDeparture}
              className="mr-2 mb-2 text-secondary d-inline iso__service-search__icon"
            />
            <div className="w-100">{OriginCheckboxes}</div>
          </div>
        </Col>
        <Col className="iso__service-search__row__1__col__2" sm={6} lg={4}>
          <MobileModalInputWrapper onOpen={onDestinationOpen} breakpoint={'(max-width: 576px)'}>
            <div className="d-flex align-items-end position-relative">
              <FontAwesomeIcon
                icon={faMapMarkerAlt}
                className="mr-2 mb-2 text-secondary d-inline iso__service-search__icon"
              />
              <div className="w-100">{DestinationSelect}</div>
            </div>
          </MobileModalInputWrapper>
        </Col>
        <Col className="iso__service-search__row__1__col__3" sm={6} lg={4} />
      </Row>
      <Row className="iso__service-search__row__2 mt-lg-3">
        <Col className="iso__service-search__row__2__col__1" sm={6} lg={4}>
          <div className="d-flex align-items-end position-relative">
            <FontAwesomeIcon
              icon={faCalendarAlt}
              className="mr-2 mb-2 text-secondary d-inline iso__service-search__icon"
            />
            <div className="w-100">{DateRangePickerSingleSelect}</div>
          </div>
        </Col>
        <Col className="iso__service-search__row__2__col__2" sm={6} lg={4}>
          <div className="d-flex align-items-end position-relative">
            <FontAwesomeIcon
              icon={faUserFriends}
              className="mr-1 mb-2 text-secondary d-inline iso__service-search__icon"
            />
            <div className="w-100">{TravelerPicker}</div>
          </div>
        </Col>
        <Col lg={4} className="d-flex flex-column">
          <div className="mt-auto">{SearchButton}</div>
        </Col>
      </Row>
    </div>
  );
};

const getNumber = (occupancy: ApiRoomContainer[], property: string): number => {
  return (occupancy as (ApiRoomContainer & { [key: string]: number })[]).reduce(
    (total: number, current: ApiRoomContainer & { [key: string]: number }) =>
      total + current[property],
    0
  );
};

export const renderSearchContent = (
  _: PackageStore,
  searchStore: PackageSearchStore,
  searchParams?: Partial<PackageParams>
): JSX.Element => {
  const { t } = useTranslation('search-content', fallback);
  const { t: tPack } = useTranslation('PackageSearch', fallbackPack);
  const { t: tDateRange } = useTranslation('date-range-input', fallbackDateRange);
  const destination = searchStore?.destinationItem?.name;
  if (!searchParams) return <></>;

  const isMobile = useMediaQuery({ query: '576', type: 'max' });
  const departureMobile = isMobile
    ? `${searchParams?.origins?.[0]?.name || ''}${searchParams?.origins?.length ? ', ...' : ''}`
    : '';

  let numberOfHotelLabel = '';
  if (searchStore.mainServiceCodes && searchStore.mainServiceCodes.length) {
    // JUST FOR THG, WILL BE UPDATE LATER
    const numberOfHotel = searchStore.mainServiceCodes.length;
    numberOfHotelLabel = tPack(KeysPack.hotel, { count: numberOfHotel, numberOfHotel });
  }

  const durationToString = () => {
    const duration = searchParams.duration;
    if (!duration) return '';
    const rest = duration % NUMBER_DAY_PER_WEEK;
    const numberOfWeeks = duration / NUMBER_DAY_PER_WEEK;
    if (rest || !numberOfWeeks)
      return tDateRange(KeysDateRange.nightComma, { count: duration, numberOfNights: duration });
    return tDateRange(KeysDateRange.weekComma, { count: numberOfWeeks, numberOfWeeks });
  };

  return (
    <div className="package__search__content">
      <div>
        <FontAwesomeIcon icon={faPlaneDeparture} />
        {departureMobile ||
          searchParams.origins?.map(origin => origin.name).join(', ') ||
          tPack(KeysPack.arbitrary)}
      </div>
      <div>
        <FontAwesomeIcon icon={faMapMarkerAlt} />
        {numberOfHotelLabel || destination}
      </div>
      <div>
        {searchParams.startDate && searchParams.endDate && (
          <>
            <FontAwesomeIcon icon={faCalendarAlt} />
            <DateDisplay date={searchParams.startDate} />
            {' / '}
            <DateDisplay date={searchParams.endDate} />
            {durationToString()}
          </>
        )}
      </div>
      {!!searchParams.occupancy && (
        <div>
          <FontAwesomeIcon icon={faUserFriends} />
          {`${getNumber(searchParams.occupancy, 'adults')} ${t(Keys.adult, {
            count: getNumber(searchParams.occupancy, 'adults')
          })}${
            getNumber(searchParams.occupancy, 'children') > 0
              ? `, ${getNumber(searchParams.occupancy, 'children')} ${t(Keys.child, {
                  count: getNumber(searchParams.occupancy, 'children')
                })}`
              : ''
          }`}
        </div>
      )}
    </div>
  );
};

export const bestPriceRenderSearchContent = (store: BestPriceAvailabilityStore): JSX.Element => {
  const { t } = useTranslation('search-content', fallback);
  const { t: tPack } = useTranslation('PackageSearch', fallbackPack);

  const searchParams = store.searchParams;
  if (!searchParams) return <></>;

  const isMobile = useMediaQuery({ query: '576', type: 'max' });
  const departureMobile = isMobile
    ? `${searchParams?.origins?.[0]?.name || ''}${searchParams?.origins?.length ? ', ...' : ''}`
    : '';

  const getNumberOfNight = (number: number): string => {
    const rest = number % 7;
    if (rest) return `${number} ${tPack(KeysPack.night, { count: number })}`;
    return `${number / 7} ${tPack(KeysPack.week, { count: number / 7 })}`;
  };
  const numberOfNights = getNumberOfNight(searchParams.duration || 0);
  return (
    <div className="package__search__content">
      <div>
        <FontAwesomeIcon icon={faPlaneDeparture} />
        {departureMobile ||
          searchParams.origins?.map(origin => origin.name).join(', ') ||
          tPack(KeysPack.arbitrary)}
      </div>
      <div>
        {searchParams.startDate && searchParams.endDate && (
          <>
            <FontAwesomeIcon icon={faCalendarAlt} />
            <DateDisplay date={searchParams.startDate} />
            {searchParams.endDate ? (
              <>
                {' / '}
                <DateDisplay date={searchParams.endDate} />
              </>
            ) : null}
          </>
        )}
      </div>
      <div>
        <FontAwesomeIcon icon={faClock} />
        {numberOfNights}
      </div>
      {!!searchParams.occupancy && (
        <div>
          <FontAwesomeIcon icon={faUserFriends} />
          {`${getNumber(searchParams.occupancy, 'adults')} ${t(Keys.adult, {
            count: getNumber(searchParams.occupancy, 'adults')
          })}${
            getNumber(searchParams.occupancy, 'children') > 0
              ? `, ${getNumber(searchParams.occupancy, 'children')} ${t(Keys.child, {
                  count: getNumber(searchParams.occupancy, 'children')
                })}`
              : ''
          }`}
        </div>
      )}
    </div>
  );
};

const renderListItem = (
  item: ApiPackageModel,
  index?: number,
  onItemSelect?: (selection: Partial<PackageItemSelection>) => void
): JSX.Element => {
  const bestPriceItem = item as ApiBestPricePackageModel;
  return (
    <div key={item.id} className="mb-3">
      <PackageListItem item={bestPriceItem} onSelect={onItemSelect} index={index} />
    </div>
  );
};

export default renderListItem;

export const CustomIndexedDestinationSelect = observer(function IndexedDestinationSelect(props: {
  store: PackageSearchStore;
}): JSX.Element {
  const { store } = props;
  const { t } = useTranslation('PackageSearch', fallbackPack);
  const context = useExternalSearchParams();

  const destinationOptionMapper = (
    result: ApiSearchProductDataResponse,
    searchTerm?: string
  ): DestinationItem[] => {
    let possibleOptions: DestinationItem[] = [];

    if (!searchTerm) {
      possibleOptions = result.geoUnits
        .sort((a, b) => a.name.localeCompare(b.name))
        .reduce((total, currentValue) => {
          return [
            ...total,
            {
              id: currentValue.id,
              name: currentValue.name,
              code: currentValue.code,
              typeCode: currentValue.typeCode,
              category: DestinationItemCategory.GEO_UNIT
            },
            ...Object.entries(currentValue.children)
              .sort((a, b) => a[1].name.localeCompare(b[1].name))
              .map(([, value]) => ({
                id: value.id,
                name: value.name,
                code: value.code,
                typeCode: value.typeCode,
                category: DestinationItemCategory.GEO_UNIT
              }))
          ];
        }, [] as DestinationItem[]);
    } else {
      possibleOptions = [
        ...result.geoUnits.map(geoUnit => ({
          id: geoUnit.id,
          name: geoUnit.name,
          code: geoUnit.code,
          typeCode: geoUnit.typeCode,
          category: DestinationItemCategory.GEO_UNIT
        })),
        ...result.products.map(product => ({
          id: product.id,
          name: product.description,
          code: product.id,
          typeCode: product.itemType,
          category: DestinationItemCategory.PRODUCT
        }))
      ];
      // Filter Hotel
      const otherOptions = possibleOptions.filter(opt => opt.typeCode !== ApiItemType.HOTEL);
      const hotels = possibleOptions.filter(opt => opt.typeCode === ApiItemType.HOTEL);
      possibleOptions = [...otherOptions, ...hotels];
    }
    return possibleOptions;
  };

  const renderDestinationLabel = (
    option: DestinationItem,
    options: DestinationItem[],
    searchTerm?: string
  ): JSX.Element => {
    if (!searchTerm) {
      let className = 'd-flex productSelect-item ';
      className =
        option.typeCode === 'COUNTRY'
          ? (className += 'productSelect-country')
          : (className += 'productSelect-dest');
      return (
        <div className={className}>
          <span>{option.name}</span>
          <div></div>
        </div>
      );
    }
    const isFirst = options.findIndex(opt => opt.code === option.code);
    const firstHotelIndex = options.findIndex(opt => opt.typeCode === ApiItemType.HOTEL);
    const optionIndex = options.findIndex(
      opt => opt.code === option.code && opt.typeCode === ApiItemType.HOTEL
    );
    const classNameWithSearchTerm = 'd-flex productSelect-item---search ';
    return (
      <>
        {isFirst === 0 && option.typeCode !== ApiItemType.HOTEL ? (
          <div className="productSelect-label">
            {t(KeysPack.destinationPlural)}
            <span></span>
          </div>
        ) : null}
        {firstHotelIndex === optionIndex && option.typeCode === ApiItemType.HOTEL ? (
          <div className="productSelect-label">
            {t(KeysPack.hotels)}
            <span></span>
          </div>
        ) : null}
        <div className={classNameWithSearchTerm}>
          <span>{option.name}</span>
        </div>
      </>
    );
  };

  return (
    <>
      <Label htmlFor="productInput">{t(KeysPack.destination)}</Label>
      <CustomProductSelect
        placeholder={t(KeysPack.select)}
        emptySearchAllowed
        renderLabel={renderDestinationLabel}
        optionsMapper={destinationOptionMapper}
        dataTestId="packageDestinationInput"
        name="productInput"
        itemType={ApiItemType.HOTEL}
        hideAnywhereGeoUnit
        value={store.destinationItem}
        // selectMultiple={opt =>
        //   // !!(context?.mainServiceCodes?.length && opt.typeCode === ApiItemType.HOTEL)
        // }
        selectMultiple={opt => !opt}
        cacheRequest
        onSelect={(item: DestinationItem | DestinationItem[] | null): void => {
          if (item) {
            if (!Array.isArray(item)) {
              store.setMainServiceCodes([]);
              return store.setDestinationItem(item || undefined);
            }
            const hotelsCode = item.reduce((mainServices, itm) => {
              mainServices.push(itm.code);
              return mainServices;
            }, [] as string[]);
            store.setMainServiceCodes(hotelsCode);
            return store.setDestinationItem(undefined);
          }
        }}
      />
    </>
  );
});
