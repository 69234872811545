import React from 'react';

import { useTranslation } from '@ibe/components';
import fallback from '../../../../Translations/generated/Checkout.de.json';
import Keys from '../../../../Translations/generated/Checkout.de.json.keys';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { useAffiliateContext } from '../../../../Util/AffiliateProvider';
import { sanitize } from '@ibe/services';

const HotLine = () => {
  const { t } = useTranslation('Checkout', fallback);
  const affiliateContactData = useAffiliateContext().metaContent?.content?.contactData;
  return affiliateContactData ? (
    <div
      className="sticky-summary-box-hotline"
      dangerouslySetInnerHTML={{ __html: sanitize(affiliateContactData + '') }}
    ></div>
  ) : (
    <div className="sticky-summary-box-hotline">
      <h3>{t(Keys.consulting)}</h3>
      <div>
        <FontAwesomeIcon icon={faPhone} />
        <span>{t(Keys.hotlineNumber)}</span>
      </div>
      <p className="text-muted">{t(Keys.officeHours)}</p>
    </div>
  );
};

export default HotLine;
