/* This file was generated by running "node scripts/generateTranslations.js". */
/* Created: 2024-07-22 09:22:06 */

const Keys = {
  priceOverview: 'priceOverview',
  travelDate: 'travelDate',
  numberParticipants: 'numberParticipants',
  duration: 'duration',
  departureAirport: 'departureAirport',
  flights: 'flights',
  day: 'day',
  day_plural: 'day_plural',
  hotel: 'hotel',
  trip: 'trip'
}; export default Keys;