// eslint-disable-next-line
enum CheckoutPageUrl {
  PACKAGES = '/checkout/packages',
  SUMMARY = '/checkout/summary',
  EXTRAS = '/checkout/extras',
  TRAVELLER = '/checkout/traveler',
  CONFIRMATION = '/checkout/confirmation'
}

export default CheckoutPageUrl;
