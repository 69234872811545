import { ApiListOptions } from '@ibe/api';
import { encodeObjectasUrlParam, LoggerFactory, removeUndefinedProps } from '@ibe/services';

export interface RawListOptionsParams {
  pagination?: string;
  sorting?: string;
  filter?: string;
  filteredCount?: string;
  count?: string;
}

export class ListOptionsParamsTransformer {
  static decode(urlParams: RawListOptionsParams): Partial<ApiListOptions> {
    const logger = LoggerFactory.get('ListOptionsParamsTransformer');
    const listOptions: Partial<ApiListOptions> = {};
    if (!urlParams) {
      return listOptions;
    }
    try {
      listOptions.pagination = urlParams.pagination ? JSON.parse(urlParams.pagination) : undefined;
      listOptions.sorting = urlParams.sorting ? JSON.parse(urlParams.sorting) : undefined;
      listOptions.filter = urlParams.filter ? JSON.parse(urlParams.filter) : undefined;
      listOptions.filteredCount = urlParams.filteredCount
        ? parseInt(urlParams.filteredCount, 10)
        : undefined;
      listOptions.count = urlParams.count ? parseInt(urlParams.count, 10) : undefined;
    } catch (e) {
      logger.error(e);
    }
    return removeUndefinedProps(listOptions);
  }

  static encode(listOptions: ApiListOptions): string {
    const obj: RawListOptionsParams = {
      pagination: JSON.stringify(listOptions.pagination),
      sorting: JSON.stringify(listOptions.sorting),
      filter: JSON.stringify(listOptions.filter),
      filteredCount: listOptions.filteredCount.toString(),
      count: listOptions.filteredCount.toString()
    };

    return encodeObjectasUrlParam(removeUndefinedProps(obj));
  }
}
