import { LoggerFactory, removeUndefinedProps } from '@ibe/services';
import CustomizerParams from './CustomizerParams';
import RawCustomizerParams from './RawCustomizerParams';

class CustomizerParamsTransformer {
  static decode(urlParams: RawCustomizerParams): Partial<CustomizerParams> {
    const logger = LoggerFactory.get('CustomizerParamsTransformer');
    const params: Partial<CustomizerParams> = {};
    if (!urlParams) {
      return params;
    }
    try {
      params.itemId = urlParams.item ? decodeURIComponent(urlParams.item) : undefined;
      params.travelDateId = urlParams.date ? decodeURIComponent(urlParams.date) : undefined;
      params.packageDetailId = urlParams.detail ? decodeURIComponent(urlParams.detail) : undefined;
    } catch (e) {
      logger.error(e);
    }
    return params;
  }

  static encode(params: Partial<CustomizerParams>): Partial<RawCustomizerParams> {
    const obj: RawCustomizerParams = {
      item: params.itemId ? encodeURIComponent(params.itemId) : undefined,
      date: params.travelDateId ? encodeURIComponent(params.travelDateId) : undefined,
      detail: params.packageDetailId ? encodeURIComponent(params.packageDetailId) : undefined
    };

    return removeUndefinedProps(obj);
  }
}

export default CustomizerParamsTransformer;
