import * as yup from 'yup';
import { AnySchema } from 'yup';
import { AnyObject, ObjectShape, OptionalObjectSchema, TypeOfShape } from 'yup/lib/object';
import { BookingService, clone, ConfigModel } from '@ibe/services';
import { TFunction } from 'i18next';
import dayjs from 'dayjs';
import {
  ApiBaseData,
  ApiBookedItem,
  ApiHotel,
  ApiHotelRoom,
  ApiItem,
  ApiItemType,
  ApiRoomRate,
  ApiTraveler,
  ApiTravelerType,
  ApiComponentType,
  ApiBooking
} from '@ibe/api';
import { FormConfig } from '@ibe/components';
import Keys from '../../../Translations/generated/Checkout.de.json.keys';
import useTravellerFormConfigProductIBE from '../../../Config/useTravellerFormConfigProductIBE';
import useTravellerFormConfigProductIBEFormWithOptionalConfig from '../../../Config/useTravellerFormConfigProductIBEFormWithOptionalConfig';

enum SalutationCodes {
  FR = 'FR',
  HR = 'HR',
  DI = 'DI',
  KD = 'KD'
}

export const getAge = (dateString: string): number => dayjs().diff(dayjs(dateString), 'years');

export const getFormConfig = (
  useFirstAddress: boolean,
  isFirstItem: boolean,
  initialValues?: { [key: string]: string },
  config?: ConfigModel
): FormConfig => {
  if (useFirstAddress)
    return useTravellerFormConfigProductIBEFormWithOptionalConfig(isFirstItem, initialValues);
  return useTravellerFormConfigProductIBE(isFirstItem, initialValues, config);
};

export const getSalutations = (
  salutations: ApiBaseData[],
  travelerType: ApiTravelerType
): ApiBaseData[] => {
  switch (travelerType) {
    case ApiTravelerType.ADULT:
      return salutations.filter(
        salutation =>
          salutation.code === SalutationCodes.HR ||
          salutation.code === SalutationCodes.FR ||
          salutation.code === SalutationCodes.DI
      );
    case ApiTravelerType.CHILD:
      return salutations.filter(salutation => salutation.code === SalutationCodes.KD);
    case ApiTravelerType.INFANT:
      return salutations.filter(salutation => salutation.code === SalutationCodes.KD);
    default:
      return salutations;
  }
};

export const buildDefaultTravelerAssignemnt = (
  bookedItems: ApiBookedItem[]
): { [key: string]: string[] } => {
  return bookedItems.reduce(
    (total: { [key: string]: string[] }, current: ApiBookedItem, idx: number) => {
      return { ...total, [idx]: Object.keys(current.priceByPersonId) };
    },
    {}
  );
};

const buildValidationSchema = (
  bs: BookingService,
  t: TFunction
): OptionalObjectSchema<ObjectShape, AnyObject, TypeOfShape<ObjectShape>> => {
  const errorMessages = {
    adultCount: t(Keys.errorAdultCount),
    childrenCount: t(Keys.errorChildCount),
    childrenAge: t(Keys.errorChildrenAge),
    infantCount: t(Keys.errorInfantCount),
    driverAge: t(Keys.errorDriverAge),
    required: t(Keys.errorRequiredField),
    invalidName: t(Keys.errorInvalidName),
    invalidChar: t(Keys.errorInvalidChar),
    invalidFirstMiddleName: t(Keys.errorInvalidFirstMiddleName),
    invalidChild: t(Keys.errorInvalidChild),
    invalidAdult: t(Keys.errorInvalidAdult),
    invalidEmail: t(Keys.errorEmail),
    invalidPhone: t(Keys.errorPhone)
  };

  let schema = yup.object();
  if (bs && bs.booking) {
    const bookedItemsList = bs.booking.bookedItems.filter(
      item => item.itemType === ApiItemType.HOTEL
    );
    bookedItemsList.forEach((item, i) => {
      const yupSchemaName: { [key: string]: AnySchema<unknown, unknown, unknown> } = {};
      yupSchemaName[i] = yup
        .array()
        .test(
          'adultCountCheck',
          errorMessages && errorMessages.adultCount
            ? errorMessages.adultCount
            : 'Error Message Adult Count',
          function validate(travelers): boolean {
            const adultCount = item.paxRequirement.paxes.adults;
            const adults = travelers?.filter(
              (traveler: ApiTraveler) => traveler.type === ApiTravelerType.ADULT
            );
            return adults?.length === adultCount;
          }
        )
        .test(
          'infantCountCheck',
          errorMessages && errorMessages.infantCount
            ? errorMessages.infantCount
            : 'Error Message Infant Count',
          function validate(travelers): boolean {
            const infantCount = item.paxRequirement.paxes.infants;
            if (infantCount === 0) {
              return true;
            }
            const infants = travelers?.filter(
              (traveler: ApiTraveler) => traveler.type === ApiTravelerType.INFANT
            );

            return infants?.length === infantCount;
          }
        )
        .test(
          'childCountCheck',
          errorMessages && errorMessages.childrenCount
            ? errorMessages.childrenCount
            : 'Error Message Child Count',
          function validate(travelers): boolean {
            const childCount = item.paxRequirement.paxes.children;

            const infantsAreChildren =
              item.itemType !== ApiItemType.FLIGHT && item.itemType !== ApiItemType.PACKAGE;

            if (infantsAreChildren) {
              const childrenAndInfants = travelers?.filter(
                (traveler: ApiTraveler) =>
                  traveler.type === ApiTravelerType.CHILD ||
                  traveler.type === ApiTravelerType.INFANT
              );
              return childrenAndInfants?.length === childCount;
            }

            const children = travelers?.filter(
              (traveler: ApiTraveler) => traveler.type === ApiTravelerType.CHILD
            );
            return children?.length === childCount;
          }
        )
        .test(
          'childAgeCheck',
          errorMessages && errorMessages.childrenAge
            ? errorMessages.childrenAge
            : 'Error Message Child Age',
          function validate(travelers): boolean {
            if (item.paxRequirement.paxes.childrenAges.length === 0) {
              return true;
            }
            const infantsAreChildren =
              item.itemType !== ApiItemType.FLIGHT && item.itemType !== ApiItemType.PACKAGE;
            const assignedChildAges = new Array<number>();
            travelers?.map((traveler: ApiTraveler) => {
              if (traveler.type === ApiTravelerType.CHILD) {
                assignedChildAges.push(traveler.age);
              }
              if (infantsAreChildren && traveler.type === ApiTravelerType.INFANT) {
                assignedChildAges.push(traveler.age);
              }
              return assignedChildAges;
            });

            const childAgesRequired = clone(
              item.paxRequirement.paxes.childrenAges
            ) as Array<number>;
            return (
              JSON.stringify(assignedChildAges.sort()) === JSON.stringify(childAgesRequired.sort())
            );
          }
        );

      const otherSchema = yup.object().shape(yupSchemaName);
      schema = schema.concat(otherSchema);
    });
  }
  return schema;
};

export const getHotelInformation = (bs: BookingService, productIBEMode?: boolean) => {
  const bookedPackage = bs.booking?.bookedItems?.find(
    (it: ApiBookedItem) => it.itemType === ApiItemType.PACKAGE
  ) as ApiBookedItem;

  const bookedHotel = bs.booking?.bookedItems?.find(
    (it: ApiBookedItem) => it.itemType === ApiItemType.HOTEL
  ) as ApiBookedItem;

  const roomRate = bs.booking?.items?.find(
    (it: ApiItem) => bookedHotel?.idParent === it.id
  ) as ApiRoomRate;

  const room = bs.booking?.items?.find(
    (it: ApiItem) => roomRate?.idParent === it.id
  ) as ApiHotelRoom;

  const hotel = bs.booking?.items?.find((it: ApiItem) => room?.idParent === it.id) as ApiHotel;

  const { startDate, endDate } = bookedPackage || {};
  let duration = room?.durationInfo?.duration;

  if (productIBEMode) {
    duration = duration + getOptionalHotelDuration(bs.booking);
  }

  return { startDate, endDate, duration, hotel };
};

export const getOptionalHotelDuration = (booking: ApiBooking | null) => {
  const bookedExtensionHotel = booking?.bookedItems?.find(
    (it: ApiBookedItem) =>
      it.itemType === ApiItemType.HOTEL && it.componentType === ApiComponentType.OPTIONAL
  ) as ApiBookedItem;
  const extensionRoomRate = booking?.items?.find(
    (it: ApiItem) => bookedExtensionHotel?.idParent === it.id
  ) as ApiRoomRate;
  const extensionRoom = booking?.items?.find(
    (it: ApiItem) => extensionRoomRate?.idParent === it.id
  ) as ApiHotelRoom;
  return extensionRoom?.durationInfo?.duration || 0;
};

export default buildValidationSchema;
