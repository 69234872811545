import React, { useEffect, useState } from 'react';
import PromotionCode, {
  PromotionCodeData,
  PromotionCodeType,
  validatePromotionCode
} from '../../Components/PromotionCode';
import PackagesList, { ProductIBEProps } from './PackagesList';
import fallback from '../../Translations/generated/product-ibe.de.json';
import Keys from '../../Translations/generated/product-ibe.de.json.keys';
import { SessionData, useConfig, useExternalSearchParams, useTranslation } from '@ibe/components';
import { useMount } from 'react-use';
import { SessionStoragePersistence, LoggerFactory } from '@ibe/services';
import useSearchApi from '../../Util/useSearchApi';
import { validPromotionCodeStorage } from '../Util/utils';
import dayjs from 'dayjs';
import { productIBEAnchor } from './usePackagesListFunctions';
import { BOOKINGID_STORAGE_KEY } from '../../Util/constant';

const logger = LoggerFactory.get('ProductIBE');

const ProductIBE = (
  props: Omit<ProductIBEProps, 'promotionCode'> & {
    initialPromotionCode?: string;
    handlePackageSelection?: () => void;
    hidePromotionCode?: boolean;
    isWhitelabel?: boolean;
  }
): JSX.Element => {
  const { t } = useTranslation('product-ibe', fallback);
  const config = useConfig();
  const searchApi = useSearchApi();
  const context = useExternalSearchParams();
  const {
    earliestPossibleDate,
    latestPossibleDate,
    packageCodes,
    checkoutUrl,
    maintenanceMode,
    handlePackageSelection,
    hidePromotionCode,
    agencyNumber,
    isWhitelabel
  } = props;
  let { initialPromotionCode } = props;
  const formattedEarliestPossibleDate = !earliestPossibleDate
    ? ''
    : dayjs(earliestPossibleDate).format('YYYY-MM-DD');
  const formattedLatestPossibleDate = !latestPossibleDate
    ? ''
    : dayjs(latestPossibleDate).add(2, 'week').format('YYYY-MM-DD');

  const [promotionCode, setPromotionCode] = useState<PromotionCodeData>({
    promotionCodeData: undefined,
    initialPromotionCodeChecked: false
  });

  useMount(() => {
    const session = new SessionStoragePersistence<SessionData>(SessionData);
    session.useKey(context?.widgetSessionKeyPrefix + config.sessionKeyCart);
    session.clear();

    const packageCartSession = new SessionStoragePersistence<string>();
    packageCartSession.useKey(context?.widgetSessionKeyPrefix + config.sessionKeyPackageCart);
    packageCartSession.clear();

    const bidSessionStorage = new SessionStoragePersistence<string>();
    bidSessionStorage.useKey(context?.widgetSessionKeyPrefix + BOOKINGID_STORAGE_KEY);
    bidSessionStorage.clear();
  });

  useEffect(() => {
    (async () => {
      // whitelabel should keep the session storage because it is not using cookies
      if (!isWhitelabel) {
        validPromotionCodeStorage.clear();
      }

      const sessionStorageCode = validPromotionCodeStorage.get();

      if (sessionStorageCode) {
        initialPromotionCode = sessionStorageCode.code;
      }

      if (!!initialPromotionCode) {
        try {
          const data = await validatePromotionCode(searchApi, packageCodes, initialPromotionCode);
          if (!!data.code) {
            setPromotionCode({ promotionCodeData: data, initialPromotionCodeChecked: true });
            data.percentageValue = data?.percentageValue ? data.percentageValue * 100 : undefined;
            validPromotionCodeStorage.set(data);
          } else {
            setPromotionCode({ ...promotionCode, initialPromotionCodeChecked: true });
          }
        } catch (err) {
          logger.error(err);
          setPromotionCode({ ...promotionCode, initialPromotionCodeChecked: true });
        }
      } else {
        setPromotionCode({ ...promotionCode, initialPromotionCodeChecked: true });
      }
    })();
  }, [initialPromotionCode]);

  const handlePromotionCode = (code: PromotionCodeType): void => {
    setPromotionCode({ promotionCodeData: code, initialPromotionCodeChecked: true });
  };

  return (
    <div className="product-ibe">
      {!hidePromotionCode && (
        <PromotionCode
          onSubmit={handlePromotionCode}
          promotionCode={promotionCode.promotionCodeData}
          packageCodes={packageCodes}
          disabled={maintenanceMode}
          hideInfoBox
        />
      )}
      <div id={productIBEAnchor + '-div'}>
        <h2 id={productIBEAnchor}>{t(Keys.headline)}</h2>
        <p className="mb-6">{t(Keys.sectionText)}</p>
        <PackagesList
          packageCodes={packageCodes}
          earliestPossibleDate={formattedEarliestPossibleDate}
          latestPossibleDate={formattedLatestPossibleDate}
          promotionCode={promotionCode}
          checkoutUrl={checkoutUrl}
          maintenanceMode={maintenanceMode}
          handlePackageSelection={handlePackageSelection}
          agencyNumber={agencyNumber}
          isWhitelabel={isWhitelabel}
        />
      </div>
    </div>
  );
};

export default ProductIBE;
