enum PageUrl {
  HOME = '/',
  LOGIN = '/login',
  LOGOUT = '/logout',
  REGISTER = '/register',
  PASSWORD_CHANGE = '/changePassword',
  PROFILE = '/profile',
  CART = '/cart',
  CHECKOUT = '/checkout',
  WORKFLOW_PACKAGE = '/package',
  WORKFLOW_ITEMS = '/item',
  HOTEL_ONLY = '/hotel',
  FLIGHT_ONLY = '/flight',
  EXTRA_ONLY = '/extra',
  TRANSPORT = '/transport',
  BOOKINGS = '/bookings',
  BOOKING_DETAILS = '/bookingDetails',
  TOURS_ONLY = '/tours',
  DINING_ONLY = '/dining',
  AGENCYUSER = '/agencyuser',
  TERMS_AND_CONDITIONS = '/terms-and-conditions',
  PRIVACY_POLICY = '/privacy-policy',
  PACKAGES = '/packages',
  HEALTH = '/health',
  PACKAGE_CUSTOMIZER = '/packages-customizer',
  PACKAGE_FLIGHT = '/package/flight',
  PACKAGE_EXTRA = '/package/extra',
  PACKAGE_HOTEL = '/package/hotel',
  PACKAGE_RESTAURANT = '/package/restaurant',
  PACKAGE_TOUR = '/package/tour',
  PACKAGE_TRANSPORT = '/package/transport'
}
export default PageUrl;
