import React, { PropsWithChildren } from 'react';
import { Price, useTranslation } from '@ibe/components';
import fallback from '../../../../Translations/generated/Checkout.de.json';
import Keys from '../../../../Translations/generated/Checkout.de.json.keys';
import { Card, CardBody, CardFooter, CardHeader, Col, CustomInput, Row } from 'reactstrap';
import InsuranceItem from './InsuranceItem';
import { PriceFactory } from '@ibe/services';

interface InsuranceDisplayProps {
  model: InsuranceModel;
  isHighlight?: boolean;
  features?: Feature[];
  isSelected: boolean;
  onChange: (selectedInsuranceItem: InsuranceModel) => void;
}

export type Feature = {
  id: string;
  text: string;
  info: string;
  noInsurance: boolean;
};

export type InsuranceModel = {
  title: string;
  value: string;
  price: number;
  packageCartId: string;
  componentId: string;
  itemId: string;
  isRecommendation?: boolean;
  isNoInsurance?: boolean;
};

const InsuranceDisplay = ({
  model,
  isHighlight,
  features,
  isSelected,
  onChange,
  children
}: PropsWithChildren<InsuranceDisplayProps>): JSX.Element => {
  const { t } = useTranslation('Checkout', fallback);

  const radioButtonLabel = (): string => {
    if (model.price <= 0) {
      return t(Keys.insuranceNo);
    } else {
      return isSelected ? t(Keys.insuranceSelected) : t(Keys.insuranceSelect);
    }
  };

  return (
    <>
      <div
        className={isHighlight ? 'insurance__highlight' : 'insurance__highlight d-none d-sm-flex'}
      >
        {isHighlight ? (
          <span className="recommendation">{t(Keys.insuranceRecommendation)}</span>
        ) : (
          <span>&nbsp;</span>
        )}
      </div>

      <Card
        className={
          model.isNoInsurance
            ? 'no-insurance-bgcolor'
            : model.isRecommendation
            ? 'is-recommendation-bgcolor'
            : 'with-deductible-bgcolor'
        }
      >
        <CardHeader className="header">{model.title}</CardHeader>

        <CardBody>
          {features &&
            features.map(feature => (
              <InsuranceItem
                key={feature.id}
                id={feature.id}
                text={feature.text}
                info={feature.info}
                noInsurance={feature.noInsurance}
              />
            ))}

          {children}
        </CardBody>

        <CardFooter>
          <Row>
            <Col xs={model.price <= 0 ? 12 : 8} className="pl-0">
              <CustomInput
                type="radio"
                id={'id_' + model.value.toLowerCase()}
                checked={isSelected}
                onChange={() => onChange(model)}
                label={<div className="insurance__selection">{radioButtonLabel()}</div>}
              />
            </Col>
            {model.price > 0 && (
              <Col xs={4} className="px-0 text-right">
                <Price
                  price={PriceFactory.create(model.price, 'EUR')}
                  prefix="+"
                  className="insurance__price"
                />
              </Col>
            )}
          </Row>
        </CardFooter>
      </Card>
    </>
  );
};

export default InsuranceDisplay;
