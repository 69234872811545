import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import {
  DateDisplay,
  Overlay,
  OverlayContainer,
  Price,
  Summary,
  useAppService,
  useBookingService,
  useConfig,
  useTranslation
} from '@ibe/components';
import {
  ApiBookedItem,
  ApiBooking,
  ApiComponentType,
  ApiDirection,
  ApiFlightItem,
  ApiItemType,
  ApiPrice,
  ApiPriceModifierType,
  ApiTraveler
} from '@ibe/api';
import { LoggerFactory, PriceFactory, SessionStorage } from '@ibe/services';
import fallback from '../../../../Translations/generated/Checkout.de.json';
import Keys from '../../../../Translations/generated/Checkout.de.json.keys';
import fallbackSummary from '../../../../Translations/generated/summary.de.json';
import KeysSummary from '../../../../Translations/generated/summary.de.json.keys';
import PriceOverviewHeader from './PriceOverviewHeader';
import { ProductIBEMode } from '../../CheckoutStore';
import PromotionCode, {
  isValidPromotionCode,
  PromotionCodeType
} from '../../../../Components/PromotionCode';
import { getTotalDiscount, validPromotionCodeStorage } from '../../../Util/utils';
import useThgConfig from '../../../../Hooks/useThgConfig';
import { summaryOverviewSessionKey } from '../../../../Config/config';
import CustomPerTravelerSummaryItem, {
  BookedItemType
} from './CustomPerTravelerSummaryItem/CustomPerTravelerSummaryItem';
import { SelectedItemsType } from '../ExtendedBooking/ContextAndProvider';
import { Col, Row } from 'reactstrap';

const logger = LoggerFactory.get('SummaryContent');

const SummaryContent = observer(
  ({
    booking,
    productIBEMode,
    showPromotionCode,
    travelers,
    extendedBookingItems,
    isConfirmationPage
  }: {
    booking: ApiBooking;
    productIBEMode?: ProductIBEMode;
    showPromotionCode?: boolean;
    handleBookingError?: (errorMessage: string) => void;
    travelers?: ApiTraveler[];
    extendedBookingItems?: SelectedItemsType;
    isConfirmationPage?: boolean;
  }): JSX.Element => {
    const bookedItems = booking?.bookedItems;
    const bookingDate = booking?.bookingDate;
    const { t } = useTranslation('Checkout', fallback);
    const { t: tSummary } = useTranslation('summary', fallbackSummary);
    const bs = useBookingService();
    const saveCollapsibles = summaryOverviewSession.get()?.collapsible?.expandedItemIndexes || [];
    const [openedCollapsibles, setOpenedCollapsibles] = useState<number[]>(saveCollapsibles);
    const [totalPricePerEachTraveler, setTotalPricePerEachTraveler] = useState<{
      [a: string]: number;
    }>({});
    const [promotionCode, setPromotionCode] = useState<PromotionCodeType>(undefined);
    const initialPromotionCode = productIBEMode?.promotionCode;
    const config = useConfig();
    const thgConfig = useThgConfig();
    const baseUrl = thgConfig.baseUrl || config.translationsUrl;

    const totalPrice = useMemo(() => {
      if (!extendedBookingItems || isConfirmationPage) {
        return booking.price?.finalPrice;
      }
      return Object.values(totalPricePerEachTraveler).reduce((acc, currentValue) => {
        return acc + currentValue;
      }, 0);
    }, [totalPricePerEachTraveler, extendedBookingItems, isConfirmationPage]);

    const applyPromotionCode = async (code: string): Promise<void> => {
      try {
        await bs.addPromoCodesToBooking([code]);
        const discount = getTotalDiscount(bs.booking?.travelers || [], bs.bookedItems);
        if (discount > 0) {
          const codeType = { code, absoluteValue: discount, sign: '€' };
          setPromotionCode({ ...codeType });
          validPromotionCodeStorage.set({ ...codeType });
        }
      } catch (err) {
        logger.error(err);
        setPromotionCode(value => (value === 'ERROR' ? 'NONE' : 'ERROR'));
      }
    };

    useEffect(() => {
      (async () => {
        const sessionStorageCode = validPromotionCodeStorage.get();
        const promoCode =
          sessionStorageCode && sessionStorageCode.code
            ? sessionStorageCode.code
            : initialPromotionCode;

        if (showPromotionCode && !!promoCode) {
          await applyPromotionCode(promoCode);
        }
      })();
    }, [initialPromotionCode, showPromotionCode]);

    useEffect(() => {
      const discount = getTotalDiscount(bs.booking?.travelers || [], bs.bookedItems);
      if (discount > 0) {
        const oldCodeType = validPromotionCodeStorage.get();
        if (oldCodeType) {
          const codeType = { code: oldCodeType.code, absoluteValue: discount, sign: '€' };
          setPromotionCode({ ...codeType });
          validPromotionCodeStorage.set({ ...codeType });
        }
      }
    }, [bs.bookedItems]);

    // FOR DESIGN REASON just take the first flight
    const filteredBookedItems = useMemo(() => {
      return filterBookedItems(bookedItems);
    }, [bookingDate, bookedItems.length]);

    const handlePromotionCodeSubmit = async (
      code: PromotionCodeType,
      fromInputSubmit?: boolean
    ): Promise<void> => {
      if (isValidPromotionCode(code) && fromInputSubmit) {
        await applyPromotionCode(code.code);
      }
    };

    const customPriceSummaryRendering = useCallback((item: ApiBookedItem, travelerId?: string):
      | ApiPrice
      | undefined => {
      if (!travelerId) return undefined;
      const discountedPrice = item.priceByPersonId[travelerId].modifiers.find(
        mod => mod.type === ApiPriceModifierType.DISCOUNT && item.itemType !== ApiItemType.PACKAGE
      );
      if (!!discountedPrice) {
        return {
          ...item.priceByPersonId[travelerId],
          finalPrice:
            item.priceByPersonId[travelerId].finalPrice + Math.abs(discountedPrice?.absolute)
        };
      } else {
        return item.priceByPersonId[travelerId];
      }
    }, []);

    const showPlusMinus = (item: ApiBookedItem): boolean => {
      return item.itemType !== ApiItemType.HOTEL;
    };

    const getOpenedCollapsible = (prev: number[], travelerIdx: number) => {
      if (prev.includes(travelerIdx)) {
        return prev.filter(val => val !== travelerIdx);
      } else {
        return prev.concat([travelerIdx]);
      }
    };

    const onCollapseToggle = (travelerIdx: number) => {
      const newCollapseds = getOpenedCollapsible(openedCollapsibles, travelerIdx);
      setOpenedCollapsibles(newCollapseds);
      summaryOverviewSession.set({ collapsible: { expandedItemIndexes: newCollapseds } });
    };

    const customPerTravelerSummaryItem = (
      traveler: ApiTraveler,
      extBookedItems: BookedItemType[],
      travelerIdx: number
    ) => {
      const isOpen = openedCollapsibles.includes(travelerIdx);
      return (
        <CustomPerTravelerSummaryItem
          onCollapseToggle={onCollapseToggle}
          isOpen={isOpen}
          traveler={traveler}
          travelers={travelers}
          extendedBookingItems={extendedBookingItems}
          travelerIdx={travelerIdx}
          bookedItems={extBookedItems}
          onTotalPriceChange={(a: number) => getTotalPrice(a, traveler)}
          booking={booking}
          key={travelerIdx}
          isConfirmationPage={isConfirmationPage}
        />
      );
    };

    const getTotalPrice = (a: number, traveler: ApiTraveler) => {
      setTotalPricePerEachTraveler(prevState => {
        return { ...prevState, [traveler.id]: a };
      });
    };

    const insuranceBookedItem = bookedItems.find(
      b => ApiItemType.INSURANCE === b.itemType && ApiComponentType.OPTIONAL === b.componentType
    );

    const customLabelsMapper = useCallback(
      (item: ApiBookedItem): string | undefined => {
        if (
          item.componentType === ApiComponentType.OPTIONAL &&
          item.itemType === ApiItemType.FLIGHT
        ) {
          return thgConfig.checkoutLabels?.railAndFly;
        }

        if (
          item.componentType === ApiComponentType.REQUIRED &&
          item.itemType === ApiItemType.FLIGHT
        ) {
          const flightItemIds = booking.bookedItems
            .filter(
              bookedItem =>
                bookedItem.itemType === ApiItemType.FLIGHT &&
                bookedItem.componentType === ApiComponentType.REQUIRED
            )
            .map(bitem => bitem.idParent);

          if (item.idParent !== flightItemIds[0]) return undefined;

          const outBoundFlights = booking.items.filter(
            flightItem =>
              flightItem.itemType === ApiItemType.FLIGHT &&
              (flightItem as ApiFlightItem).segment[0].direction === ApiDirection.OUTBOUND &&
              flightItemIds.includes(flightItem.id)
          );

          const inBoundFlights = booking.items.filter(
            flightItem =>
              flightItem.itemType === ApiItemType.FLIGHT &&
              (flightItem as ApiFlightItem).segment[0].direction === ApiDirection.INBOUND &&
              flightItemIds.includes(flightItem.id)
          );

          const outBoundFlight = outBoundFlights[0] as ApiFlightItem;
          const inBoundFlight = inBoundFlights[0] as ApiFlightItem;

          const title = `${outBoundFlight.segment[0]?.origin.description} (${outBoundFlight.segment[0]?.origin.code}) - ${inBoundFlight.segment[0]?.origin.description} (${inBoundFlight?.segment[0].origin.code})`;

          return title;
        }
      },
      [booking.items]
    );

    return (
      <div className={'ibe__flight'}>
        <PriceOverviewHeader booking={booking} title={t(Keys.travelOverview)} />
        <div className="sticky-summary-box-summary">
          <Summary
            booking={booking}
            showPricePlusMinus={showPlusMinus}
            withIconColumn={false}
            showAdditionalInfo
            showTraveler={false}
            travelers={travelers}
            showSsr={false}
            showDiscounts={true}
            showInfo={false}
            showDivider={true}
            showTravelerSeparate={true}
            bookedItems={filteredBookedItems}
            isCollapsible
            activeCollapsibleIndexes={openedCollapsibles}
            onCollapseToggle={onCollapseToggle}
            customLabelsMapper={customLabelsMapper}
            customPerTravelerSummaryItem={customPerTravelerSummaryItem}
            customFlightDateDisplay={flight => (
              <CustomFlightSummaryRendering key={flight.id} item={flight} booking={booking} />
            )}
            customPriceModifier={customPriceSummaryRendering}
            showNumberOfPerson
          />
        </div>
        {insuranceBookedItem && (
          <OverlayContainer>
            <div className="insurance__wrapper mt-5">
              <Row noGutters>
                <Col
                  xs={insuranceBookedItem.price.finalPrice > 0 ? 8 : 12}
                  className="align-self-center"
                >
                  <div className="d-flex">
                    <img
                      className="insurance-icon"
                      src={`${baseUrl}/img/booking_insurance.svg`}
                      alt="an icon for a selected insurance"
                    />
                    <span className="font-weight-bold">{t(Keys.insuranceYourInsurance)}</span>
                  </div>
                </Col>
                {insuranceBookedItem.price.finalPrice > 0 && (
                  <Col xs={4} className="text-right align-self-center">
                    <Price className="summary__traveler__price" price={insuranceBookedItem.price} />
                  </Col>
                )}
              </Row>
              <Row noGutters>
                <Col>
                  {insuranceBookedItem.price.finalPrice > 0
                    ? insuranceBookedItem.description
                    : t(Keys.insuranceNoInsuranceSelected)}
                </Col>
              </Row>
            </div>
          </OverlayContainer>
        )}
        {!!productIBEMode?.serviceCode && showPromotionCode ? (
          <OverlayContainer>
            <div className="prom-code__wrapper mt-5">
              <PromotionCode
                promotionCode={promotionCode}
                setPromotionCode={setPromotionCode}
                onSubmit={handlePromotionCodeSubmit}
                packageCodes={[{ code: productIBEMode.serviceCode, smallGroup: false }]}
                smallMode
                numberOfTravelers={booking?.travelers?.length}
              />
            </div>
            {bs.isBusy ? <Overlay /> : <></>}
          </OverlayContainer>
        ) : (
          <></>
        )}
        <div className="sticky-total-price d-flex justify-content-between justify-content-lg-between ">
          <b className="price">{tSummary(KeysSummary.total)}</b>
          <b className="price text-left text-lg-left d-flex d-lg-block">
            <Price
              price={PriceFactory.create(totalPrice, bs.booking?.price.currencyCode || 'EUR')}
              className="summary__price"
            />
          </b>
        </div>
      </div>
    );
  }
);

const CustomFlightSummaryRendering = ({
  booking,
  item
}: {
  booking: ApiBooking;
  item: ApiFlightItem;
}): JSX.Element => {
  // we have 2 (or more if connected flights) booked items but want to summarize them to one and hide intermediate fights.
  // hence we want this to render only once

  const flightItemIds = booking.bookedItems
    .filter(
      bookedItem =>
        bookedItem.itemType === ApiItemType.FLIGHT &&
        bookedItem.componentType === ApiComponentType.REQUIRED
    )
    .map(bitem => bitem.idParent);

  if (item.id !== flightItemIds[0]) return <></>;

  const config = useConfig();
  const appService = useAppService();

  const outBoundFlights = booking.items.filter(
    flightItem =>
      flightItem.itemType === ApiItemType.FLIGHT &&
      (flightItem as ApiFlightItem).segment[0].direction === ApiDirection.OUTBOUND &&
      flightItemIds.includes(flightItem.id)
  ) as ApiFlightItem[];

  const inBoundFlights = booking.items.filter(
    flightItem =>
      flightItem.itemType === ApiItemType.FLIGHT &&
      (flightItem as ApiFlightItem).segment[0].direction === ApiDirection.INBOUND &&
      flightItemIds.includes(flightItem.id)
  ) as ApiFlightItem[];

  const flightItems = booking.items.filter(
    i => i.itemType === ApiItemType.FLIGHT
  ) as ApiFlightItem[];
  if (!outBoundFlights || !inBoundFlights) return <></>;
  const originLeg = outBoundFlights[0].segment[0].legs[0];
  const destinationLeg = inBoundFlights[0].segment[0].legs[0];
  return (
    <>
      <div className="custom-summary-flight-date-display">
        <div>
          {originLeg && (
            <DateDisplay
              utc
              date={originLeg.departure}
              format={config.displayFormatDate[appService.lang.replace('-', '_')]}
            />
          )}
        </div>
        <div>
          <div>
            <span>{outBoundFlights[0].segment[0]?.origin.code}</span>
          </div>
          <span></span>
          <div>
            <span>{outBoundFlights[outBoundFlights.length - 1].segment[0]?.destination.code}</span>
          </div>
        </div>
      </div>
      <div className="custom-summary-flight-date-display">
        <div>
          {destinationLeg && (
            <DateDisplay
              utc
              date={destinationLeg.departure}
              format={config.displayFormatDate[appService.lang.replace('-', '_')]}
            />
          )}
        </div>
        <div>
          <div>
            <span>{inBoundFlights[0].segment[0]?.origin.code}</span>
          </div>
          <span></span>
          <div>
            <span>{inBoundFlights[inBoundFlights.length - 1].segment[0]?.destination.code}</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default SummaryContent;

export const summaryOverviewSession = new SessionStorage<{
  collapsible: {
    expandedItemIndexes: number[];
  };
}>(summaryOverviewSessionKey);

export const filterBookedItems = (bookedItems: ApiBookedItem[] | null) => {
  if (!bookedItems) return [];

  const flights = bookedItems.filter(
    (item: ApiBookedItem) =>
      item.itemType === ApiItemType.FLIGHT && item.componentType === ApiComponentType.REQUIRED
  );

  const filteredHotelItems = bookedItems.filter(
    (item: ApiBookedItem) =>
      (item.itemType === ApiItemType.HOTEL &&
        ((bookedItems.some(
          (innerItem: ApiBookedItem) =>
            innerItem.itemType === ApiItemType.HOTEL &&
            innerItem.componentType === ApiComponentType.OPTIONAL
        ) &&
          item.componentType === ApiComponentType.OPTIONAL) ||
          (!bookedItems.some(
            (innerItem: ApiBookedItem) =>
              innerItem.itemType === ApiItemType.HOTEL &&
              innerItem.componentType === ApiComponentType.OPTIONAL
          ) &&
            item.componentType === ApiComponentType.MAIN))) ||
      (item.componentType === ApiComponentType.MAIN && item.itemType == ApiItemType.HOTEL)
  );

  const filteredRequiredItems = bookedItems.filter(
    (item: ApiBookedItem) =>
      item.componentType === ApiComponentType.REQUIRED && item.itemType == ApiItemType.EXTRA
  );

  // Seperated into 2 arrays to have the optional items explicitly below the required items
  const filteredOptionalItems = bookedItems.filter(
    (item: ApiBookedItem) =>
      (item.componentType === ApiComponentType.OPTIONAL && item.itemType == ApiItemType.FLIGHT) ||
      (item.componentType === ApiComponentType.OPTIONAL && item.itemType == ApiItemType.EXTRA)
  );
  return [...filteredHotelItems, ...flights, ...filteredRequiredItems, ...filteredOptionalItems];
};
