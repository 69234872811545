import {
  ApiBaseData,
  ApiConfigurationDataType,
  ApiImage,
  ApiInsurance,
  ApiTraveler
} from '@ibe/api';
import {
  ApiService,
  BookingService,
  LoggerFactory,
  StepType,
  ValidationError
} from '@ibe/services';
import {
  faBed,
  faCheck,
  faFile,
  faUsers,
  faStar,
  faCalendarAlt
} from '@fortawesome/free-solid-svg-icons';
import { action, computed, makeObservable, observable } from 'mobx';
import { AnyObject, ObjectShape, OptionalObjectSchema, TypeOfShape } from 'yup/lib/object';
import Keys from '../../Translations/generated/Checkout.de.json.keys';
import { ImageProgressbarStep } from '@ibe/components';
import { TFunction } from 'i18next';
import intersection from 'lodash-es/intersection';

export type HotelInformationType = {
  name?: string;
  image?: ApiImage;
  icon?: ApiImage;
  country?: string;
  additionalServices?: 'extras' | 'attractions';
};

export type ProductIBEMode = {
  bookingHasComponents?: boolean;
  promotionCode?: string;
  serviceCode?: string;
};

const logger = LoggerFactory.get('CheckoutStore.tsx');

class CheckoutStore {
  insurances: ApiInsurance[] = [];

  stepActive: StepType | null = null;

  isTravellersDataValid = false;

  isTravelerAssignmentValid = false;

  productIBEMode?: ProductIBEMode;

  travelerAssignmentValidationSchema: OptionalObjectSchema<
    ObjectShape,
    AnyObject,
    TypeOfShape<ObjectShape>
  >;

  travelerAssignmentErrors: { [key: string]: string[] };

  hotelInformation: HotelInformationType;

  constructor(private api: ApiService, private t: TFunction) {
    makeObservable(this, {
      insurances: observable,
      stepActive: observable,
      isTravellersDataValid: observable,
      isTravelerAssignmentValid: observable,
      travelerAssignmentErrors: observable,
      hotelInformation: observable,
      isProductIBEMode: computed,
      productIbeModeObject: computed,
      steps: computed,
      setActiveStep: action,
      setProductIBEMode: action,
      setIsTravelerAssignmentValid: action,
      validateTravelerAssignment: action
    });
  }

  setIsTravelerAssignmentValid(value: boolean): void {
    this.isTravelerAssignmentValid = value;
  }

  setHotelInformation(value: HotelInformationType): void {
    this.hotelInformation = value;
  }

  public setProductIBEMode(productIBEMode?: ProductIBEMode): void {
    this.productIBEMode = productIBEMode;
  }

  get isProductIBEMode(): boolean {
    return !!this.productIBEMode;
  }

  get productIbeModeObject(): ProductIBEMode | undefined {
    return this.productIBEMode;
  }

  get steps(): ImageProgressbarStep[] {
    return [
      {
        description: Keys.travelDate,
        selected: this.stepActive === undefined || this.stepActive === StepType.OAG_PACKAGE_HOTEL,
        image: this.stepActive === undefined ? faCheck : faCalendarAlt
      },
      {
        description: Keys.summary,
        selected: this.stepActive === StepType.OAG_CHECKOUT_SUMMARY,
        image: faBed
      },
      {
        description: Keys.extras,
        selected: this.stepActive === StepType.OAG_CHECKOUT_EXTRAS,
        image: faStar
      },
      {
        description: Keys.participant,
        selected: this.stepActive === StepType.OAG_CHECKOUT_TRAVELERS,
        image: faUsers
      },
      {
        description: Keys.confirmation,
        selected: this.stepActive === StepType.OAG_CHECKOUT_CONFIRMATION,
        image: faFile
      }
    ];
  }

  setActiveStep(value: StepType | null): void {
    this.stepActive = value;
  }

  setTravelerAssignmentValidationSchema(
    schema: OptionalObjectSchema<ObjectShape, AnyObject, TypeOfShape<ObjectShape>>
  ): void {
    this.travelerAssignmentValidationSchema = schema;
  }

  async executeTravelerAssignmentValidation(
    values: Record<string, ApiTraveler[]>
  ): Promise<object | null | void> {
    if (this.travelerAssignmentValidationSchema) {
      return this.travelerAssignmentValidationSchema.validate(values, { abortEarly: false });
    }
    return Promise.resolve();
  }

  async getTravelerAssignmentErrors(
    values: Record<string, ApiTraveler[]>
  ): Promise<{ [key: string]: string[] }> {
    const errors: { [key: string]: string[] } = {};

    await this.executeTravelerAssignmentValidation(values).catch((e: ValidationError) => {
      if (e && e.inner) {
        e.inner.forEach(ee => {
          if (ee && ee.path) {
            errors[ee.path] = ee.errors;
          }
        });
      }
    });

    return errors;
  }

  static getTravelers(
    travellers: ApiTraveler[],
    travelerAssignment: { [key: string]: string[] }
  ): Record<string, ApiTraveler[]> {
    const travelers: Record<string, ApiTraveler[]> = {};

    Object.entries(travelerAssignment).forEach(([key, personIds]) => {
      travelers[key] = [];
      personIds.forEach(personId => {
        const traveler = BookingService.getTraveler(travellers, personId);
        if (traveler) {
          travelers[key]?.push(traveler);
        }
      });
    });
    return travelers;
  }

  async validateTravelerAssignment(
    travellers: ApiTraveler[],
    travelerAssignment: { [key: string]: string[] },
    ignoreIndices?: number[]
  ): Promise<void> {
    const travelers = CheckoutStore.getTravelers(travellers, travelerAssignment);
    const errors = await this.getTravelerAssignmentErrors(travelers);
    const newAssignment = Object.fromEntries(
      Object.entries(travelerAssignment).filter(
        ([key]) => !ignoreIndices || (!!ignoreIndices && !ignoreIndices.includes(parseInt(key, 10)))
      )
    );
    const allAssigned = Object.values(newAssignment).flat();
    const paxMultipleTimesAssigned = new Set(allAssigned).size !== allAssigned.length;
    if (paxMultipleTimesAssigned) {
      const duplicates = allAssigned.filter((item, index) => allAssigned.indexOf(item) != index);
      Object.values(newAssignment).forEach((element, index) => {
        const check = intersection(element, duplicates);
        if (check && check.length > 0 && !errors[index]) {
          errors[index] = [this.t(Keys.errorPaxAssignmentNotUnique)];
        }
      });
    }
    this.isTravelerAssignmentValid = !(Object.keys(errors).length > 0) && !paxMultipleTimesAssigned;

    this.travelerAssignmentErrors = errors;
  }

  async loadExternalData(): Promise<{
    salutations: ApiBaseData[];
    countries: ApiBaseData[];
    nationalities: ApiBaseData[];
  }> {
    try {
      const response = await Promise.all([
        this.api.getSalutations(),
        this.api.getAllCountries(),
        this.api.getNationalities()
      ]);
      return {
        salutations: response[0].data[ApiConfigurationDataType.SALUTATIONS],
        countries: response[1].sort(o1 => (o1.code === 'DE' ? -1 : 1)),
        nationalities: response[2].sort(o1 => (o1.code === 'DE' ? -1 : 1))
      };
    } catch (err) {
      logger.error(err);
      return {
        salutations: [],
        countries: [],
        nationalities: []
      };
    }
  }
}

export default CheckoutStore;
