/* This file was generated by running "node scripts/generateTranslations.js". */
/* Created: 2024-07-22 09:22:06 */

const Keys = {
  addTraveller: 'addTraveller',
  next: 'next',
  participant: 'participant',
  inputRequired: 'inputRequired',
  mustBeAdult: 'mustBeAdult',
  shouldNotBeNull: 'shouldNotBeNull',
  mustBeInfant: 'mustBeInfant',
  mustBeChild: 'mustBeChild',
  mustBeValidDate: 'mustBeValidDate',
  mustBeOlderThan: 'mustBeOlderThan',
  salutation: 'salutation',
  title: 'title',
  male: 'male',
  female: 'female',
  firstname: 'firstname',
  lastname: 'lastname',
  nationality: 'nationality',
  dateOfBirth: 'dateOfBirth',
  day: 'day',
  month: 'month',
  year: 'year',
  removeParticipant: 'removeParticipant',
  cancel: 'cancel',
  continue: 'continue',
  street: 'street',
  houseNumber: 'houseNumber',
  street_houseNumber: 'street_houseNumber',
  city: 'city',
  zipCode: 'zipCode',
  country: 'country',
  phone: 'phone',
  phoneError: 'phoneError',
  email: 'email',
  emailOptional: 'emailOptional',
  confirmEmail: 'confirmEmail',
  zipCodeError: 'zipCodeError',
  emailError: 'emailError',
  confirmEmailError: 'confirmEmailError',
  prev: 'prev',
  summary: 'summary',
  billingInfo: 'billingInfo',
  payment: 'payment',
  bookingAttemptFailed: 'bookingAttemptFailed'
}; export default Keys;