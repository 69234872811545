import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { routeChangeEvent, SessionStorage, SessionStoragePersistence } from '@ibe/services';
import { Action, Location } from 'history';
import { SessionStorageType } from './Components/Search';
import { productSearchSessionKey } from './Config/config';
import { BOOKINGID_STORAGE_KEY } from './Util/constant';
import { stepSessionStorage } from './Util/globals';
import { useExternalSearchParams } from '@ibe/components';

const RouteBroadcast = (): JSX.Element => {
  const history = useHistory();
  const location = useLocation();
  const { pathname, search } = location;
  const sessionStorage = new SessionStorage<SessionStorageType>(productSearchSessionKey);
  const context = useExternalSearchParams();

  useEffect(() => {
    return history.listen((currentLocation: Omit<Location, 'key'>) => {
      if (history.action === Action.Push) {
        const urlObject = new URL(
          `${currentLocation.pathname}${currentLocation.search}`,
          window.location.origin
        );
        if (!urlObject.searchParams.get('fromOnPopstate')) {
          window.history.pushState({ url: `${urlObject.pathname}${urlObject.search}` }, '');
        }
      }
    });
  }, []);

  useEffect(() => {
    const callback = (evt: PopStateEvent) => {
      const urlObject = new URL(window.location.origin);
      urlObject.searchParams.append('fromOnPopstate', 'true');
      const bidSessionStorage = new SessionStoragePersistence<string>();
      bidSessionStorage.useKey(context?.widgetSessionKeyPrefix + BOOKINGID_STORAGE_KEY);
      if (bidSessionStorage.exists()) {
        stepSessionStorage.set('confirmation');
        history.push('confirmation');
        return;
      }
      if (!!sessionStorage.get()?.search && evt.state == null) {
        sessionStorage.clear();
        history.push(`${urlObject.pathname}${urlObject.search}`);
      } else if (evt.state) {
        const newUrl = evt.state?.url;
        const urlObjectTemp = new URL(newUrl, window.location.origin);
        urlObjectTemp.searchParams.append('fromOnPopstate', 'true');
        history.push(`${urlObjectTemp.pathname}${urlObjectTemp.search}`);
      } else {
        history.push(`${urlObject.pathname}${urlObject.search}`);
      }
    };

    window.addEventListener('popstate', callback);

    return () => {
      window.removeEventListener('popstate', callback);
    };
  }, []);

  useEffect(() => {
    routeChangeEvent.broadcast(location);
  }, [pathname, search]);

  return <></>;
};

export default RouteBroadcast;
