import { Button, Col, Container, Row } from 'reactstrap';
import React, { useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import { Price, useTranslation, Overlay } from '@ibe/components';
import fallback from '../../../../Translations/generated/Checkout.de.json';
import Keys from '../../../../Translations/generated/Checkout.de.json.keys';
import { ApiBooking } from '@ibe/api';
import { footerSelector, navbarMobileSelector } from './useFixedElementScroll';
import debounce from 'lodash-es/debounce';

type ExtendedProps = {
  booking: ApiBooking;
  loading: boolean;
  next: () => void;
  showModal: () => void;
  disabled: boolean;
  rightBottomText?: string;
  onHeightChange?: (height: number) => void;
};

const SummaryFixedBottomBar = observer(
  ({
    booking,
    showModal,
    next,
    disabled,
    loading,
    rightBottomText,
    onHeightChange
  }: ExtendedProps): JSX.Element => {
    const { t } = useTranslation('Checkout', fallback);
    const fixedBottomBarRef = useRef<HTMLDivElement>(null);

    const checkFooterVisibility = () => {
      const footer = document.querySelector(footerSelector) as HTMLDivElement;
      const navbarMobile = document.querySelector(navbarMobileSelector) as HTMLDivElement;
      if (footer) {
        const navbarHeight = navbarMobile?.offsetHeight || 0;

        const { top } = footer.getBoundingClientRect();
        const windowHeight = window.innerHeight;

        const footerIsVisible = top <= windowHeight;
        const isButtonOutOfScreen = top - navbarHeight < 0;
        const currentFixedRef = fixedBottomBarRef?.current;
        if (!currentFixedRef) return;
        if (footerIsVisible) {
          if (isButtonOutOfScreen) {
            currentFixedRef.style.position = 'fixed';
            currentFixedRef.style.bottom = 'initial';
            currentFixedRef.style.top = navbarHeight + 'px';
          } else {
            currentFixedRef.style.position = 'relative';
            currentFixedRef.style.bottom = 'initial';
            currentFixedRef.style.top = 'initial';
          }
        } else {
          currentFixedRef.style.position = 'fixed';
          currentFixedRef.style.bottom = '0px';
          currentFixedRef.style.top = 'initial';
        }
      }
    };

    const checkFooterHeight = () => {
      const elementHeight = fixedBottomBarRef?.current?.clientHeight;
      if (onHeightChange && elementHeight) {
        onHeightChange(elementHeight);
      }
    };

    useEffect(() => {
      const debouncedCheckFooterVisibility = debounce(checkFooterVisibility, 15);
      window.addEventListener('scroll', debouncedCheckFooterVisibility);
      checkFooterVisibility();
      return () => {
        window.removeEventListener('scroll', debouncedCheckFooterVisibility);
      };
    }, []);

    useEffect(() => {
      if (!!onHeightChange) {
        const debouncedHandleResize = debounce(checkFooterHeight, 150);
        window.removeEventListener('resize', debouncedHandleResize);
        window.addEventListener('resize', debouncedHandleResize);
        debouncedHandleResize();
        return (): void => {
          window.removeEventListener('resize', debouncedHandleResize);
        };
      }
    }, [onHeightChange]);

    return (
      <div
        ref={fixedBottomBarRef}
        style={{ position: 'fixed' }}
        className="d-md-none summary-fixed-bottom-bar"
      >
        <Container fluid>
          {loading && <Overlay />}
          <Row noGutters className="align-items-center">
            <Col xs={6} sm={3} md={3}>
              <button onClick={showModal} className="btn close-btn price-detail-btn">
                {t(Keys.priceDetail)}
              </button>
            </Col>
            <Col xs={6} sm={4} md={4} className="text-right">
              <p className="price">{t(Keys.totalPrice)}</p>
              <Price price={booking.price} className="summary__price" />
            </Col>
            <Col xs={12} sm={5} md={5} className="text-right pl-sm-3">
              <Button disabled={disabled} onClick={next} color="primary" className="next-btn px-4">
                {rightBottomText ? rightBottomText : t(Keys.next)}
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
);

export default SummaryFixedBottomBar;
